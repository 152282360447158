import { useRoute } from '@react-navigation/native';
import React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import Toast from 'react-native-toast-message';
import tw, {
  Button,
  InputForm,
} from 'design-system';
import { RouteProps } from 'types/navigation';
import { useCommunityQuery } from 'types/graphql';
import Screens from '../../../../screens';
import useUpdateCommunity from './useUpdateCommunity';
import translations, { toastrTranslations } from '../translations';
import validationSchema from './UpdateCommunityForm.validation';

type UpdateCommunityFormikFields = {
  id: string;
  name: string;
  description: string;
}

export default function UpdateCommunityForm() {
  const intl = useIntl();
  const { updateCommunityMutation } = useUpdateCommunity();
  const { params: { id } } = useRoute<RouteProps<Screens.updateCommunity>>();
  const communityData = useCommunityQuery({
    variables: {
      id,
    },
    onError: () => (
      Toast.show({
        type: 'error',
        text1: intl.formatMessage(toastrTranslations.failedToFetchCommunity),
      })
    ),
  });
  const initialValues = {
    id,
    name: communityData.data?.community?.name || '',
    description: communityData.data?.community?.description || '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange
      validateOnBlur
      validateOnMount
      validationSchema={validationSchema(intl)}
      onSubmit={(
        values: UpdateCommunityFormikFields,
      ) => updateCommunityMutation({ variables: values })}
    >
      {({
        handleSubmit,
      }) => (
        <>
          <InputForm
            fieldName="name"
            label={intl.formatMessage(translations.formLabelTitle)}
            style={tw.style('mb-4')}
          />
          <InputForm
            fieldName="description"
            label={intl.formatMessage(translations.formLabelDescription)}
            style={tw.style('mb-4')}
          />
          <Button
            variant="full"
            onPress={() => handleSubmit()}
            style={tw.style('self-center')}
          >
            {intl.formatMessage(translations.formButtonSubmit).toUpperCase()}
          </Button>
        </>
      )}
    </Formik>
  );
}
