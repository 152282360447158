import React from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';
import tw, {
  Text,
  ScreenAreaView,
  ScreenContainer,
  KeyboardDisplayManager,
  ProgressBar,
} from 'design-system';
import CreatePasswordForm from './create-password-form/CreatePasswordForm';
import translations from './translations';

export default function CreatePasswordScreen() {
  const intl = useIntl();

  return (
    <ScreenAreaView edges={['top', 'bottom']}>
      <ScreenContainer>
        <KeyboardDisplayManager>
          <Text
            variant="header1"
            style={tw.style('mb-4')}
          >
            {intl.formatMessage(translations.title)}
          </Text>
          <View
            style={tw.style('mb-4')}
          >
            <ProgressBar
              progress={50}
            />
          </View>
          <Text
            variant="subtitle"
            style={tw.style('mb-4')}
          >
            {intl.formatMessage(translations.subtitle)}
          </Text>
          <Text
            style={tw.style('mb-6')}
          >
            {intl.formatMessage(translations.description)}
          </Text>
          <CreatePasswordForm />
        </KeyboardDisplayManager>
      </ScreenContainer>
    </ScreenAreaView>
  );
}
