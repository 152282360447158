import React, { ComponentPropsWithoutRef } from 'react';
import { View } from 'react-native';
import tw, {
  Avatar,
  Tag,
  Text,
} from 'design-system';
import { useIntl } from 'react-intl';
import { PropertyInvitationStatus } from 'types/graphql';
import translations from './translations';

type UserItemProps = {
  userProfile: ComponentPropsWithoutRef<typeof Avatar>['profile'],
  invitationStatus?: PropertyInvitationStatus
}

export default function UserItem({
  userProfile,
  invitationStatus,
}: UserItemProps) {
  const { formatMessage } = useIntl();
  return (
    <View
      style={tw.style('flex-row items-center')}
    >
      {
        userProfile ? (
          <Avatar
            profile={userProfile}
            variant="small"
          />
        )
          : null
      }

      <Text
        style={tw.style({
          'ml-4': !!userProfile?.firstName && !!userProfile?.lastName,
        })}
      >
        {
        userProfile
          ? `${userProfile?.firstName} ${userProfile?.lastName}`
          : formatMessage(translations.unknown)
        }
      </Text>
      {
        invitationStatus && (
          <Tag
            color={invitationStatus === 'accepted' ? 'success' : 'warning'}
            style={tw.style('ml-auto')}
          >
            {formatMessage(translations[invitationStatus])}
          </Tag>
        )
      }
    </View>
  );
}
