import { defineMessages } from 'react-intl';

export default defineMessages({
  myjobglasses: {
    id: 'PublicScreen.myjobglasses',
    defaultMessage: 'my<boldThis>job</boldThis>glasses',
  },
  title: {
    id: 'PublicScreen.title',
    defaultMessage: 'Rencontrez vos pairs',
  },
  subtitle: {
    id: 'PublicScreen.subtitle',
    defaultMessage: 'Le réseau de développement professionnel',
  },
  register: {
    id: 'PublicScreen.register',
    defaultMessage: 'Rejoindre le réseau',
  },
  alreadySignin: {
    id: 'PublicScreen.alreadySignin',
    defaultMessage: 'Vous avez déjà un compte ? <linkThis>Connectez-vous</linkThis>',
  },
});
