import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import Toast from 'react-native-toast-message';
import commonErrorsTranslations from 'shared/translations/errors.translations';
import {
  ErrorMapping,
  MJGApolloError,
  MJGGraphQLErrorCode,
} from 'types/graphql-errors';

export default function useHandleGraphqlErrors() {
  const intl = useIntl();

  return <
    FormikField extends { [key: string]: string | boolean },
    ERRORS extends string,
    >(
    apolloError: MJGApolloError<ERRORS>,
    formikBag: FormikHelpers<FormikField>,
    errorMapping: ErrorMapping<FormikField, ERRORS>,
  ) => {
    const defaultError = intl.formatMessage(commonErrorsTranslations.default);
    const networkError = intl.formatMessage(commonErrorsTranslations.network);

    /**
     * Network error
     * display toastr and stop process
     */
    if (apolloError.networkError) {
      Toast.show({
        type: 'error',
        text1: networkError,
      });
      return;
    }

    const mjgErrors = apolloError.graphQLErrors[0].extensions.validationErrors;

    /**
     * Makes an array of possible error field
     */
    const managedFormField = Object
      .keys(errorMapping.translations)
      .filter((key) => key !== 'global');

    if (apolloError.graphQLErrors[0].extensions.code !== MJGGraphQLErrorCode.BAD_USER_INPUT) {
      const errorCode = apolloError.graphQLErrors[0].extensions.code;
      Toast.show({
        type: 'error',
        text1: errorMapping.translations.global[errorCode],
      });
      return;
    }

    /**
     * Catch errors that are not associated with a field
     * display them in toastr and stop process
     */
    const globalErrors = [];
    Object.entries(mjgErrors).forEach(([key, value]) => {
      if (!managedFormField.includes(key)) {
        globalErrors.push(errorMapping.translations.global[value[0]] || defaultError);
      }
    });
    if (globalErrors.length > 0) {
      globalErrors.forEach((error) => {
        Toast.show({
          type: 'error',
          text1: error,
        });
      });
      return;
    }

    /**
     * Identify all fields errors
     * set theme with the formik bag
     */
    managedFormField.forEach((fieldName) => {
      const fieldErrors = mjgErrors[fieldName];
      if (fieldErrors) {
        const errorCode = fieldErrors[0];
        const errorMessage = errorMapping.translations[fieldName][errorCode] || defaultError;
        if (errorMessage) {
          formikBag.setFieldError(fieldName, errorMessage);
        }
      }
    });
  };
}
