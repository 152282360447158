import { useNavigation } from '@react-navigation/native';
import { ScreenNavigationProp } from 'types/navigation';
import Screens from '../../../../screens';

export default function useCheckRegistrationTokenSuccess() {
  const { navigate } = useNavigation<ScreenNavigationProp<Screens.createPassword>>();

  return ({
    passwordToken,
  }: {
    passwordToken: string;
  }) => {
    navigate(Screens.createPassword, {
      passwordToken,
    });
  };
}
