import React from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';
import Toast from 'react-native-toast-message';
import tw, {
  ScreenContainer,
  Text,
} from 'design-system';
import AddProgramOwnerForm from './add-program-owner-form/AddProgramOwnerForm';
import translations from './translations';

export default function AddProgramOwner() {
  const intl = useIntl();

  return (
    <ScreenContainer>
      <View
        style={tw.style('flex-1 flex-col')}
      >
        <Text
          variant="header2"
          style={tw.style('text-center mb-8')}
        >
          {intl.formatMessage(translations.title)}
        </Text>
        <AddProgramOwnerForm />
      </View>
      <Toast />
    </ScreenContainer>
  );
}
