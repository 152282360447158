import { ApolloProvider } from '@apollo/client';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import React from 'react';
import { StatusBar } from 'expo-status-bar';
// import { useDeviceContext } from 'twrnc';
import LocalizationProvider from 'providers/localization/LocalizationProvider';
import AuthenticationProvider from 'providers/authorization/AuthorizationProvider';
// import tw from 'design-system';
import client from './graphql/graphql';
import RootNavigation from './navigation/RootNavigation';

export default function App() {
  // useDeviceContext(tw);

  return (
    <ActionSheetProvider>
      <ApolloProvider client={client}>
        <AuthenticationProvider>
          <LocalizationProvider>
            <StatusBar
              // eslint-disable-next-line react/style-prop-object
              style="dark"
              backgroundColor="#fff"
            />
            <RootNavigation />
          </LocalizationProvider>
        </AuthenticationProvider>
      </ApolloProvider>
    </ActionSheetProvider>
  );
}
