import React from 'react';
import { useIntl } from 'react-intl';
import { useMeQuery } from 'types/graphql';
import tw, {
  Text,
  ScreenAreaView,
  ScreenContainer,
  Loader,
} from 'design-system';
import translations from './translations';

export default function SecurityScreen() {
  const { formatMessage } = useIntl();

  const { loading } = useMeQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <ScreenAreaView edges={['bottom']}>
      <ScreenContainer>
        <Text
          variant="header1"
          style={tw.style('mb-4')}
        >
          {formatMessage(translations.title)}
        </Text>
      </ScreenContainer>
    </ScreenAreaView>
  );
}
