import { useNavigation } from '@react-navigation/native';
import React from 'react';
import {
  FlatList,
  View,
} from 'react-native';
import { useIntl } from 'react-intl';
import tw, {
  Text,
  ScreenAreaView,
  ScreenContainer,
  Loader,
  ButtonIcon,
} from 'design-system';
import { useMyCommunitiesQuery } from 'types/graphql';
import { ScreenNavigationProp } from 'types/navigation';
import Screens from '../../../screens';
import CommunityItem from './community-item/CommunityItem';
import translations from './translations';

export default function MyCommunities() {
  const intl = useIntl();
  const { data, loading } = useMyCommunitiesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { navigate } = useNavigation<ScreenNavigationProp<Screens.myCommunities>>();

  if (loading) {
    return <Loader />;
  }

  return (
    <ScreenAreaView edges={['bottom']}>
      <ScreenContainer>
        <View
          style={tw.style('flex-row items-center justify-between mb-6')}
        >
          <Text
            variant="header2"
          >
            {intl.formatMessage(translations.title)}
          </Text>
          <ButtonIcon
            icon="add"
            action={() => {
              navigate(Screens.createCommunity);
            }}
          />
        </View>
        {data.me.joinedCommunities.length === 0
          ? <Text>{intl.formatMessage(translations.noCommunities)}</Text>
          : (
            <FlatList
              data={data.me.joinedCommunities}
              renderItem={({ item, index }) => (
                <CommunityItem
                  key={index}
                  id={item.id}
                  title={item.name}
                />
              )}
              keyExtractor={({ id }) => id}
            />
          )}
        {data.me.authoredCommunities.length === 0
          ? <Text>{intl.formatMessage(translations.noAuthoredCommunities)}</Text>
          : (
            <FlatList
              data={data.me.authoredCommunities}
              renderItem={({ item, index }) => (
                <CommunityItem
                  key={index}
                  id={item.id}
                  title={item.name}
                />
              )}
              keyExtractor={({ id }) => id}
            />
          )}
      </ScreenContainer>
    </ScreenAreaView>
  );
}
