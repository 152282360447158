import React, { ReactNode } from 'react';
import {
  View,
  TextInputProps,
} from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import { useField } from 'formik';
import tw from '../../tw';
import Input from '../../basic/input/Input';
import Text from '../../basic/text/Text';

interface InputFormProps extends TextInputProps {
  fieldName: string;
  label: string;
  textHelper?: string;
  inputRightElement?: ReactNode;
  inputLeftElement?: ReactNode;
  style?: Style;
}
export default function InputForm({
  fieldName,
  editable = true,
  secureTextEntry = false,
  label,
  textHelper,
  inputRightElement,
  inputLeftElement,
  keyboardType,
  style,
}: InputFormProps) {
  const [field, meta] = useField(fieldName);
  const isValid = !(meta.error && meta.touched);

  return (
    <View
      style={tw.style(style)}
    >
      <Text
        variant="pretitle"
        style={tw.style(
          'mb-1',
          {
            'text-dark dark:text-grey-500': editable,
            'text-grey-200 dark:text-grey-800': !editable,
          },
        )}
      >
        {label}
      </Text>
      <Input
        value={field.value}
        editable={editable}
        secureTextEntry={secureTextEntry}
        inputRightElement={inputRightElement}
        inputLeftElement={inputLeftElement}
        keyboardType={keyboardType}
        onFieldChangeText={field.onChange(field.name)}
        onFieldBlur={field.onBlur(field.name)}
        isValid={isValid}
        accessibilityLabel={label}
      />
      {textHelper && isValid && (
        <Text
          variant="small"
          style={tw.style('mt-2', {
            'text-grey-500': editable,
            'text-grey-200 dark:text-grey-800': !editable,
          })}
        >
          {textHelper}
        </Text>
      )}
      {meta.error && meta.touched && (
        <Text
          variant="small"
          style={tw.style('mt-2 text-danger-500')}
        >
          {meta.error}
        </Text>
      )}
    </View>
  );
}
