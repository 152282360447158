import React, {
  useState,
  useEffect,
  ReactNode,
  useMemo,
} from 'react';
import { IntlProvider } from 'react-intl';
import { Loader } from 'design-system';
import Storage from '../../enums/storage';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../utils/storage/storage';
import loadLocaleTranslations from '../../utils/translations/load-local-translation';
import LocalizationContext from './LocalizationContext';

const defaultLocal = 'fr';

export default function LocalizationProvider({
  children,
}: {
  children: ReactNode,
}) {
  const [local, setLocal] = useState<string | undefined>(undefined);
  const [translations, setTranslations] = useState<{}>({});
  const [loading, setLoading] = useState<boolean>(true);

  // set local to context
  // set local to localstorage
  function handleChangeLanguage(value: string) {
    setLocal(value);
    setLocalStorageItem(Storage.LANGUAGE, value);
  }

  // set values with memoization
  const values = useMemo(() => ({
    local,
    setLocal: handleChangeLanguage,
  }), [local]);

  // load translations
  function handleLoadTranslations(currentLocal: string) {
    setLoading(true);
    loadLocaleTranslations(currentLocal).then((loadedTranslations) => {
      setTranslations(loadedTranslations);
      setLoading(false);
    });
  }

  // load translations on mount
  useEffect(() => {
    async function getLocalStorageLocal() {
      const currentLocal = await getLocalStorageItem(Storage.LANGUAGE, defaultLocal);
      handleChangeLanguage(currentLocal as string); // todo remove as string
      handleLoadTranslations(currentLocal || defaultLocal);
    }
    getLocalStorageLocal();
  }, []);

  if (loading || local === undefined) return <Loader />;

  return (
    <LocalizationContext.Provider
      value={values}
    >
      <IntlProvider
        messages={translations}
        locale={local}
        defaultLocale={defaultLocal}
      >
        {children}
      </IntlProvider>
    </LocalizationContext.Provider>
  );
}
