import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NavigationProp } from '@react-navigation/native';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  RootStackParamList,
  RouteProps,
} from 'types/navigation';
import { Button } from 'design-system';
import navigationTranslations from 'shared/translations/navigation.translations';
import Screens from '../../screens';
import ShowProgram from './show-program/ShowProgram';
import CreateProgram from './create-program/CreateProgram';
import UpdateProgram from './update-program/UpdateProgram';
import ProgramParticipantsList from './program-participants-list/ProgramParticipantsList';
import ProgramOwnersList from './program-owners-list/ProgramOwnersList';
import AddProgramParticipant from './add-program-participant/AddProgramParticipant';
import AddProgramOwner from './add-program-owner/AddProgramOwner';
import ProgramActions from './show-program/program-actions/ProgramActions';

export default function ProgramGroupStack() {
  const ProgramGroupStackNavigator = createNativeStackNavigator();
  const intl = useIntl();

  return (
    <>
      <ProgramGroupStackNavigator.Group>
        <ProgramGroupStackNavigator.Screen
          name={Screens.showProgram}
          component={ShowProgram}
          options={({
            navigation,
            route,
          }: {
            navigation: NavigationProp<RootStackParamList>,
            route: RouteProps<Screens.updateProgram>,
          }) => ({
            headerRight: () => (
              <>
                <Button
                  variant="outlined"
                  onPress={() => navigation.navigate(Screens.updateProgram, {
                    id: route.params.id,
                  })}
                >
                  {intl.formatMessage(navigationTranslations.openModificationModal)}
                </Button>
                <ProgramActions />
              </>
            ),
          })}
        />
        <ProgramGroupStackNavigator.Screen
          name={Screens.programParticipantsList}
          component={ProgramParticipantsList}
        />
        <ProgramGroupStackNavigator.Screen
          name={Screens.programOwnersList}
          component={ProgramOwnersList}
        />
      </ProgramGroupStackNavigator.Group>
      <ProgramGroupStackNavigator.Group
        screenOptions={{
          presentation: 'modal',
        }}
      >
        <ProgramGroupStackNavigator.Screen
          name={Screens.createProgram}
          component={CreateProgram}
        />
        <ProgramGroupStackNavigator.Screen
          name={Screens.updateProgram}
          component={UpdateProgram}
        />
        <ProgramGroupStackNavigator.Screen
          name={Screens.addProgramParticipant}
          component={AddProgramParticipant}
        />
        <ProgramGroupStackNavigator.Screen
          name={Screens.addProgramOwner}
          component={AddProgramOwner}
        />
      </ProgramGroupStackNavigator.Group>
    </>
  );
}
