import {
  StackActions,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { useIntl } from 'react-intl';
import Toast from 'react-native-toast-message';
import { useDeleteProgramMutation } from 'types/graphql';
import { RouteProps } from 'types/navigation';
import Screens from '../../../../../screens';
import translations from '../translations';

export default function useProgramDelete() {
  const intl = useIntl();
  const { params: { id } } = useRoute<RouteProps<Screens.showProgram>>();
  const { dispatch } = useNavigation();
  const [deleteProgram] = useDeleteProgramMutation({
    variables: {
      id,
    },
    refetchQueries: ['community'],
    onCompleted: () => {
      Toast.show({
        type: 'success',
        text1: intl.formatMessage(translations.deleteProgramSuccess),
      });
      dispatch(StackActions.pop());
    },
    onError: () => {
      Toast.show({
        type: 'error',
        text1: intl.formatMessage(translations.deleteProgramError),
      });
    },
  });
  return (
    deleteProgram
  );
}
