import { useRoute } from '@react-navigation/native';
import useUpdateUserPassword from './useCreatePassword';
import useSuccessCreatePassword from './useSuccessCreatePassword';

export default function useCreatePasswordForm() {
  const { params } = useRoute<{
    key: string;
    path?: string;
    name: string;
    params?: { [key: string]: any };
  }>();
  const initialValues = {
    password: '',
    passwordToken: params?.passwordToken || '',
  };
  const updatePassword = useUpdateUserPassword({
    onSuccess: useSuccessCreatePassword(),
  });

  return {
    initialValues,
    updatePassword,
  };
}
