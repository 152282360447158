import React from 'react';
import {
  Edge,
  SafeAreaView,
} from 'react-native-safe-area-context';
import tw from '../../tw';

interface IViewProps {
  children: React.ReactNode,
  edges?: ReadonlyArray<Edge>
}

export default function ScreenAreaView({
  children,
  edges,
}: IViewProps) {
  return (
    <SafeAreaView
      edges={edges}
      style={
        tw.style('flex-1 bg-light dark:bg-dark')
      }
    >
      {children}
    </SafeAreaView>
  );
}
