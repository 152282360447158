import React, {
  useState,
  ReactNode,
} from 'react';
import {
  View,
  TextInput,
  TextInputProps,
} from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import tw from '../../tw';

interface InputProps extends TextInputProps {
  inputRightElement?: ReactNode;
  inputLeftElement?: ReactNode;
  onFieldChangeText: (e: string) => void;
  onFieldBlur: (e: any) => void;
  isValid?: boolean;
  style?: Style;
}
export default function Input({
  value,
  onFieldChangeText,
  onFieldBlur,
  placeholder,
  inputLeftElement: InputLeftElement,
  inputRightElement: InputRightElement,
  keyboardType = 'default',
  secureTextEntry = false,
  editable = true,
  isValid = true,
  accessibilityLabel,
  style,
}: InputProps) {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <View
      style={tw.style(
        'flex flex-row justify-between px-1',
        'h-10 border border-grey-500 rounded-md',
        {
          'border-2 border-primary-500': isFocus,
          'border-danger-500': !isValid,
          'border-grey-200 dark:border-grey-800': !editable,
        },
        style,
      )}
    >
      {InputLeftElement}
      <TextInput
        value={value}
        editable={editable}
        placeholder={placeholder}
        keyboardType={keyboardType}
        secureTextEntry={secureTextEntry}
        onChangeText={onFieldChangeText}
        onFocus={() => setIsFocus(true)}
        onBlur={(e) => {
          setIsFocus(false);
          onFieldBlur(e);
        }}
        selectionColor={tw.color('primary-500')}
        placeholderTextColor={tw.color(editable ? 'grey-500' : 'grey-200')}
        style={tw.style(
          'flex-1 h-auto rounded-md px-1',
          'text-dark dark:text-light',
        )}
        accessible
        autoFocus={false}
        autoCorrect={false}
        autoCapitalize="none"
        accessibilityLabel={accessibilityLabel}
        accessibilityState={{
          disabled: !editable,
          selected: isFocus,
        }}
      />
      {InputRightElement}
    </View>
  );
}
