import React, { ComponentPropsWithoutRef } from 'react';
import { View } from 'react-native';
import tw from '../../tw';
import Avatar from '../../basic/avatar/Avatar';

const AvatarPosition = {
  0: '50',
  1: '40',
  2: '30',
  3: '20',
  4: '10',
  5: '0',
  6: '-10',
};

type AvatarListProps = {
  users?: Array<{
    id: string
    userProfile?: ComponentPropsWithoutRef<typeof Avatar>['profile'];
  }>;
  itemsToDisplay?: 1|2|3|4|5|6|7;
}

export default function AvatarList({
  users,
  itemsToDisplay = 3,
}: AvatarListProps) {
  return (
    <View
      style={tw.style('flex-row items-center pl-4')}
    >
      {
        users
          .slice(0, itemsToDisplay)
          .map((user, index) => (
            <Avatar
              key={user.id}
              profile={user.userProfile}
              style={tw.style(`-ml-4 border-2 z-${AvatarPosition[index]} border-white`)}
            />
          ))

      }
    </View>
  );
}
