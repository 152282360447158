import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
  Upload: any;
};

/** Input for accepting published Consent */
export type AcceptConsentInput = {
  /** Consent id */
  consentId: Scalars['ID'];
  /** User id */
  userId: Scalars['ID'];
};

/** The activity_type of a UserActivity */
export enum Activity {
  AcceptConsent = 'accept_consent',
  AcceptedCommunityAdminInvitation = 'accepted_community_admin_invitation',
  AcceptedCommunityAdminRequest = 'accepted_community_admin_request',
  AcceptedCommunityMemberInvitation = 'accepted_community_member_invitation',
  AcceptedCommunityMemberRequest = 'accepted_community_member_request',
  AcceptedParticipationInvitation = 'accepted_participation_invitation',
  AcceptedPropertyInvitation = 'accepted_property_invitation',
  AskedPassword = 'asked_password',
  CanceledCommunityAdminInvitation = 'canceled_community_admin_invitation',
  CanceledCommunityAdminRequest = 'canceled_community_admin_request',
  CanceledCommunityMemberInvitation = 'canceled_community_member_invitation',
  CanceledCommunityMemberRequest = 'canceled_community_member_request',
  CanceledParticipationInvitation = 'canceled_participation_invitation',
  CanceledPropertyInvitation = 'canceled_property_invitation',
  CreatedCommunity = 'created_community',
  CreatedCommunityAdminInvitation = 'created_community_admin_invitation',
  CreatedCommunityAdminRequest = 'created_community_admin_request',
  CreatedCommunityMemberInvitation = 'created_community_member_invitation',
  CreatedCommunityMemberRequest = 'created_community_member_request',
  CreatedParticipationInvitation = 'created_participation_invitation',
  CreatedProfile = 'created_profile',
  CreatedProgram = 'created_program',
  CreatedPropertyInvitation = 'created_property_invitation',
  DeclinedCommunityAdminInvitation = 'declined_community_admin_invitation',
  DeclinedCommunityAdminRequest = 'declined_community_admin_request',
  DeclinedCommunityMemberInvitation = 'declined_community_member_invitation',
  DeclinedCommunityMemberRequest = 'declined_community_member_request',
  DeclinedParticipationInvitation = 'declined_participation_invitation',
  DeclinedPropertyInvitation = 'declined_property_invitation',
  DeletedCommunity = 'deleted_community',
  DeletedCommunityAdmin = 'deleted_community_admin',
  DeletedCommunityMember = 'deleted_community_member',
  DeletedParticipation = 'deleted_participation',
  DeletedProgram = 'deleted_program',
  DeletedProperty = 'deleted_property',
  PublishedProgram = 'published_program',
  UpdatedCommunity = 'updated_community',
  UpdatedProfile = 'updated_profile',
  UpdatedProgram = 'updated_program'
}

/** Input for asking reset password instructions email for a User */
export type AskPasswordUserInput = {
  /** Email of the user */
  email: Scalars['String'];
};

/** Input to reload registration of a User */
export type AskUserRegistrationInput = {
  /** Email of the user */
  email: Scalars['String'];
};

/** Input to check the validity of a User's password_token */
export type CheckPasswordTokenInput = {
  /** User's email */
  email: Scalars['String'];
  /** User password token from a previously sent email */
  passwordToken: Scalars['String'];
};

export type CollectionMetadata = {
  __typename?: 'CollectionMetadata';
  currentPage: Scalars['Int'];
  limitValue: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type Community = {
  __typename?: 'Community';
  admins?: Maybe<Array<User>>;
  author: User;
  communityAdmins?: Maybe<Array<CommunityAdmin>>;
  communityMembers?: Maybe<CommunityMemberCollection>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  members?: Maybe<Array<User>>;
  name: Scalars['String'];
  programs?: Maybe<Array<Program>>;
  updatedAt: Scalars['ISO8601DateTime'];
};


export type CommunityCommunityMembersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type CommunityAdmin = {
  __typename?: 'CommunityAdmin';
  community: Community;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

export type CommunityAdminInvitation = {
  __typename?: 'CommunityAdminInvitation';
  author: User;
  community: Community;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  status: CommunityAdminInvitationStatus;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** The status of an CommunityAdminInvitation */
export enum CommunityAdminInvitationStatus {
  Accepted = 'accepted',
  Canceled = 'canceled',
  Declined = 'declined',
  Pending = 'pending'
}

export type CommunityAdminRequest = {
  __typename?: 'CommunityAdminRequest';
  community: Community;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  status: CommunityAdminRequestStatus;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** The status of an CommunityAdminRequest */
export enum CommunityAdminRequestStatus {
  Accepted = 'accepted',
  Canceled = 'canceled',
  Declined = 'declined',
  Pending = 'pending'
}

export type CommunityMember = {
  __typename?: 'CommunityMember';
  community: Community;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

export type CommunityMemberCollection = {
  __typename?: 'CommunityMemberCollection';
  collection: Array<CommunityMember>;
  metadata: CollectionMetadata;
};

export type CommunityMemberInvitation = {
  __typename?: 'CommunityMemberInvitation';
  author: User;
  community: Community;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  status: CommunityMemberInvitationStatus;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** The status of an CommunityMemberInvitation */
export enum CommunityMemberInvitationStatus {
  Accepted = 'accepted',
  Canceled = 'canceled',
  Declined = 'declined',
  Pending = 'pending'
}

export type CommunityMemberRequest = {
  __typename?: 'CommunityMemberRequest';
  community: Community;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  status: CommunityMemberRequestStatus;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** The status of an CommunityMemberRequest */
export enum CommunityMemberRequestStatus {
  Accepted = 'accepted',
  Canceled = 'canceled',
  Declined = 'declined',
  Pending = 'pending'
}

export type Consent = {
  __typename?: 'Consent';
  content: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  publicationStatus: ConsentPublicationStatus;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The publication_status of a Consent */
export enum ConsentPublicationStatus {
  Archived = 'archived',
  Draft = 'draft',
  Published = 'published'
}

/** Input for creating a CommunityAdminInvitation */
export type CreateCommunityAdminInvitationInput = {
  /** Community id */
  communityId: Scalars['ID'];
  /** Email of the user */
  email: Scalars['String'];
};

/** Input for creating a CommunityAdminRequest */
export type CreateCommunityAdminRequestInput = {
  /** Community id */
  communityId: Scalars['ID'];
};

/** Input to create Community */
export type CreateCommunityInput = {
  /** Community description */
  description?: InputMaybe<Scalars['String']>;
  /** The jpeg/png logo of the Community */
  logo?: InputMaybe<Scalars['Upload']>;
  /** Community name */
  name: Scalars['String'];
};

/** Input for creating a CommunityMemberInvitation */
export type CreateCommunityMemberInvitationInput = {
  /** Community id */
  communityId: Scalars['ID'];
  /** Email of the user */
  email: Scalars['String'];
};

/** Input for creating a CommunityMemberRequest */
export type CreateCommunityMemberRequestInput = {
  /** Community id */
  communityId: Scalars['ID'];
};

/** Input to create a ParticipationInvitation */
export type CreateParticipationInvitationInput = {
  /** email of the User */
  email: Scalars['String'];
  /** ID of the Program */
  programId: Scalars['ID'];
};

/** Input to create a Program */
export type CreateProgramInput = {
  /** ID of the Program's Community */
  communityId: Scalars['ID'];
  /** Program description */
  description?: InputMaybe<Scalars['String']>;
  /** Program name */
  title: Scalars['String'];
  visibilityStatus?: InputMaybe<ProgramVisibilityStatus>;
};

/** Input for creating a PropertyInvitation */
export type CreatePropertyInvitationInput = {
  /** Email of the user */
  email: Scalars['String'];
  /** Program id */
  programId: Scalars['ID'];
};

/** Input for creating a UserProfile */
export type CreateUserProfileInput = {
  /** The jpeg/png avatar of the user */
  avatar?: InputMaybe<Scalars['Upload']>;
  /** First name of the user */
  firstName: Scalars['String'];
  /** Last name of the user */
  lastName: Scalars['String'];
  /** Phone number of the user */
  phoneNumber: Scalars['String'];
  /** ID of the user */
  userId: Scalars['ID'];
};

/** Input for login a User */
export type LoginUserInput = {
  /** Email of the user */
  email: Scalars['String'];
  /** Password set by the user */
  password: Scalars['String'];
};

/** Input for logout a User */
export type LogoutUserInput = {
  /** Current device where the user has an existing session */
  client?: InputMaybe<Scalars['String']>;
  /** Id of the user */
  id: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Accept a pending CommunityAdminInvitation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - STATUS:
   *         - COMMUNITY_ADMIN_INVITATION_STATUS_INCLUSION
   *         - COMMUNITY_ADMIN_INVITATION_STATUS_ALREADY_ACCEPTED
   *       - COMMUNITY_ADMIN:
   *         - COMMUNITY_ADMIN_INVITATION_COMMUNITY_ADMIN_EXISTS
   */
  acceptCommunityAdminInvitation?: Maybe<CommunityAdminInvitation>;
  /**
   * Accept a pending CommunityAdminRequest
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - STATUS:
   *         - COMMUNITY_ADMIN_REQUEST_STATUS_INCLUSION
   *         - COMMUNITY_ADMIN_REQUEST_STATUS_ALREADY_ACCEPTED
   *       - COMMUNITY_ADMIN:
   *         - COMMUNITY_ADMIN_REQUEST_COMMUNITY_ADMIN_EXISTS
   */
  acceptCommunityAdminRequest?: Maybe<CommunityAdminRequest>;
  /**
   * Accept a pending CommunityMemberInvitation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - STATUS:
   *         - COMMUNITY_MEMBER_INVITATION_STATUS_INCLUSION
   *         - COMMUNITY_MEMBER_INVITATION_STATUS_ALREADY_ACCEPTED
   *       - COMMUNITY_MEMBER:
   *         - COMMUNITY_MEMBER_INVITATION_COMMUNITY_MEMBER_EXISTS
   */
  acceptCommunityMemberInvitation?: Maybe<CommunityMemberInvitation>;
  /**
   * Accept a pending CommunityMemberRequest
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - STATUS:
   *         - COMMUNITY_MEMBER_REQUEST_STATUS_INCLUSION
   *         - COMMUNITY_MEMBER_REQUEST_STATUS_ALREADY_ACCEPTED
   *       - COMMUNITY_MEMBER:
   *         - COMMUNITY_MEMBER_REQUEST_COMMUNITY_MEMBER_EXISTS
   */
  acceptCommunityMemberRequest?: Maybe<CommunityMemberRequest>;
  /**
   * Create UserConsent to accept Published Consent
   * Errors:
   *   - NOT_FOUND
   *   - UNAUTHORIZED
   */
  acceptConsent?: Maybe<UserConsent>;
  /**
   * Accept a pending PropertyInvitation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - STATUS:
   *         - PROPERTY_INVITATION_STATUS_INCLUSION
   *         - PROPERTY_INVITATION_STATUS_ALREADY_ACCEPTED
   *       - PROGRAM:
   *         - PROPERTY_INVITATION_PROGRAM_OWNER_EXISTS
   */
  acceptPropertyInvitation?: Maybe<PropertyInvitation>;
  /**
   * Send an email containing a token to reset the password of a User
   *   Errors:
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT
   */
  askPasswordUser?: Maybe<User>;
  /**
   * Sending another registration email to finish registration
   * of user
   *   Errors:
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  askUserRegistration?: Maybe<User>;
  /**
   * Cancel a pending CommunityAdminInvitation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - STATUS:
   *         - COMMUNITY_ADMIN_INVITATION_STATUS_INCLUSION
   *         - COMMUNITY_ADMIN_INVITATION_STATUS_ALREADY_CANCELED
   */
  cancelCommunityAdminInvitation?: Maybe<CommunityAdminInvitation>;
  /**
   * Cancel a pending CommunityAdminRequest
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - STATUS:
   *         - COMMUNITY_ADMIN_REQUEST_STATUS_INCLUSION
   *         - COMMUNITY_ADMIN_REQUEST_STATUS_ALREADY_CANCELED
   */
  cancelCommunityAdminRequest?: Maybe<CommunityAdminRequest>;
  /**
   * Cancel a pending CommunityMemberInvitation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - STATUS:
   *         - COMMUNITY_MEMBER_INVITATION_STATUS_INCLUSION
   *         - COMMUNITY_MEMBER_INVITATION_STATUS_ALREADY_CANCELED
   */
  cancelCommunityMemberInvitation?: Maybe<CommunityMemberInvitation>;
  /**
   * Cancel a pending CommunityMemberRequest
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - STATUS:
   *         - COMMUNITY_MEMBER_REQUEST_STATUS_INCLUSION
   *         - COMMUNITY_MEMBER_REQUEST_STATUS_ALREADY_CANCELED
   */
  cancelCommunityMemberRequest?: Maybe<CommunityMemberRequest>;
  /**
   * Cancel a pending PropertyInvitation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - STATUS:
   *         - PROPERTY_INVITATION_STATUS_INCLUSION
   *         - PROPERTY_INVITATION_STATUS_ALREADY_CANCELED
   */
  cancelPropertyInvitation?: Maybe<PropertyInvitation>;
  /**
   * Check the validity of a User's password_token
   * retrieved from an email
   * Errors:
   *   - NOT_FOUND
   *   - BAD_USER_INPUT:
   *     - PASSWORD_TOKEN:
   *       - USER_PASSWORD_TOKEN_INVALID
   *       - USER_PASSWORD_TOKEN_NOT_GENERATED
   *       - USER_PASSWORD_TOKEN_EXPIRED
   */
  checkPasswordToken?: Maybe<User>;
  /**
   * Create a Community
   * send an email to the current_user after creation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - NAME:
   *         - COMMUNITY_NAME_BLANK
   *         - COMMUNITY_NAME_TAKEN
   */
  createCommunity?: Maybe<Community>;
  /**
   * Create a CommunityAdminInvitation
   *   will fetch any existing User from the email and associate it to the invitation
   *   or create a User from the email
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - COMMUNITY_ADMIN:
   *         - COMMUNITY_ADMIN_INVITATION_COMMUNITY_ADMIN_EXISTS
   */
  createCommunityAdminInvitation?: Maybe<CommunityAdminInvitation>;
  /**
   * Create a CommunityAdminRequest
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - COMMUNITY_ADMIN:
   *         - COMMUNITY_ADMIN_REQUEST_COMMUNITY_ADMIN_EXISTS
   */
  createCommunityAdminRequest?: Maybe<CommunityAdminRequest>;
  /**
   * Create a CommunityMemberInvitation
   *   will fetch any existing User from the email and associate it to the invitation
   *   or create a User from the email
   *   ****************
   *     Will accept the community_member_invitation right after creation
   *     to comply with existing product's flow
   *   ****************
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - COMMUNITY_MEMBER:
   *         - COMMUNITY_MEMBER_INVITATION_COMMUNITY_MEMBER_EXISTS
   */
  createCommunityMemberInvitation?: Maybe<CommunityMemberInvitation>;
  /**
   * Create a CommunityMemberRequest
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - COMMUNITY_MEMBER:
   *         - COMMUNITY_MEMBER_REQUEST_COMMUNITY_MEMBER_EXISTS
   */
  createCommunityMemberRequest?: Maybe<CommunityMemberRequest>;
  /**
   * Create a ParticipationInvitation
   *   will fetch any existing User from the email and associate it to the invitation
   *   or create a User from the email
   *   ****************
   *     Will accept the participation_invitation right after creation
   *     to comply with current product's flow
   *   ****************
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - PARTICIPATION_INVITATION:
   *         - PARTICIPATION_INVITATION_PROGRAM_OWNER_EXISTS
   */
  createParticipationInvitation?: Maybe<ParticipationInvitation>;
  /**
   * Create a Program
   *   Errors:
   *     - UNAUTHENTICATED
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       TITLE:
   *         - PROGRAM_TITLE_BLANK
   */
  createProgram?: Maybe<Program>;
  /**
   * Create a PropertyInvitation
   *   will fetch any existing User from the email and associate it to the invitation
   *   or create a User from the email
   *   ****************
   *     Will accept the property_invitation right after creation
   *     to comply with current product's flow
   *   ****************
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - PROPERTY_INVITATION:
   *         - PROPERTY_INVITATION_PROGRAM_OWNER_EXISTS
   */
  createPropertyInvitation?: Maybe<PropertyInvitation>;
  /**
   * Create a UserProfile
   *   Errors:
   *     - UNAUTHENTICATED
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - USER:
   *         - USER_PROFILE_USER_BLANK
   *         - USER_PROFILE_USER_ALREADY_HAVE_A_USER_PROFILE
   *       - FIRST_NAME:
   *         - USER_PROFILE_FIRST_NAME_BLANK
   *       - LAST_NAME:
   *         - USER_PROFILE_LAST_NAME_BLANK
   *       - PHONE_NUMBER:
   *         - USER_PROFILE_PHONE_NUMBER_BLANK
   *         - USER_PROFILE_PHONE_NUMBER_INVALID
   *         - USER_PROFILE_PHONE_NUMBER_TAKEN
   */
  createUserProfile?: Maybe<UserProfile>;
  /**
   * Decline a pending CommunityAdminInvitation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - STATUS:
   *         - COMMUNITY_ADMIN_INVITATION_STATUS_INCLUSION
   *         - COMMUNITY_ADMIN_INVITATION_STATUS_ALREADY_DECLINED
   */
  declineCommunityAdminInvitation?: Maybe<CommunityAdminInvitation>;
  /**
   * Decline a pending CommunityAdminRequest
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - STATUS:
   *         - COMMUNITY_ADMIN_REQUEST_STATUS_INCLUSION
   *         - COMMUNITY_ADMIN_REQUEST_STATUS_ALREADY_DECLINED
   */
  declineCommunityAdminRequest?: Maybe<CommunityAdminRequest>;
  /**
   * Decline a pending CommunityMemberInvitation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - STATUS:
   *         - COMMUNITY_MEMBER_INVITATION_STATUS_INCLUSION
   *         - COMMUNITY_MEMBER_INVITATION_STATUS_ALREADY_DECLINED
   */
  declineCommunityMemberInvitation?: Maybe<CommunityMemberInvitation>;
  /**
   * Decline a pending CommunityMemberRequest
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - STATUS:
   *         - COMMUNITY_MEMBER_REQUEST_STATUS_INCLUSION
   *         - COMMUNITY_MEMBER_REQUEST_STATUS_ALREADY_DECLINED
   */
  declineCommunityMemberRequest?: Maybe<CommunityMemberRequest>;
  /**
   * Decline a pending PropertyInvitation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - STATUS:
   *         - PROPERTY_INVITATION_STATUS_INCLUSION
   *         - PROPERTY_INVITATION_STATUS_ALREADY_DECLINED
   */
  declinePropertyInvitation?: Maybe<PropertyInvitation>;
  /**
   * Delete a Community
   * send an email to the current_user upon Community deletion
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT
   */
  deleteCommunity?: Maybe<Community>;
  /**
   * Delete a CommunityAdmin
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT
   *       - USER:
   *         - COMMUNITY_ADMIN_USER_CANNOT_BE_REMOVED
   */
  deleteCommunityAdmin?: Maybe<CommunityAdmin>;
  /**
   * Delete a CommunityMember
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT
   *       - USER:
   *         - COMMUNITY_MEMBER_USER_CANNOT_BE_REMOVED
   */
  deleteCommunityMember?: Maybe<CommunityMember>;
  /**
   * Deletes a program
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT
   */
  deleteProgram?: Maybe<Program>;
  /**
   * Destroy a Participation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT
   */
  destroyParticipation?: Maybe<Participation>;
  /**
   * Destroy a Property
   *   Errors:
   *     - UNAUTHENTICATED
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT
   */
  destroyProperty?: Maybe<Participation>;
  /**
   * Authenticate the User to access the application through a new session
   *   Errors:
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - PASSWORD:
   *         - USER_PASSWORD_WRONG_PASSWORD
   *       - EMAIL:
   *         - USER_EMAIL_LOCKED
   */
  loginUser?: Maybe<User>;
  /**
   * Terminate the session of the User on the application
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  logoutUser?: Maybe<User>;
  /**
   * Publish a Program
   *   Errors:
   *     - UNAUTHENTICATED
   *     - UNAUTHORIZED
   *     - NOT_FOUND
   */
  publishProgram?: Maybe<Program>;
  /**
   * Register a new User
   * sending an email containing a token to reset the password and confirm this User
   *   Errors:
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - EMAIL:
   *         - USER_EMAIL_BLANK
   *         - USER_EMAIL_INVALID
   *         - USER_EMAIL_TAKEN
   */
  registerUser?: Maybe<User>;
  /**
   * Update a Community
   * send an email to confirm Community update
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - NAME:
   *         - COMMUNITY_NAME_BLANK
   *         - COMMUNITY_NAME_TAKEN
   */
  updateCommunity?: Maybe<Community>;
  /**
   * Update a Program
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       TITLE:
   *         - PROGRAM_TITLE_BLANK
   */
  updateProgram?: Maybe<Program>;
  /**
   * Update a User
   * Errors:
   *   - NOT_FOUND
   *   - UNAUTHORIZED
   *   - BAD_USER_INPUT:
   *     - PASSWORD:
   *       - USER_PASSWORD_BLANK
   *       - USER_PASSWORD_NOT_STRONG_ENOUGH
   *     - PASSWORD_TOKEN:
   *       - USER_PASSWORD_TOKEN_NOT_GENERATED
   *       - USER_PASSWORD_TOKEN_EXPIRED
   */
  updateUserPassword?: Maybe<User>;
  /**
   * Update a UserProfile
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   *     - BAD_USER_INPUT:
   *       - FIRST_NAME:
   *         - USER_PROFILE_FIRST_NAME_BLANK
   *       - LAST_NAME:
   *         - USER_PROFILE_LAST_NAME_BLANK
   *       - PHONE_NUMBER:
   *         - USER_PROFILE_PHONE_NUMBER_TAKEN
   *         - USER_PROFILE_PHONE_NUMBER_BLANK
   *         - USER_PROFILE_PHONE_NUMBER_INVALID
   */
  updateUserProfile?: Maybe<UserProfile>;
};


export type MutationAcceptCommunityAdminInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationAcceptCommunityAdminRequestArgs = {
  id: Scalars['ID'];
};


export type MutationAcceptCommunityMemberInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationAcceptCommunityMemberRequestArgs = {
  id: Scalars['ID'];
};


export type MutationAcceptConsentArgs = {
  input: AcceptConsentInput;
};


export type MutationAcceptPropertyInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationAskPasswordUserArgs = {
  input: AskPasswordUserInput;
};


export type MutationAskUserRegistrationArgs = {
  input: AskUserRegistrationInput;
};


export type MutationCancelCommunityAdminInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationCancelCommunityAdminRequestArgs = {
  id: Scalars['ID'];
};


export type MutationCancelCommunityMemberInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationCancelCommunityMemberRequestArgs = {
  id: Scalars['ID'];
};


export type MutationCancelPropertyInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationCheckPasswordTokenArgs = {
  input: CheckPasswordTokenInput;
};


export type MutationCreateCommunityArgs = {
  input: CreateCommunityInput;
};


export type MutationCreateCommunityAdminInvitationArgs = {
  input: CreateCommunityAdminInvitationInput;
};


export type MutationCreateCommunityAdminRequestArgs = {
  input: CreateCommunityAdminRequestInput;
};


export type MutationCreateCommunityMemberInvitationArgs = {
  input: CreateCommunityMemberInvitationInput;
};


export type MutationCreateCommunityMemberRequestArgs = {
  input: CreateCommunityMemberRequestInput;
};


export type MutationCreateParticipationInvitationArgs = {
  input: CreateParticipationInvitationInput;
};


export type MutationCreateProgramArgs = {
  input: CreateProgramInput;
};


export type MutationCreatePropertyInvitationArgs = {
  input: CreatePropertyInvitationInput;
};


export type MutationCreateUserProfileArgs = {
  input: CreateUserProfileInput;
};


export type MutationDeclineCommunityAdminInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationDeclineCommunityAdminRequestArgs = {
  id: Scalars['ID'];
};


export type MutationDeclineCommunityMemberInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationDeclineCommunityMemberRequestArgs = {
  id: Scalars['ID'];
};


export type MutationDeclinePropertyInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCommunityArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCommunityAdminArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCommunityMemberArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProgramArgs = {
  id: Scalars['ID'];
};


export type MutationDestroyParticipationArgs = {
  id: Scalars['ID'];
};


export type MutationDestroyPropertyArgs = {
  id: Scalars['ID'];
};


export type MutationLoginUserArgs = {
  input: LoginUserInput;
};


export type MutationLogoutUserArgs = {
  input: LogoutUserInput;
};


export type MutationPublishProgramArgs = {
  id: Scalars['ID'];
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationUpdateCommunityArgs = {
  id: Scalars['ID'];
  input: UpdateCommunityInput;
};


export type MutationUpdateProgramArgs = {
  id: Scalars['ID'];
  input: UpdateProgramInput;
};


export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};


export type MutationUpdateUserProfileArgs = {
  id: Scalars['ID'];
  input: UpdateUserProfileInput;
};

export type Organization = {
  __typename?: 'Organization';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Participation = {
  __typename?: 'Participation';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  program: Program;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

export type ParticipationInvitation = {
  __typename?: 'ParticipationInvitation';
  author: User;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  program: Program;
  status: ParticipationInvitationStatus;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** The status of a ParticipationInvitation */
export enum ParticipationInvitationStatus {
  Accepted = 'accepted',
  Canceled = 'canceled',
  Declined = 'declined',
  Pending = 'pending'
}

export type Program = {
  __typename?: 'Program';
  author: User;
  community: Community;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owners?: Maybe<Array<User>>;
  participants?: Maybe<Array<User>>;
  participations?: Maybe<Array<Participation>>;
  properties?: Maybe<Array<Property>>;
  propertyInvitations?: Maybe<Array<PropertyInvitation>>;
  publicationStatus: ProgramPublicationStatus;
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  visibilityStatus: ProgramVisibilityStatus;
};

/** The publication_status of a program */
export enum ProgramPublicationStatus {
  Archived = 'archived',
  Draft = 'draft',
  Published = 'published'
}

/** The visibility_status of a program */
export enum ProgramVisibilityStatus {
  PrivateProgram = 'private_program',
  PublicProgram = 'public_program'
}

export type Property = {
  __typename?: 'Property';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  program: Program;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

export type PropertyInvitation = {
  __typename?: 'PropertyInvitation';
  author: User;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  program: Program;
  status: PropertyInvitationStatus;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** The status of a PropertyInvitation */
export enum PropertyInvitationStatus {
  Accepted = 'accepted',
  Canceled = 'canceled',
  Declined = 'declined',
  Pending = 'pending'
}

export type Query = {
  __typename?: 'Query';
  /**
   * A Community
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  community?: Maybe<Community>;
  /**
   * A CommunityAdmin
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  communityAdmin?: Maybe<CommunityAdmin>;
  /**
   * A CommunityAdminInvitation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  communityAdminInvitation?: Maybe<CommunityAdminInvitation>;
  /**
   * A CommunityAdminRequest
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  communityAdminRequest?: Maybe<CommunityAdminRequest>;
  /**
   * A CommunityMember
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  communityMember?: Maybe<CommunityMember>;
  /**
   * A CommunityMemberInvitation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  communityMemberInvitation?: Maybe<CommunityMemberInvitation>;
  /**
   * A CommunityMemberRequest
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  communityMemberRequest?: Maybe<CommunityMemberRequest>;
  /**
   * The current_user - User
   *   Errors:
   *    - UNAUTHENTICATED
   *    - NOT_FOUND
   *    - UNAUTHORIZED
   */
  me?: Maybe<User>;
  /**
   * An Organization
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  organization?: Maybe<Organization>;
  /**
   * A Participation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  participation?: Maybe<Participation>;
  /**
   * A ParticipationInvitation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  participationInvitation?: Maybe<ParticipationInvitation>;
  /**
   * A Program
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  program?: Maybe<Program>;
  /**
   * A Property
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  property?: Maybe<Property>;
  /**
   * A PropertyInvitation
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  propertyInvitation?: Maybe<PropertyInvitation>;
  /**
   * The published consent
   *   Errors:
   *     - NOT_FOUND
   */
  publishedConsent?: Maybe<Consent>;
  /**
   * A User
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  user?: Maybe<User>;
  /**
   * A UserActivity
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  userActivity?: Maybe<UserActivity>;
  /**
   * A UserProfile
   *   Errors:
   *     - UNAUTHENTICATED
   *     - NOT_FOUND
   *     - UNAUTHORIZED
   */
  userProfile?: Maybe<UserProfile>;
};


export type QueryCommunityArgs = {
  id: Scalars['ID'];
};


export type QueryCommunityAdminArgs = {
  id: Scalars['ID'];
};


export type QueryCommunityAdminInvitationArgs = {
  id: Scalars['ID'];
};


export type QueryCommunityAdminRequestArgs = {
  id: Scalars['ID'];
};


export type QueryCommunityMemberArgs = {
  id: Scalars['ID'];
};


export type QueryCommunityMemberInvitationArgs = {
  id: Scalars['ID'];
};


export type QueryCommunityMemberRequestArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryParticipationArgs = {
  id: Scalars['ID'];
};


export type QueryParticipationInvitationArgs = {
  id: Scalars['ID'];
};


export type QueryProgramArgs = {
  id: Scalars['ID'];
};


export type QueryPropertyArgs = {
  id: Scalars['ID'];
};


export type QueryPropertyInvitationArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserActivityArgs = {
  id: Scalars['ID'];
};


export type QueryUserProfileArgs = {
  id: Scalars['ID'];
};

/** Input for registering a User */
export type RegisterUserInput = {
  /** Email of the user */
  email: Scalars['String'];
};

/** Input to update Community */
export type UpdateCommunityInput = {
  /** Community description */
  description?: InputMaybe<Scalars['String']>;
  /** The jpeg/png logo of the Community */
  logo?: InputMaybe<Scalars['Upload']>;
  /** Community name */
  name?: InputMaybe<Scalars['String']>;
};

/** Input to update a Program */
export type UpdateProgramInput = {
  /** Program description */
  description?: InputMaybe<Scalars['String']>;
  /** Program name */
  title?: InputMaybe<Scalars['String']>;
  visibilityStatus?: InputMaybe<ProgramVisibilityStatus>;
};

/** Input for updating a User */
export type UpdateUserPasswordInput = {
  /** User password */
  password: Scalars['String'];
  /** User password token from a previously sent email */
  passwordToken: Scalars['String'];
};

/** Input for UserProfile update */
export type UpdateUserProfileInput = {
  /** The jpeg/png avatar of the user */
  avatar?: InputMaybe<Scalars['Upload']>;
  /** First name of the user */
  firstName: Scalars['String'];
  /** Last name of the user */
  lastName: Scalars['String'];
  /** Phone number of the user */
  phoneNumber: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  activities?: Maybe<Array<UserActivity>>;
  administrableCommunities?: Maybe<Array<Community>>;
  authoredCommunities?: Maybe<Array<Community>>;
  communityAdmins?: Maybe<Array<CommunityAdmin>>;
  communityMembers?: Maybe<Array<CommunityMember>>;
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  joinedCommunities?: Maybe<Array<Community>>;
  joinedPrograms?: Maybe<Array<Program>>;
  ownedPrograms?: Maybe<Array<Program>>;
  participations?: Maybe<Array<Participation>>;
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  properties?: Maybe<Array<Property>>;
  updatedAt: Scalars['ISO8601DateTime'];
  userProfile?: Maybe<UserProfile>;
};

export type UserActivity = {
  __typename?: 'UserActivity';
  activityType: Activity;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  parameters?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

export type UserConsent = {
  __typename?: 'UserConsent';
  consent: Consent;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type LogoutUserMutationVariables = Exact<{
  id: Scalars['ID'];
  client?: InputMaybe<Scalars['String']>;
}>;


export type LogoutUserMutation = { __typename?: 'Mutation', logoutUser?: { __typename?: 'User', id: string } | null };

export type CreateCommunityMemberInvitationMutationVariables = Exact<{
  communityId: Scalars['ID'];
  email: Scalars['String'];
}>;


export type CreateCommunityMemberInvitationMutation = { __typename?: 'Mutation', createCommunityMemberInvitation?: { __typename?: 'CommunityMemberInvitation', id: string, status: CommunityMemberInvitationStatus, user: { __typename?: 'User', id: string, email: string, userProfile?: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string } | null }, author: { __typename?: 'User', id: string }, community: { __typename?: 'Community', id: string } } | null };

export type CommunityMembersListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CommunityMembersListQuery = { __typename?: 'Query', community?: { __typename?: 'Community', id: string, name: string, description?: string | null, members?: Array<{ __typename?: 'User', id: string, userProfile?: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string } | null }> | null } | null };

export type CommunityProgramsListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CommunityProgramsListQuery = { __typename?: 'Query', community?: { __typename?: 'Community', id: string, programs?: Array<{ __typename?: 'Program', id: string, title: string, publicationStatus: ProgramPublicationStatus }> | null } | null };

export type CreateCommunityMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
  logo?: InputMaybe<Scalars['Upload']>;
}>;


export type CreateCommunityMutation = { __typename?: 'Mutation', createCommunity?: { __typename?: 'Community', id: string, createdAt: any, updatedAt: any, name: string, description?: string | null, logoUrl?: string | null, author: { __typename?: 'User', id: string, email: string, userProfile?: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string } | null }, admins?: Array<{ __typename?: 'User', id: string }> | null, communityAdmins?: Array<{ __typename?: 'CommunityAdmin', id: string }> | null, members?: Array<{ __typename?: 'User', id: string }> | null, programs?: Array<{ __typename?: 'Program', id: string }> | null } | null };

export type MyCommunitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCommunitiesQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, joinedCommunities?: Array<{ __typename?: 'Community', id: string, name: string }> | null, authoredCommunities?: Array<{ __typename?: 'Community', id: string, name: string, description?: string | null }> | null } | null };

export type CommunityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CommunityQuery = { __typename?: 'Query', community?: { __typename?: 'Community', id: string, name: string, description?: string | null, members?: Array<{ __typename?: 'User', id: string, userProfile?: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string } | null }> | null, programs?: Array<{ __typename?: 'Program', id: string, title: string, publicationStatus: ProgramPublicationStatus }> | null } | null };

export type UpdateCommunityMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type UpdateCommunityMutation = { __typename?: 'Mutation', updateCommunity?: { __typename?: 'Community', id: string, createdAt: any, updatedAt: any, name: string, description?: string | null, author: { __typename?: 'User', id: string, email: string, userProfile?: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string } | null } } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, createdAt: any, updatedAt: any, email: string, userProfile?: { __typename?: 'UserProfile', id: string, createdAt: any, updatedAt: any, avatarUrl?: string | null, firstName: string, lastName: string, phoneNumber: string } | null } | null };

export type CreateProgramOwnerInvitationMutationVariables = Exact<{
  programId: Scalars['ID'];
  email: Scalars['String'];
}>;


export type CreateProgramOwnerInvitationMutation = { __typename?: 'Mutation', createPropertyInvitation?: { __typename?: 'PropertyInvitation', id: string, program: { __typename?: 'Program', id: string }, user: { __typename?: 'User', id: string, email: string, userProfile?: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string } | null } } | null };

export type CreateProgramParticipantInvitationMutationVariables = Exact<{
  programId: Scalars['ID'];
  email: Scalars['String'];
}>;


export type CreateProgramParticipantInvitationMutation = { __typename?: 'Mutation', createPropertyInvitation?: { __typename?: 'PropertyInvitation', id: string, program: { __typename?: 'Program', id: string }, user: { __typename?: 'User', id: string, email: string, userProfile?: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string } | null } } | null };

export type CreateProgramMutationVariables = Exact<{
  communityId: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
}>;


export type CreateProgramMutation = { __typename?: 'Mutation', createProgram?: { __typename?: 'Program', id: string, createdAt: any, updatedAt: any, title: string, description?: string | null, author: { __typename?: 'User', id: string, email: string, userProfile?: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string } | null } } | null };

export type ProgramQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProgramQuery = { __typename?: 'Query', program?: { __typename?: 'Program', id: string, title: string, description?: string | null, publicationStatus: ProgramPublicationStatus, author: { __typename?: 'User', id: string, userProfile?: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string } | null }, owners?: Array<{ __typename?: 'User', id: string, userProfile?: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string } | null }> | null, propertyInvitations?: Array<{ __typename?: 'PropertyInvitation', id: string, status: PropertyInvitationStatus, user: { __typename?: 'User', id: string, email: string, userProfile?: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string } | null } }> | null, participants?: Array<{ __typename?: 'User', id: string, userProfile?: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string } | null }> | null, community: { __typename?: 'Community', id: string, name: string } } | null };

export type DeleteProgramMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteProgramMutation = { __typename?: 'Mutation', deleteProgram?: { __typename?: 'Program', id: string } | null };

export type PublishProgramMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PublishProgramMutation = { __typename?: 'Mutation', publishProgram?: { __typename?: 'Program', id: string } | null };

export type UpdateProgramMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  visibilityStatus?: InputMaybe<ProgramVisibilityStatus>;
}>;


export type UpdateProgramMutation = { __typename?: 'Mutation', updateProgram?: { __typename?: 'Program', id: string, title: string, description?: string | null, visibilityStatus: ProgramVisibilityStatus, createdAt: any, updatedAt: any, author: { __typename?: 'User', id: string }, community: { __typename?: 'Community', id: string } } | null };

export type AutoLoginQueryVariables = Exact<{ [key: string]: never; }>;


export type AutoLoginQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, createdAt: any, updatedAt: any, email: string, authoredCommunities?: Array<{ __typename?: 'Community', id: string, name: string, description?: string | null }> | null, userProfile?: { __typename?: 'UserProfile', id: string, createdAt: any, updatedAt: any, avatarUrl?: string | null, firstName: string, lastName: string, phoneNumber: string } | null } | null };

export type AskPasswordUserMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type AskPasswordUserMutation = { __typename?: 'Mutation', askPasswordUser?: { __typename?: 'User', id: string, email: string } | null };

export type CheckPasswordTokenMutationVariables = Exact<{
  email: Scalars['String'];
  passwordToken: Scalars['String'];
}>;


export type CheckPasswordTokenMutation = { __typename?: 'Mutation', checkPasswordToken?: { __typename?: 'User', id: string, email: string } | null };

export type UpdateUserPasswordMutationVariables = Exact<{
  passwordToken: Scalars['String'];
  password: Scalars['String'];
}>;


export type UpdateUserPasswordMutation = { __typename?: 'Mutation', updateUserPassword?: { __typename?: 'User', id: string } | null };

export type CreateUserProfileMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  userId: Scalars['ID'];
}>;


export type CreateUserProfileMutation = { __typename?: 'Mutation', createUserProfile?: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string, phoneNumber: string, avatarUrl?: string | null } | null };

export type LoginUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser?: { __typename?: 'User', id: string, createdAt: any, updatedAt: any, email: string, userProfile?: { __typename?: 'UserProfile', id: string, createdAt: any, updatedAt: any, avatarUrl?: string | null, firstName: string, lastName: string, phoneNumber: string } | null } | null };

export type AskUserRegistrationMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type AskUserRegistrationMutation = { __typename?: 'Mutation', askUserRegistration?: { __typename?: 'User', id: string, email: string } | null };

export type AcceptConsentMutationVariables = Exact<{
  consentId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type AcceptConsentMutation = { __typename?: 'Mutation', acceptConsent?: { __typename?: 'UserConsent', user: { __typename?: 'User', id: string } } | null };

export type PublishedConsentQueryVariables = Exact<{ [key: string]: never; }>;


export type PublishedConsentQuery = { __typename?: 'Query', publishedConsent?: { __typename?: 'Consent', id: string, content: string } | null };

export type RegisterUserMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RegisterUserMutation = { __typename?: 'Mutation', registerUser?: { __typename?: 'User', id: string, email: string } | null };


export const LogoutUserDocument = gql`
    mutation logoutUser($id: ID!, $client: String) {
  logoutUser(input: {id: $id, client: $client}) {
    id
  }
}
    `;
export type LogoutUserMutationFn = Apollo.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      client: // value for 'client'
 *   },
 * });
 */
export function useLogoutUserMutation(baseOptions?: Apollo.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserDocument, options);
      }
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = Apollo.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<LogoutUserMutation, LogoutUserMutationVariables>;
export const CreateCommunityMemberInvitationDocument = gql`
    mutation createCommunityMemberInvitation($communityId: ID!, $email: String!) {
  createCommunityMemberInvitation(
    input: {communityId: $communityId, email: $email}
  ) {
    id
    status
    user {
      id
      email
      userProfile {
        id
        firstName
        lastName
      }
    }
    author {
      id
    }
    community {
      id
    }
  }
}
    `;
export type CreateCommunityMemberInvitationMutationFn = Apollo.MutationFunction<CreateCommunityMemberInvitationMutation, CreateCommunityMemberInvitationMutationVariables>;

/**
 * __useCreateCommunityMemberInvitationMutation__
 *
 * To run a mutation, you first call `useCreateCommunityMemberInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityMemberInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityMemberInvitationMutation, { data, loading, error }] = useCreateCommunityMemberInvitationMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateCommunityMemberInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommunityMemberInvitationMutation, CreateCommunityMemberInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommunityMemberInvitationMutation, CreateCommunityMemberInvitationMutationVariables>(CreateCommunityMemberInvitationDocument, options);
      }
export type CreateCommunityMemberInvitationMutationHookResult = ReturnType<typeof useCreateCommunityMemberInvitationMutation>;
export type CreateCommunityMemberInvitationMutationResult = Apollo.MutationResult<CreateCommunityMemberInvitationMutation>;
export type CreateCommunityMemberInvitationMutationOptions = Apollo.BaseMutationOptions<CreateCommunityMemberInvitationMutation, CreateCommunityMemberInvitationMutationVariables>;
export const CommunityMembersListDocument = gql`
    query communityMembersList($id: ID!) {
  community(id: $id) {
    id
    name
    description
    members {
      id
      userProfile {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useCommunityMembersListQuery__
 *
 * To run a query within a React component, call `useCommunityMembersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityMembersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityMembersListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommunityMembersListQuery(baseOptions: Apollo.QueryHookOptions<CommunityMembersListQuery, CommunityMembersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunityMembersListQuery, CommunityMembersListQueryVariables>(CommunityMembersListDocument, options);
      }
export function useCommunityMembersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityMembersListQuery, CommunityMembersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunityMembersListQuery, CommunityMembersListQueryVariables>(CommunityMembersListDocument, options);
        }
export type CommunityMembersListQueryHookResult = ReturnType<typeof useCommunityMembersListQuery>;
export type CommunityMembersListLazyQueryHookResult = ReturnType<typeof useCommunityMembersListLazyQuery>;
export type CommunityMembersListQueryResult = Apollo.QueryResult<CommunityMembersListQuery, CommunityMembersListQueryVariables>;
export const CommunityProgramsListDocument = gql`
    query communityProgramsList($id: ID!) {
  community(id: $id) {
    id
    programs {
      id
      title
      publicationStatus
    }
  }
}
    `;

/**
 * __useCommunityProgramsListQuery__
 *
 * To run a query within a React component, call `useCommunityProgramsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityProgramsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityProgramsListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommunityProgramsListQuery(baseOptions: Apollo.QueryHookOptions<CommunityProgramsListQuery, CommunityProgramsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunityProgramsListQuery, CommunityProgramsListQueryVariables>(CommunityProgramsListDocument, options);
      }
export function useCommunityProgramsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityProgramsListQuery, CommunityProgramsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunityProgramsListQuery, CommunityProgramsListQueryVariables>(CommunityProgramsListDocument, options);
        }
export type CommunityProgramsListQueryHookResult = ReturnType<typeof useCommunityProgramsListQuery>;
export type CommunityProgramsListLazyQueryHookResult = ReturnType<typeof useCommunityProgramsListLazyQuery>;
export type CommunityProgramsListQueryResult = Apollo.QueryResult<CommunityProgramsListQuery, CommunityProgramsListQueryVariables>;
export const CreateCommunityDocument = gql`
    mutation createCommunity($name: String!, $description: String!, $logo: Upload) {
  createCommunity(input: {name: $name, description: $description, logo: $logo}) {
    id
    createdAt
    updatedAt
    name
    description
    logoUrl
    author {
      id
      email
      userProfile {
        id
        firstName
        lastName
      }
    }
    admins {
      id
    }
    communityAdmins {
      id
    }
    members {
      id
    }
    programs {
      id
    }
  }
}
    `;
export type CreateCommunityMutationFn = Apollo.MutationFunction<CreateCommunityMutation, CreateCommunityMutationVariables>;

/**
 * __useCreateCommunityMutation__
 *
 * To run a mutation, you first call `useCreateCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityMutation, { data, loading, error }] = useCreateCommunityMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      logo: // value for 'logo'
 *   },
 * });
 */
export function useCreateCommunityMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommunityMutation, CreateCommunityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommunityMutation, CreateCommunityMutationVariables>(CreateCommunityDocument, options);
      }
export type CreateCommunityMutationHookResult = ReturnType<typeof useCreateCommunityMutation>;
export type CreateCommunityMutationResult = Apollo.MutationResult<CreateCommunityMutation>;
export type CreateCommunityMutationOptions = Apollo.BaseMutationOptions<CreateCommunityMutation, CreateCommunityMutationVariables>;
export const MyCommunitiesDocument = gql`
    query myCommunities {
  me {
    id
    joinedCommunities {
      id
      name
    }
    authoredCommunities {
      id
      name
      description
    }
  }
}
    `;

/**
 * __useMyCommunitiesQuery__
 *
 * To run a query within a React component, call `useMyCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCommunitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCommunitiesQuery(baseOptions?: Apollo.QueryHookOptions<MyCommunitiesQuery, MyCommunitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyCommunitiesQuery, MyCommunitiesQueryVariables>(MyCommunitiesDocument, options);
      }
export function useMyCommunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyCommunitiesQuery, MyCommunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyCommunitiesQuery, MyCommunitiesQueryVariables>(MyCommunitiesDocument, options);
        }
export type MyCommunitiesQueryHookResult = ReturnType<typeof useMyCommunitiesQuery>;
export type MyCommunitiesLazyQueryHookResult = ReturnType<typeof useMyCommunitiesLazyQuery>;
export type MyCommunitiesQueryResult = Apollo.QueryResult<MyCommunitiesQuery, MyCommunitiesQueryVariables>;
export const CommunityDocument = gql`
    query community($id: ID!) {
  community(id: $id) {
    id
    name
    description
    members {
      id
      userProfile {
        id
        firstName
        lastName
      }
    }
    programs {
      id
      title
      publicationStatus
    }
  }
}
    `;

/**
 * __useCommunityQuery__
 *
 * To run a query within a React component, call `useCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommunityQuery(baseOptions: Apollo.QueryHookOptions<CommunityQuery, CommunityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunityQuery, CommunityQueryVariables>(CommunityDocument, options);
      }
export function useCommunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityQuery, CommunityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunityQuery, CommunityQueryVariables>(CommunityDocument, options);
        }
export type CommunityQueryHookResult = ReturnType<typeof useCommunityQuery>;
export type CommunityLazyQueryHookResult = ReturnType<typeof useCommunityLazyQuery>;
export type CommunityQueryResult = Apollo.QueryResult<CommunityQuery, CommunityQueryVariables>;
export const UpdateCommunityDocument = gql`
    mutation updateCommunity($id: ID!, $name: String!, $description: String!) {
  updateCommunity(id: $id, input: {name: $name, description: $description}) {
    id
    createdAt
    updatedAt
    name
    description
    author {
      id
      email
      userProfile {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type UpdateCommunityMutationFn = Apollo.MutationFunction<UpdateCommunityMutation, UpdateCommunityMutationVariables>;

/**
 * __useUpdateCommunityMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityMutation, { data, loading, error }] = useUpdateCommunityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateCommunityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunityMutation, UpdateCommunityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunityMutation, UpdateCommunityMutationVariables>(UpdateCommunityDocument, options);
      }
export type UpdateCommunityMutationHookResult = ReturnType<typeof useUpdateCommunityMutation>;
export type UpdateCommunityMutationResult = Apollo.MutationResult<UpdateCommunityMutation>;
export type UpdateCommunityMutationOptions = Apollo.BaseMutationOptions<UpdateCommunityMutation, UpdateCommunityMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    createdAt
    updatedAt
    email
    userProfile {
      id
      createdAt
      updatedAt
      avatarUrl
      firstName
      lastName
      phoneNumber
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CreateProgramOwnerInvitationDocument = gql`
    mutation createProgramOwnerInvitation($programId: ID!, $email: String!) {
  createPropertyInvitation(input: {programId: $programId, email: $email}) {
    id
    program {
      id
    }
    user {
      id
      email
      userProfile {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type CreateProgramOwnerInvitationMutationFn = Apollo.MutationFunction<CreateProgramOwnerInvitationMutation, CreateProgramOwnerInvitationMutationVariables>;

/**
 * __useCreateProgramOwnerInvitationMutation__
 *
 * To run a mutation, you first call `useCreateProgramOwnerInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramOwnerInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramOwnerInvitationMutation, { data, loading, error }] = useCreateProgramOwnerInvitationMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateProgramOwnerInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CreateProgramOwnerInvitationMutation, CreateProgramOwnerInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProgramOwnerInvitationMutation, CreateProgramOwnerInvitationMutationVariables>(CreateProgramOwnerInvitationDocument, options);
      }
export type CreateProgramOwnerInvitationMutationHookResult = ReturnType<typeof useCreateProgramOwnerInvitationMutation>;
export type CreateProgramOwnerInvitationMutationResult = Apollo.MutationResult<CreateProgramOwnerInvitationMutation>;
export type CreateProgramOwnerInvitationMutationOptions = Apollo.BaseMutationOptions<CreateProgramOwnerInvitationMutation, CreateProgramOwnerInvitationMutationVariables>;
export const CreateProgramParticipantInvitationDocument = gql`
    mutation createProgramParticipantInvitation($programId: ID!, $email: String!) {
  createPropertyInvitation(input: {programId: $programId, email: $email}) {
    id
    program {
      id
    }
    user {
      id
      email
      userProfile {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type CreateProgramParticipantInvitationMutationFn = Apollo.MutationFunction<CreateProgramParticipantInvitationMutation, CreateProgramParticipantInvitationMutationVariables>;

/**
 * __useCreateProgramParticipantInvitationMutation__
 *
 * To run a mutation, you first call `useCreateProgramParticipantInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramParticipantInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramParticipantInvitationMutation, { data, loading, error }] = useCreateProgramParticipantInvitationMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateProgramParticipantInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CreateProgramParticipantInvitationMutation, CreateProgramParticipantInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProgramParticipantInvitationMutation, CreateProgramParticipantInvitationMutationVariables>(CreateProgramParticipantInvitationDocument, options);
      }
export type CreateProgramParticipantInvitationMutationHookResult = ReturnType<typeof useCreateProgramParticipantInvitationMutation>;
export type CreateProgramParticipantInvitationMutationResult = Apollo.MutationResult<CreateProgramParticipantInvitationMutation>;
export type CreateProgramParticipantInvitationMutationOptions = Apollo.BaseMutationOptions<CreateProgramParticipantInvitationMutation, CreateProgramParticipantInvitationMutationVariables>;
export const CreateProgramDocument = gql`
    mutation createProgram($communityId: ID!, $title: String!, $description: String!) {
  createProgram(
    input: {communityId: $communityId, title: $title, description: $description}
  ) {
    id
    createdAt
    updatedAt
    title
    description
    author {
      id
      email
      userProfile {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type CreateProgramMutationFn = Apollo.MutationFunction<CreateProgramMutation, CreateProgramMutationVariables>;

/**
 * __useCreateProgramMutation__
 *
 * To run a mutation, you first call `useCreateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramMutation, { data, loading, error }] = useCreateProgramMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateProgramMutation(baseOptions?: Apollo.MutationHookOptions<CreateProgramMutation, CreateProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProgramMutation, CreateProgramMutationVariables>(CreateProgramDocument, options);
      }
export type CreateProgramMutationHookResult = ReturnType<typeof useCreateProgramMutation>;
export type CreateProgramMutationResult = Apollo.MutationResult<CreateProgramMutation>;
export type CreateProgramMutationOptions = Apollo.BaseMutationOptions<CreateProgramMutation, CreateProgramMutationVariables>;
export const ProgramDocument = gql`
    query program($id: ID!) {
  program(id: $id) {
    id
    title
    author {
      id
      userProfile {
        id
        firstName
        lastName
      }
    }
    description
    publicationStatus
    owners {
      id
      userProfile {
        id
        firstName
        lastName
      }
    }
    propertyInvitations {
      id
      status
      user {
        id
        email
        userProfile {
          id
          firstName
          lastName
        }
      }
    }
    participants {
      id
      userProfile {
        id
        firstName
        lastName
      }
    }
    community {
      id
      name
    }
  }
}
    `;

/**
 * __useProgramQuery__
 *
 * To run a query within a React component, call `useProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProgramQuery(baseOptions: Apollo.QueryHookOptions<ProgramQuery, ProgramQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProgramQuery, ProgramQueryVariables>(ProgramDocument, options);
      }
export function useProgramLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgramQuery, ProgramQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProgramQuery, ProgramQueryVariables>(ProgramDocument, options);
        }
export type ProgramQueryHookResult = ReturnType<typeof useProgramQuery>;
export type ProgramLazyQueryHookResult = ReturnType<typeof useProgramLazyQuery>;
export type ProgramQueryResult = Apollo.QueryResult<ProgramQuery, ProgramQueryVariables>;
export const DeleteProgramDocument = gql`
    mutation deleteProgram($id: ID!) {
  deleteProgram(id: $id) {
    id
  }
}
    `;
export type DeleteProgramMutationFn = Apollo.MutationFunction<DeleteProgramMutation, DeleteProgramMutationVariables>;

/**
 * __useDeleteProgramMutation__
 *
 * To run a mutation, you first call `useDeleteProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProgramMutation, { data, loading, error }] = useDeleteProgramMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProgramMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProgramMutation, DeleteProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProgramMutation, DeleteProgramMutationVariables>(DeleteProgramDocument, options);
      }
export type DeleteProgramMutationHookResult = ReturnType<typeof useDeleteProgramMutation>;
export type DeleteProgramMutationResult = Apollo.MutationResult<DeleteProgramMutation>;
export type DeleteProgramMutationOptions = Apollo.BaseMutationOptions<DeleteProgramMutation, DeleteProgramMutationVariables>;
export const PublishProgramDocument = gql`
    mutation publishProgram($id: ID!) {
  publishProgram(id: $id) {
    id
  }
}
    `;
export type PublishProgramMutationFn = Apollo.MutationFunction<PublishProgramMutation, PublishProgramMutationVariables>;

/**
 * __usePublishProgramMutation__
 *
 * To run a mutation, you first call `usePublishProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishProgramMutation, { data, loading, error }] = usePublishProgramMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishProgramMutation(baseOptions?: Apollo.MutationHookOptions<PublishProgramMutation, PublishProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishProgramMutation, PublishProgramMutationVariables>(PublishProgramDocument, options);
      }
export type PublishProgramMutationHookResult = ReturnType<typeof usePublishProgramMutation>;
export type PublishProgramMutationResult = Apollo.MutationResult<PublishProgramMutation>;
export type PublishProgramMutationOptions = Apollo.BaseMutationOptions<PublishProgramMutation, PublishProgramMutationVariables>;
export const UpdateProgramDocument = gql`
    mutation updateProgram($id: ID!, $title: String, $description: String, $visibilityStatus: ProgramVisibilityStatus) {
  updateProgram(
    id: $id
    input: {title: $title, description: $description, visibilityStatus: $visibilityStatus}
  ) {
    id
    title
    description
    visibilityStatus
    createdAt
    updatedAt
    author {
      id
    }
    community {
      id
    }
  }
}
    `;
export type UpdateProgramMutationFn = Apollo.MutationFunction<UpdateProgramMutation, UpdateProgramMutationVariables>;

/**
 * __useUpdateProgramMutation__
 *
 * To run a mutation, you first call `useUpdateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramMutation, { data, loading, error }] = useUpdateProgramMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      visibilityStatus: // value for 'visibilityStatus'
 *   },
 * });
 */
export function useUpdateProgramMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProgramMutation, UpdateProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProgramMutation, UpdateProgramMutationVariables>(UpdateProgramDocument, options);
      }
export type UpdateProgramMutationHookResult = ReturnType<typeof useUpdateProgramMutation>;
export type UpdateProgramMutationResult = Apollo.MutationResult<UpdateProgramMutation>;
export type UpdateProgramMutationOptions = Apollo.BaseMutationOptions<UpdateProgramMutation, UpdateProgramMutationVariables>;
export const AutoLoginDocument = gql`
    query autoLogin {
  me {
    id
    createdAt
    updatedAt
    email
    authoredCommunities {
      id
      name
      description
    }
    userProfile {
      id
      createdAt
      updatedAt
      avatarUrl
      firstName
      lastName
      phoneNumber
    }
  }
}
    `;

/**
 * __useAutoLoginQuery__
 *
 * To run a query within a React component, call `useAutoLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutoLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutoLoginQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutoLoginQuery(baseOptions?: Apollo.QueryHookOptions<AutoLoginQuery, AutoLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutoLoginQuery, AutoLoginQueryVariables>(AutoLoginDocument, options);
      }
export function useAutoLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutoLoginQuery, AutoLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutoLoginQuery, AutoLoginQueryVariables>(AutoLoginDocument, options);
        }
export type AutoLoginQueryHookResult = ReturnType<typeof useAutoLoginQuery>;
export type AutoLoginLazyQueryHookResult = ReturnType<typeof useAutoLoginLazyQuery>;
export type AutoLoginQueryResult = Apollo.QueryResult<AutoLoginQuery, AutoLoginQueryVariables>;
export const AskPasswordUserDocument = gql`
    mutation askPasswordUser($email: String!) {
  askPasswordUser(input: {email: $email}) {
    id
    email
  }
}
    `;
export type AskPasswordUserMutationFn = Apollo.MutationFunction<AskPasswordUserMutation, AskPasswordUserMutationVariables>;

/**
 * __useAskPasswordUserMutation__
 *
 * To run a mutation, you first call `useAskPasswordUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAskPasswordUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [askPasswordUserMutation, { data, loading, error }] = useAskPasswordUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAskPasswordUserMutation(baseOptions?: Apollo.MutationHookOptions<AskPasswordUserMutation, AskPasswordUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AskPasswordUserMutation, AskPasswordUserMutationVariables>(AskPasswordUserDocument, options);
      }
export type AskPasswordUserMutationHookResult = ReturnType<typeof useAskPasswordUserMutation>;
export type AskPasswordUserMutationResult = Apollo.MutationResult<AskPasswordUserMutation>;
export type AskPasswordUserMutationOptions = Apollo.BaseMutationOptions<AskPasswordUserMutation, AskPasswordUserMutationVariables>;
export const CheckPasswordTokenDocument = gql`
    mutation checkPasswordToken($email: String!, $passwordToken: String!) {
  checkPasswordToken(input: {email: $email, passwordToken: $passwordToken}) {
    id
    email
  }
}
    `;
export type CheckPasswordTokenMutationFn = Apollo.MutationFunction<CheckPasswordTokenMutation, CheckPasswordTokenMutationVariables>;

/**
 * __useCheckPasswordTokenMutation__
 *
 * To run a mutation, you first call `useCheckPasswordTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckPasswordTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkPasswordTokenMutation, { data, loading, error }] = useCheckPasswordTokenMutation({
 *   variables: {
 *      email: // value for 'email'
 *      passwordToken: // value for 'passwordToken'
 *   },
 * });
 */
export function useCheckPasswordTokenMutation(baseOptions?: Apollo.MutationHookOptions<CheckPasswordTokenMutation, CheckPasswordTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckPasswordTokenMutation, CheckPasswordTokenMutationVariables>(CheckPasswordTokenDocument, options);
      }
export type CheckPasswordTokenMutationHookResult = ReturnType<typeof useCheckPasswordTokenMutation>;
export type CheckPasswordTokenMutationResult = Apollo.MutationResult<CheckPasswordTokenMutation>;
export type CheckPasswordTokenMutationOptions = Apollo.BaseMutationOptions<CheckPasswordTokenMutation, CheckPasswordTokenMutationVariables>;
export const UpdateUserPasswordDocument = gql`
    mutation updateUserPassword($passwordToken: String!, $password: String!) {
  updateUserPassword(input: {password: $password, passwordToken: $passwordToken}) {
    id
  }
}
    `;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      passwordToken: // value for 'passwordToken'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(UpdateUserPasswordDocument, options);
      }
export type UpdateUserPasswordMutationHookResult = ReturnType<typeof useUpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationResult = Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;
export const CreateUserProfileDocument = gql`
    mutation createUserProfile($firstName: String!, $lastName: String!, $phoneNumber: String!, $userId: ID!) {
  createUserProfile(
    input: {firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, userId: $userId}
  ) {
    id
    firstName
    lastName
    phoneNumber
    avatarUrl
  }
}
    `;
export type CreateUserProfileMutationFn = Apollo.MutationFunction<CreateUserProfileMutation, CreateUserProfileMutationVariables>;

/**
 * __useCreateUserProfileMutation__
 *
 * To run a mutation, you first call `useCreateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserProfileMutation, { data, loading, error }] = useCreateUserProfileMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserProfileMutation, CreateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserProfileMutation, CreateUserProfileMutationVariables>(CreateUserProfileDocument, options);
      }
export type CreateUserProfileMutationHookResult = ReturnType<typeof useCreateUserProfileMutation>;
export type CreateUserProfileMutationResult = Apollo.MutationResult<CreateUserProfileMutation>;
export type CreateUserProfileMutationOptions = Apollo.BaseMutationOptions<CreateUserProfileMutation, CreateUserProfileMutationVariables>;
export const LoginUserDocument = gql`
    mutation loginUser($email: String!, $password: String!) {
  loginUser(input: {email: $email, password: $password}) {
    id
    createdAt
    updatedAt
    email
    userProfile {
      id
      createdAt
      updatedAt
      avatarUrl
      firstName
      lastName
      phoneNumber
    }
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const AskUserRegistrationDocument = gql`
    mutation askUserRegistration($email: String!) {
  askUserRegistration(input: {email: $email}) {
    id
    email
  }
}
    `;
export type AskUserRegistrationMutationFn = Apollo.MutationFunction<AskUserRegistrationMutation, AskUserRegistrationMutationVariables>;

/**
 * __useAskUserRegistrationMutation__
 *
 * To run a mutation, you first call `useAskUserRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAskUserRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [askUserRegistrationMutation, { data, loading, error }] = useAskUserRegistrationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAskUserRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<AskUserRegistrationMutation, AskUserRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AskUserRegistrationMutation, AskUserRegistrationMutationVariables>(AskUserRegistrationDocument, options);
      }
export type AskUserRegistrationMutationHookResult = ReturnType<typeof useAskUserRegistrationMutation>;
export type AskUserRegistrationMutationResult = Apollo.MutationResult<AskUserRegistrationMutation>;
export type AskUserRegistrationMutationOptions = Apollo.BaseMutationOptions<AskUserRegistrationMutation, AskUserRegistrationMutationVariables>;
export const AcceptConsentDocument = gql`
    mutation acceptConsent($consentId: ID!, $userId: ID!) {
  acceptConsent(input: {consentId: $consentId, userId: $userId}) {
    user {
      id
    }
  }
}
    `;
export type AcceptConsentMutationFn = Apollo.MutationFunction<AcceptConsentMutation, AcceptConsentMutationVariables>;

/**
 * __useAcceptConsentMutation__
 *
 * To run a mutation, you first call `useAcceptConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptConsentMutation, { data, loading, error }] = useAcceptConsentMutation({
 *   variables: {
 *      consentId: // value for 'consentId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAcceptConsentMutation(baseOptions?: Apollo.MutationHookOptions<AcceptConsentMutation, AcceptConsentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptConsentMutation, AcceptConsentMutationVariables>(AcceptConsentDocument, options);
      }
export type AcceptConsentMutationHookResult = ReturnType<typeof useAcceptConsentMutation>;
export type AcceptConsentMutationResult = Apollo.MutationResult<AcceptConsentMutation>;
export type AcceptConsentMutationOptions = Apollo.BaseMutationOptions<AcceptConsentMutation, AcceptConsentMutationVariables>;
export const PublishedConsentDocument = gql`
    query publishedConsent {
  publishedConsent {
    id
    content
  }
}
    `;

/**
 * __usePublishedConsentQuery__
 *
 * To run a query within a React component, call `usePublishedConsentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedConsentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedConsentQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublishedConsentQuery(baseOptions?: Apollo.QueryHookOptions<PublishedConsentQuery, PublishedConsentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublishedConsentQuery, PublishedConsentQueryVariables>(PublishedConsentDocument, options);
      }
export function usePublishedConsentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublishedConsentQuery, PublishedConsentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublishedConsentQuery, PublishedConsentQueryVariables>(PublishedConsentDocument, options);
        }
export type PublishedConsentQueryHookResult = ReturnType<typeof usePublishedConsentQuery>;
export type PublishedConsentLazyQueryHookResult = ReturnType<typeof usePublishedConsentLazyQuery>;
export type PublishedConsentQueryResult = Apollo.QueryResult<PublishedConsentQuery, PublishedConsentQueryVariables>;
export const RegisterUserDocument = gql`
    mutation registerUser($email: String!) {
  registerUser(input: {email: $email}) {
    id
    email
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;