import React from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';
import ActionSheet from 'shared/components/action-sheet/ActionSheet';
import { ProgramDeleteModal } from './program-delete-modal/ProgramDeleteModal';
import useProgramActions from './useProgramActions';
import translations from './translations';

export default function ProgramActions() {
  const intl = useIntl();
  const {
    actionsSheetOptions,
    isDeleteModalVisible,
    setIsDeleteModalVisible,
  } = useProgramActions();
  return (
    <View>
      <ActionSheet
        title={intl.formatMessage(translations.programActionsTitle)}
        options={
          actionsSheetOptions
        }
      />
      <ProgramDeleteModal
        isDeleteModalVisible={isDeleteModalVisible}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </View>
  );
}
