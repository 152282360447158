import {
  StackActions,
  useNavigation,
} from '@react-navigation/native';
import { useIntl } from 'react-intl';
import Toast from 'react-native-toast-message';
import { useCreateProgramMutation } from 'types/graphql';
import { toastrTranslations } from '../translations';

export default function useCreateProgram() {
  const intl = useIntl();
  const { dispatch } = useNavigation();

  const [createProgramMutation] = useCreateProgramMutation({
    refetchQueries: ['community'],
    onCompleted: () => dispatch(StackActions.pop()),
    onError: () => {
      Toast.show({
        type: 'error',
        text1: intl.formatMessage(toastrTranslations.failure),
      });
    },
  });

  return {
    createProgramMutation,
  };
}
