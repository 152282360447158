import {
  PublishedConsentQuery,
  PublishedConsentQueryVariables,
} from 'types/graphql';
import { MJGGraphQLErrorCode } from 'types/graphql-errors';
import { useQueryWithError } from '../../../../hooks/use-query-with-error/useQueryWithError';
import { PUBLISHED_CONSENT } from '../gql/publishedConsent.gql';

export default function useRegisterScreen() {
  const { loading, data, error } = useQueryWithError<
    PublishedConsentQuery,
    PublishedConsentQueryVariables,
    MJGGraphQLErrorCode
  >(PUBLISHED_CONSENT, {
    fetchPolicy: 'network-only',
  });

  return {
    loading,
    consent: data?.publishedConsent,
    error,
  };
}
