import React, {
  ComponentPropsWithoutRef,
  ReactNode,
} from 'react';
import { View } from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import Text from '../../basic/text/Text';
import AvatarList from '../avatar-list/AvatarList';
import tw from '../../tw';

type UsersPartialListProps = {
  users: ComponentPropsWithoutRef<typeof AvatarList>['users'];
  ListEmptyComponent: ReactNode;
  LinkToListScreen: ReactNode;
  itemsToDisplay?: ComponentPropsWithoutRef<typeof AvatarList>['itemsToDisplay'];
  style?: Style
}
export default function UsersPartialList({
  users,
  ListEmptyComponent,
  LinkToListScreen,
  itemsToDisplay = 3,
  style,
}: UsersPartialListProps) {
  return (
    <View
      style={tw.style(
        'flex-row content-start items-center',
        style,
      )}
    >
      {
        users.length > 0 && (
          <AvatarList
            users={users}
            itemsToDisplay={itemsToDisplay}
          />
        )
      }
      {
        users.length > itemsToDisplay && (
          <Text
            variant="body"
            style={tw.style('ml-1 mr-2')}
          >
            {`+ ${users.length - itemsToDisplay}`}
          </Text>
        )
      }
      {
        users.length > itemsToDisplay
          ? LinkToListScreen
          : ListEmptyComponent
      }
    </View>
  );
}
