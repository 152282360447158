import { defineMessages } from 'react-intl';

export default defineMessages({
  programActionsTitle: {
    id: 'ProgramActions.programActionsTitle',
    defaultMessage: 'Actions sur programme',
  },
  deleteProgramTitle: {
    id: 'ProgramActions.deleteProgramTitle',
    defaultMessage: 'Supprimer le programme',
  },
  deleteProgramModalTitle: {
    id: 'ProgramActions.deleteProgramModalTitle',
    defaultMessage: 'Etes-vous sûr de vouloir supprimer ce programme ?',
  },
  deleteProgramModalText: {
    id: 'ProgramActions.deleteProgramModalText',
    defaultMessage: 'Cette action est irréversible.',
  },
  deleteProgramModalSubmitButton: {
    id: 'ProgramActions.deleteProgramModalSubmitButton',
    defaultMessage: 'Supprimer',
  },
  deleteProgramModalCancelButton: {
    id: 'ProgramActions.deleteProgramModalCancelButton',
    defaultMessage: 'Annuler',
  },
  deleteProgramSuccess: {
    id: 'ProgramActions.deleteProgramSuccess',
    defaultMessage: 'Le programme a été supprimé avec succès',
  },
  deleteProgramError: {
    id: 'ProgramActions.deleteProgramError',
    defaultMessage: 'Une erreur est survenue lors de la suppression du programme',
  },
});
