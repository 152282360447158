import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

export enum MJGGraphQLErrorCode {
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export interface MJGValidationError<MJGErrors> extends GraphQLError {
  extensions: {
    code: MJGGraphQLErrorCode,
    validationErrors: {
      [key: string]: Array<MJGErrors>
    }
  }
}
export interface MJGApolloError<MJGErrors> extends ApolloError {
  graphQLErrors: Array<MJGValidationError<MJGErrors>>
}

export interface ErrorMapping<
  FormikField,
  ERRORS extends string
  > {
  translations: {
    [fieldName in keyof FormikField | 'global']?: {
      [errorCode in ERRORS | MJGGraphQLErrorCode]?: string; // string is the error translated
    };
  };
}

export enum LoginUserErrors {
  NOT_FOUND = 'NOT_FOUND',
  USER_PASSWORD_WRONG_PASSWORD = 'USER_PASSWORD_WRONG_PASSWORD',
  USER_EMAIL_LOCKED = 'USER_EMAIL_LOCKED'
}

export enum RegisterUserErrors {
  USER_EMAIL_BLANK = 'USER_EMAIL_BLANK',
  USER_EMAIL_INVALID = 'USER_EMAIL_INVALID',
  USER_EMAIL_TAKEN = 'USER_EMAIL_TAKEN',
}

export enum AskPasswordUserErrors {
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  USER_EMAIL_BLANK = 'USER_EMAIL_BLANK',
  USER_EMAIL_INVALID = 'USER_EMAIL_INVALID',
}

export enum UpdateUserPasswordErrors {
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  USER_PASSWORD_BLANK = 'USER_PASSWORD_BLANK',
  USER_PASSWORD_NOT_STRONG_ENOUGH = 'USER_PASSWORD_NOT_STRONG_ENOUGH',
  USER_PASSWORD_TOKEN_INVALID = 'USER_PASSWORD_TOKEN_INVALID',
  USER_PASSWORD_TOKEN_NOT_GENERATED = 'USER_PASSWORD_TOKEN_NOT_GENERATED',
  USER_PASSWORD_TOKEN_EXPIRED = 'USER_PASSWORD_TOKEN_EXPIRED',
}

export enum CreateUserProfileErrors {
  USER_PROFILE_FIRST_NAME_BLANK = 'USER_PROFILE_FIRST_NAME_BLANK',
  USER_PROFILE_LAST_NAME_BLANK = 'USER_PROFILE_LAST_NAME_BLANK',
  USER_PROFILE_PHONE_NUMBER_TAKEN = 'USER_PROFILE_PHONE_NUMBER_TAKEN',
  USER_PROFILE_PHONE_NUMBER_INVALID = 'USER_PROFILE_PHONE_NUMBER_INVALID',
  USER_PROFILE_USER_BLANK = 'USER_PROFILE_USER_BLANK',
  USER_PROFILE_USER_ALREADY_HAVE_A_USER_PROFILE = 'USER_PROFILE_USER_ALREADY_HAVE_A_USER_PROFILE',
}

export enum CheckRegistrationToken {
  USER_PASSWORD_TOKEN_INVALID = 'USER_PASSWORD_TOKEN_INVALID',
  USER_PASSWORD_TOKEN_NOT_GENERATED = 'USER_PASSWORD_TOKEN_NOT_GENERATED',
  USER_PASSWORD_TOKEN_EXPIRED = 'USER_PASSWORD_TOKEN_EXPIRED',
}
