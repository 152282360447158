import { useRoute } from '@react-navigation/native';
import React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import { RouteProps } from 'types/navigation';
import tw, {
  Button,
  InputForm,
} from 'design-system';
import Screens from '../../../../screens';
import useCreateProgram from './useCreateProgram';
import translations from '../translations';
import validationSchema from './CreateProgramForm.validation';

type CreateProgramFormikFields = {
  communityId: string;
  title: string;
  description: string;
}

export default function CreateProgramForm() {
  const intl = useIntl();
  const { params: { id } } = useRoute<RouteProps<Screens.createProgram>>();
  const initialValues = {
    communityId: id,
    title: '',
    description: '',
  };
  const { createProgramMutation } = useCreateProgram();

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange
      validateOnBlur
      validateOnMount
      validationSchema={validationSchema(intl)}
      onSubmit={(
        values: CreateProgramFormikFields,
      ) => createProgramMutation({ variables: values })}
    >
      {({
        handleSubmit,
      }) => (
        <>
          <InputForm
            fieldName="title"
            label={intl.formatMessage(translations.formLabelTitle)}
            style={tw.style('mb-4')}
          />
          <InputForm
            fieldName="description"
            label={intl.formatMessage(translations.formLabelDescription)}
            style={tw.style('mb-4')}
          />
          <Button
            variant="full"
            onPress={() => handleSubmit()}
            style={tw.style('self-center')}
          >
            {intl.formatMessage(translations.formButtonSubmit).toUpperCase()}
          </Button>
        </>
      )}
    </Formik>
  );
}
