import {
  useQuery,
  ApolloError,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client';
import {
  DocumentNode,
  GraphQLError,
} from 'graphql';

interface MJGGraphQLError<E> extends GraphQLError {
  extensions: {
    code?: E;
  } | undefined;
}

export interface MJGApolloErrorExtended<E> extends ApolloError {
  graphQLErrors: readonly MJGGraphQLError<E>[];
}

interface MJGQueryWithErrorEnhanced<Q, V, E> extends QueryResult<Q, V> {
  error?: MJGApolloErrorExtended<E>;
}

export function useQueryWithError<TData, TVariables, TErrors>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>,
): MJGQueryWithErrorEnhanced<TData, TVariables, TErrors> {
  return useQuery<TData, TVariables>(
    query,
    options,
  );
}
