import React from 'react';
import { useIntl } from 'react-intl';
import { Style } from 'twrnc/dist/esm/types';
import { Button } from 'design-system';
import translations from './translations';
import usePublishProgram from './usePublishProgram';

type PublishProgramButtonProps = {
  id: string;
  style?: Style
}

export default function PublishProgramButton({
  id,
  style,
}: PublishProgramButtonProps) {
  const intl = useIntl();
  const { publishProgramMutation } = usePublishProgram();

  return (
    <Button
      variant="full"
      onPress={() => {
        publishProgramMutation({
          variables: {
            id,
          },
        });
      }}
      style={style}
    >
      {intl.formatMessage(translations.publishButtonText)}
    </Button>
  );
}
