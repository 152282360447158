import { useIntl } from 'react-intl';
import { FormikHelpers } from 'formik';
import { useLoginUserMutation, User } from 'types/graphql';
import { LoginUserErrors } from 'types/graphql-errors';
import { FormikFields } from '../@types';
import { errorTranslations } from '../translations';
import useHandleGraphqlErrors from '../../../../hooks/use-handle-graphql-errors/useHandleGraphqlErrors';

export default function useLoginUser({
  onSuccess,
}: {
  onSuccess: (data: Partial<User>) => void;
}) {
  const intl = useIntl();
  const [loginUserMutation] = useLoginUserMutation();
  const handleGraphqlErrors = useHandleGraphqlErrors();

  const errorsMapping = {
    translations: {
      global: {
        [LoginUserErrors.NOT_FOUND]:
          intl.formatMessage(errorTranslations.NOT_FOUND),
      },
      email: {
        [LoginUserErrors.USER_EMAIL_LOCKED]:
          intl.formatMessage(errorTranslations.USER_EMAIL_LOCKED),
      },
      password: {
        [LoginUserErrors.USER_PASSWORD_WRONG_PASSWORD]:
          intl.formatMessage(errorTranslations.USER_PASSWORD_WRONG_PASSWORD),
      },
    },
  };

  return async function onSubmit(
    values: FormikFields,
    formikBag: FormikHelpers<FormikFields>,
  ): Promise<void> {
    return loginUserMutation({
      variables: values,
    })
      .then(({ data: { loginUser } }) => {
        onSuccess(loginUser);
      })
      .catch((errors) => {
        handleGraphqlErrors<
        FormikFields,
        LoginUserErrors
        >(
          errors,
          formikBag,
          errorsMapping,
        );
      });
  };
}
