import React from 'react';
import {
  View,
} from 'react-native';
import tw from '../../tw';

export default function KeyboardDisplayManager({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <View
      style={tw.style('flex-1')}
    >
      {children}
    </View>
  );
}
