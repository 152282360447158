import React from 'react';
import {
  Pressable,
  ScrollView,
} from 'react-native';
import { useIntl } from 'react-intl';
import {
  Formik,
  FormikHelpers,
} from 'formik';
import tw, {
  Text,
  Button,
  InputForm,
  SwitchForm,
} from 'design-system';
import useRegisterForm from '../hooks/useRegisterForm';
import { RegisterFormikFields } from '../@types';
import translations from '../translations';
import validationSchema from './RegisterForm.validation';

export type RegisterScreenProps = {
  consent: {
    id: string;
    content: string;
  }
};
export default function RegisterForm({
  consent,
}: RegisterScreenProps) {
  const intl = useIntl();
  const {
    initialValues,
    register,
    openConsent,
  } = useRegisterForm({ consent });

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange
      validateOnBlur
      validationSchema={validationSchema(intl)}
      onSubmit={(
        values: RegisterFormikFields,
        formikBag: FormikHelpers<RegisterFormikFields>,
      ) => register(values, formikBag)}
    >
      {({
        handleSubmit,
      }) => (
        <>
          <ScrollView>
            <InputForm
              fieldName="email"
              label={intl.formatMessage(translations.emailLabel)}
              placeholder={intl.formatMessage(translations.emailLabel)}
              keyboardType="email-address"
              style={tw.style('mb-6')}
            />
            <SwitchForm
              fieldName="consent"
              textHelper={intl.formatMessage(translations.acceptConsentHelper)}
            >
              <Pressable
                onPress={() => openConsent()}
              >
                <Text
                  variant="link"
                  style={tw.style('ml-4 shrink')}
                >
                  {intl.formatMessage(translations.acceptConsent)}
                </Text>
              </Pressable>
            </SwitchForm>
          </ScrollView>
          <Button
            variant="full"
            onPress={() => handleSubmit()}
            style={tw.style('self-center')}
          >
            {intl.formatMessage(translations.submitButton).toUpperCase()}
          </Button>
        </>
      )}
    </Formik>
  );
}
