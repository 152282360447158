import { useActionSheet } from '@expo/react-native-action-sheet';
import { MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  Pressable,
  View,
} from 'react-native';
import translations from './translations';

type ActionSheetProps = {
  icon?: keyof typeof MaterialIcons.glyphMap;
  title?: string;
  message?: string;
  cancelText?: string;
  options: {
    [key: string]: () => void
  }
}

export default function ActionSheet({
  icon,
  title,
  message,
  cancelText,
  options,
}: ActionSheetProps) {
  const { formatMessage } = useIntl();
  const { showActionSheetWithOptions } = useActionSheet();

  return (
    <View>
      <Pressable
        onPress={() => (
          showActionSheetWithOptions(
            {
              options: [
                ...Object.keys(options),
                cancelText ?? formatMessage(translations.cancel),
              ],
              title,
              message,
              cancelButtonIndex: Object.keys(options).length,
            },
            (buttonIndex) => {
              if (buttonIndex !== Object.keys(options).length) {
                const action = Object.values(options);
                action[buttonIndex]();
              }
            },
          )
        )}
      >
        <MaterialIcons
          name={icon ?? 'more-vert'}
          size={20}
        />
      </Pressable>
    </View>
  );
}
