import {
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import React from 'react';
import {
  Image,
  View,
} from 'react-native';
import { useIntl } from 'react-intl';
import { useCommunityQuery } from 'types/graphql';
import {
  RouteProps,
  ScreenNavigationProp,
} from 'types/navigation';
import tw, {
  Text,
  ScreenAreaView,
  ScreenContainer,
  Loader,
  Link,
  UsersPartialList,
  SectionListManager,
} from 'design-system';
import Screens from '../../../screens';
import translations from './translations';

export default function ShowCommunity() {
  const { navigate } = useNavigation<ScreenNavigationProp<
    Screens.showProgram | Screens.addCommunityMember
  >>();
  const intl = useIntl();
  const { params: { id } } = useRoute<RouteProps<Screens.showCommunity>>();
  const { data, loading } = useCommunityQuery({
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <ScreenAreaView edges={['bottom']}>
      <ScreenContainer>
        <View
          style={tw.style('flex-row items-center mb-4')}
        >
          <Image
            style={
              tw.style(
                'h-20 w-20 items-center justify-center rounded-md',
              )
            }
            source={{ uri: 'https://via.placeholder.com/150' }}
          />
          <Text
            variant="pretitle"
            style={tw.style('ml-4 shrink')}
          >
            {data.community.name}
          </Text>
        </View>
        <View>
          <Text
            variant="body"
            style={tw.style('mb-4')}
          >
            {data.community.description}
          </Text>
        </View>

        <SectionListManager
          badge
          title={intl.formatMessage(translations.programs)}
          data={data.community.programs}
          addItem={() => navigate(Screens.createProgram, { id })}
          style={tw.style('mb-6')}
        >
          <Link
            route={Screens.communityProgramsList}
            params={{
              id: data.community.id,
            }}
            text={intl.formatMessage(translations.programsListLink)}
          />
        </SectionListManager>

        <SectionListManager
          badge
          title={intl.formatMessage(translations.members)}
          data={data.community.members}
          addItem={() => navigate(Screens.addCommunityMember, { id })}
        >
          <UsersPartialList
            users={data.community.members}
            LinkToListScreen={(
              <Link
                route={Screens.communityMembersList}
                params={{
                  id: data.community.id,
                }}
                text={intl.formatMessage(translations.membersListLink)}
              />
            )}
            ListEmptyComponent={(
              <Text
                variant="body"
              >
                {intl.formatMessage(translations.noMembers)}
              </Text>
            )}
          />
        </SectionListManager>
      </ScreenContainer>
    </ScreenAreaView>
  );
}
