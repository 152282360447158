import { defineMessages } from 'react-intl';
import { UpdateUserPasswordErrors } from 'types/graphql-errors';

export const errorTranslations = defineMessages({
  minimumPasswordStrength: {
    id: 'CreatePasswordForm.required.minimumPasswordStrength',
    defaultMessage: 'Votre mot de passe doit faire au minimum 8 caractères',
  },
  updatedPasswordRequired: {
    id: 'CreatePasswordForm.required.createdPasswordRequired',
    defaultMessage: 'Veuillez saisir un mot de passe',
  },
  [UpdateUserPasswordErrors.NOT_FOUND]: {
    id: 'CreatePasswordForm.error.notFound',
    defaultMessage: 'Utilisateur introuvable',
  },
  [UpdateUserPasswordErrors.USER_PASSWORD_BLANK]: {
    id: 'CreatePasswordForm.error.passwordBlank',
    defaultMessage: 'Vous devez saisir un mot de passe',
  },
  [UpdateUserPasswordErrors.USER_PASSWORD_NOT_STRONG_ENOUGH]: {
    id: 'CreatePasswordForm.error.userPasswordNotStrongEnough',
    defaultMessage: 'Votre mot de passe doit contenir au moins 8 caractères',
  },
  [UpdateUserPasswordErrors.USER_PASSWORD_TOKEN_EXPIRED]: {
    id: 'CreatePasswordForm.error.userPasswordTokenExpired',
    defaultMessage: 'Votre lien de création de mot de passe a expiré',
  },
  [UpdateUserPasswordErrors.USER_PASSWORD_TOKEN_INVALID]: {
    id: 'CreatePasswordForm.error.userPasswordTokenInvalid',
    defaultMessage: 'Votre lien de création de mot de passe n\'est pas valide',
  },
  [UpdateUserPasswordErrors.USER_PASSWORD_TOKEN_NOT_GENERATED]: {
    id: 'CreatePasswordForm.error.userPasswordTokenNotGenerated',
    defaultMessage: 'Votre lien de création de mot de passe n\'a pas été généré',
  },
});

export default defineMessages({
  title: {
    id: 'CreatePasswordScreen.title',
    defaultMessage: 'Création du compte',
  },
  subtitle: {
    id: 'CreatePasswordScreen.subtitle',
    defaultMessage: 'Choisissez votre mot de passe',
  },
  description: {
    id: 'CreatePasswordScreen.description',
    defaultMessage: 'Utilisez votre nouveau mot de passe lors de vos prochaines connexions.',
  },
  passwordLabel: {
    id: 'CreatePasswordForm.passwordLabel',
    defaultMessage: 'Nouveau mot de passe',
  },
  submitButton: {
    id: 'CreatePasswordForm.submitButton',
    defaultMessage: 'Continuer',
  },
});
