import React from 'react';
import { View } from 'react-native';
import { useIntl } from 'react-intl';
import {
  Formik,
  FormikHelpers,
} from 'formik';
import tw, {
  Button,
  InputForm,
  InputPhoneForm,
} from 'design-system';
import translations from '../translations';
import useCreateProfileForm, { CreateProfileFormikFields } from '../hooks/useCreateProfileForm';
import validationSchema from './CreateProfileForm.validation';

export default function CreateProfileForm({
  userId,
}: {
  userId: string;
}) {
  const intl = useIntl();
  const { initialValues, onSubmit } = useCreateProfileForm({
    userId,
  });

  return (
    <Formik
      initialValues={initialValues}
      validateOnChangecreateUserProfile
      validateOnBlur
      validateOnMount
      validationSchema={validationSchema(intl)}
      onSubmit={(
        values: CreateProfileFormikFields,
        formikBag: FormikHelpers<CreateProfileFormikFields>,
      ) => onSubmit(values, formikBag)}
    >
      {({
        handleSubmit,
      }) => (
        <>
          <InputForm
            fieldName="firstName"
            label={intl.formatMessage(translations.firstNameLabel)}
            style={tw.style('mb-4')}
          />
          <InputForm
            fieldName="lastName"
            label={intl.formatMessage(translations.lastNameLabel)}
            style={tw.style('mb-4')}
          />
          <InputPhoneForm
            name="phoneNumber"
            label={intl.formatMessage(translations.phoneLabel)}
          />
          <View
            style={tw.style('flex-1 justify-end')}
          >
            <Button
              variant="full"
              onPress={() => handleSubmit()}
              style={tw.style('self-center')}
            >
              {intl.formatMessage(translations.submitButton).toUpperCase()}
            </Button>
          </View>
        </>
      )}
    </Formik>
  );
}
