/* eslint-disable max-len */
import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { CreateUserProfileErrors } from 'types/graphql-errors';
import { errorTranslations } from '../translations';

export default (intl: IntlShape) => Yup.object().shape({
  firstName: Yup
    .string()
    .ensure()
    .required(
      intl.formatMessage(errorTranslations[CreateUserProfileErrors.USER_PROFILE_FIRST_NAME_BLANK]),
    ),
  lastName: Yup
    .string()
    .ensure()
    .required(
      intl.formatMessage(errorTranslations[CreateUserProfileErrors.USER_PROFILE_LAST_NAME_BLANK]),
    ),
  phoneNumber: Yup
    .string()
    .ensure()
    .required(
      intl.formatMessage(errorTranslations[CreateUserProfileErrors.USER_PROFILE_PHONE_NUMBER_INVALID]),
    ),
});
