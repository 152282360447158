import { MaterialIcons } from '@expo/vector-icons';
import React, { useState } from 'react';
import { View } from 'react-native';
import { useIntl } from 'react-intl';
import {
  Formik,
  FormikHelpers,
} from 'formik';
import tw, {
  Button,
  InputForm,
} from 'design-system';
import useCreatePasswordForm from '../hooks/useCreatePasswordForm';
import { CreatePasswordFormikFields } from '../@types';
import translations from '../translations';
import validationSchema from './CreatePasswordForm.validation';

export default function CreatePasswordForm() {
  const intl = useIntl();
  const [isPasswordSecured, setPasswordSecured] = useState(true);
  const { initialValues, updatePassword } = useCreatePasswordForm();

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange
      validateOnBlur
      validateOnMount
      validationSchema={validationSchema(intl)}
      onSubmit={(
        values: CreatePasswordFormikFields,
        formikBag: FormikHelpers<CreatePasswordFormikFields>,
      ) => updatePassword(values, formikBag)}
    >
      {({
        handleSubmit,
      }) => (
        <>
          <InputForm
            fieldName="password"
            label={intl.formatMessage(translations.passwordLabel)}
            secureTextEntry={isPasswordSecured}
            inputRightElement={(
              <MaterialIcons
                style={tw.style('self-center text-grey-500')}
                size={24}
                name={isPasswordSecured ? 'visibility' : 'visibility-off'}
                onPress={() => setPasswordSecured(!isPasswordSecured)}
              />
            )}
            style={tw.style('mb-2')}
          />
          <View
            style={tw.style('flex-1 justify-end')}
          >
            <Button
              variant="full"
              onPress={() => handleSubmit()}
              style={tw.style('self-center')}
            >
              {intl.formatMessage(translations.submitButton).toUpperCase()}
            </Button>
          </View>
        </>
      )}
    </Formik>
  );
}
