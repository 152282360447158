import {
  StackActions,
  useNavigation,
} from '@react-navigation/native';
import { useIntl } from 'react-intl';
import Toast from 'react-native-toast-message';
import { useUpdateProgramMutation } from 'types/graphql';
import { toastrTranslations } from '../translations';

export default function useUpdateProgram() {
  const intl = useIntl();
  const { dispatch } = useNavigation();

  const [updateProgramMutation] = useUpdateProgramMutation({
    onCompleted: () => dispatch(StackActions.pop()),
    onError: () => {
      Toast.show({
        type: 'error',
        text1: intl.formatMessage(toastrTranslations.failedToUpdateProgram),
      });
    },
  });

  return {
    updateProgramMutation,
  };
}
