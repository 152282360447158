import React from 'react';
import { View } from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import Badge from '../../basic/badge/Badge';
import ButtonIcon from '../../basic/button-icon/ButtonIcon';
import Text from '../../basic/text/Text';
import tw from '../../tw';

type ListManagerProps<T> = {
  children: React.ReactNode;
  title: string;
  badge?: boolean;
  data: Array<T>;
  addItem?: () => void;
  style?: Style;
}

export default function SectionListManager<T extends { id: string }>({
  children,
  title,
  badge = false,
  data,
  addItem,
  style,
}: ListManagerProps<T>) {
  return (
    <View
      style={tw.style(style)}
    >
      <View
        style={tw.style('h-8 flex-row mb-1 items-center')}
      >
        <Text
          variant="subtitle"
        >
          {title}
        </Text>
        {badge && (
          <Badge
            count={data.length}
            style={tw.style('ml-2')}
          />
        )}
        {
          addItem && (
            <ButtonIcon
              icon="add"
              action={() => addItem()}
              style={tw.style('ml-auto')}
            />
          )
        }
      </View>
      {children}
    </View>
  );
}
