import { useNavigation, useRoute } from '@react-navigation/native';
import React from 'react';
import { FlatList } from 'react-native';
import { useIntl } from 'react-intl';
import {
  ScreenAreaView,
  ScreenContainer,
  SectionListManager,
  Text,
} from 'design-system';
import { RouteProps, ScreenNavigationProp } from 'types/navigation';
import { useCommunityProgramsListQuery } from 'types/graphql';
import Screens from '../../../screens';
import ProgramItem from '../program-item/ProgramItem';
import translations from './translations';

export default function CommunityProgramsList() {
  const intl = useIntl();
  const { navigate } = useNavigation<ScreenNavigationProp<Screens.createProgram>>();
  const { params: { id } } = useRoute<RouteProps<Screens.communityProgramsList>>();
  const { data } = useCommunityProgramsListQuery({
    variables: {
      id,
    },
    fetchPolicy: 'cache-first',
  });

  return (
    <ScreenAreaView edges={['bottom']}>
      <ScreenContainer>
        <SectionListManager
          badge
          title={intl.formatMessage(translations.programs)}
          data={data.community.programs}
          addItem={() => navigate(Screens.createProgram, { id })}
        >
          <FlatList
            data={data.community.programs}
            renderItem={({ item }) => (
              <ProgramItem
                id={item.id}
                title={item.title}
                publicationStatus={item.publicationStatus}
              />
            )}
            keyExtractor={(item) => item.id}
            ListEmptyComponent={() => (
              <Text>
                {intl.formatMessage(translations.noPrograms)}
              </Text>
            )}
          />
        </SectionListManager>
      </ScreenContainer>
    </ScreenAreaView>
  );
}
