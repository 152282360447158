import { defineMessages } from 'react-intl';
import { LoginUserErrors } from 'types/graphql-errors';

export const errorTranslations = defineMessages({
  emailRequired: {
    id: 'LoginScreen.required.email',
    defaultMessage: 'Veuillez saisir un email valide',
  },
  passwordRequired: {
    id: 'LoginScreen.required.password',
    defaultMessage: 'Veuillez saisir un mot de passe valide',
  },
  [LoginUserErrors.NOT_FOUND]: {
    id: 'LoginScreen.error.notFound',
    defaultMessage: 'Utilisateur introuvable',
  },
  [LoginUserErrors.USER_PASSWORD_WRONG_PASSWORD]: {
    id: 'LoginScreen.error.userPasswordWrongPassword',
    defaultMessage: 'Mot de passe incorrect',
  },
  [LoginUserErrors.USER_EMAIL_LOCKED]: {
    id: 'LoginScreen.error.userEmailLocked',
    defaultMessage: 'Votre compte est bloqué, veuillez nous contacter',
  },
});

export default defineMessages({
  title: {
    id: 'LoginScreen.title',
    defaultMessage: 'Se connecter',
  },
  subtitle: {
    id: 'LoginScreen.subtitle',
    defaultMessage: 'Connectez-vous et enrichissez votre réseau professionnel.',
  },
  emailLabel: {
    id: 'LoginScreen.label.email',
    defaultMessage: 'E-mail',
  },
  passwordLabel: {
    id: 'LoginScreen.label.password',
    defaultMessage: 'Mot de passe',
  },
  submitButton: {
    id: 'LoginScreen.button.submit',
    defaultMessage: 'Se connecter',
  },
  askPasswordLink: {
    id: 'LoginScreen.askPassword',
    defaultMessage: '<linkThis>Mot de passe oublié ? </linkThis>',
  },
  isNotRegistered: {
    id: 'LoginScreen.isNotRegistered',
    defaultMessage: 'Pas encore inscrit ? <linkThis>Rejoignez-nous</linkThis>',
  },
});
