import React from 'react';
import Toast from 'react-native-toast-message';
import {
  Text,
  Button,
  ScreenAreaView,
  ScreenContainer,
  Loader,
} from 'design-system';
import { useMeLazyQuery } from 'types/graphql';

export default function DashboardScreen() {
  const [me, { loading, data }] = useMeLazyQuery();

  if (loading) {
    return <Loader />;
  }

  return (
    <ScreenAreaView edges={['bottom']}>
      <ScreenContainer>
        <Text variant="header1">
          dashboard
        </Text>
        <Button
          onPress={() => {
            Toast.show({
              type: 'success',
              text1: 'Hello sunshine 🥰',
            });
            me();
          }}
        >
          me
        </Button>
        <Text>
          {JSON.stringify(data)}
        </Text>
      </ScreenContainer>
    </ScreenAreaView>
  );
}
