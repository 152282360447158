import React from 'react';
import {
  Text,
  ScreenAreaView,
  ScreenContainer,
} from 'design-system';

export default function NotificationsScreen() {
  return (
    <ScreenAreaView edges={['bottom']}>
      <ScreenContainer>
        <Text>
          Notifications
        </Text>
      </ScreenContainer>
    </ScreenAreaView>
  );
}
