import React, { ReactNode } from 'react';
import { Pressable } from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import tw from '../../tw';
import Text from '../text/Text';

export type ColorType = 'primary' | 'secondary' | 'tertiary';

export type VariantType = 'full' | 'outlined' | 'ghost';

export type ButtonProps = {
  children: ReactNode,
  color?: ColorType,
  variant?: VariantType,
  style?: Style,
  onPress: () => void,
  disabled?: boolean,
}

export default function Button({
  children,
  color = 'primary',
  variant = 'full',
  style,
  onPress,
  disabled = false,
}: ButtonProps) {
  return (
    <Pressable
      accessible
      accessibilityRole="button"
      style={tw.style(
        'flex items-center justify-center h-10 px-3  rounded-md',
        {
          // basic
          [`bg-${color}-500`]: variant === 'full',
          [`border border-${color}-500 bg-transparent dark:bg-transparent `]: variant === 'outlined',
          // disabled
          'bg-grey-200 dark:bg-grey-800': disabled && variant === 'full',
          'border border-grey-200': disabled && variant === 'outlined',
        },
        style,
      )}
      onPress={onPress}
      disabled={disabled}
    >
      <Text
        variant="buttontext"
        style={tw.style({
          'text-white': !disabled && variant === 'full',
          [`text-${color}-500`]: !disabled && ['outlined', 'ghost'].includes(variant),
          'text-grey-400 dark:text-grey-600': disabled && variant === 'full',
          'text-grey-200 dark:text-grey-800': disabled && ['outlined', 'ghost'].includes(variant),
        })}
      >
        { children }
      </Text>
    </Pressable>
  );
}
