import {
  useLogoutUserMutation,
  useMeQuery,
} from 'types/graphql';
import { useAuthorizationContext } from 'providers/authorization/AuthorizationContext';
import { removeLocalStorageItem } from 'utils/storage/storage';
import Storage from 'enums/storage';
import client from '../../../../../graphql/graphql';

export default function useLogoutButton() {
  const { data } = useMeQuery({
    fetchPolicy: 'cache-only',
  });
  const { setAuthenticated } = useAuthorizationContext();
  const [logoutUser] = useLogoutUserMutation({
    variables: {
      id: data?.me?.id,
      client: 'web',
    },
    onCompleted: () => {
      client.resetStore(); // delete apollo cache
      removeLocalStorageItem(Storage.SESSION);
      setAuthenticated(false); // set authenticated to false to redirect to public page
    },
  });

  return {
    logoutUser,
  };
}
