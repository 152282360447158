import { useRoute } from '@react-navigation/native';
import { useMemo } from 'react';
import OpenLink from '../../../../utils/open-link/OpenLink';
import useAcceptConsent from './useAcceptConsent';
import useRegisterUser from './useRegisterUser';
import useSuccessAcceptConsent from './useSuccessAcceptConsent';

const myjobglassesWebsite = process.env.REACT_APP_MYJOBGLASSES_WEBSITE_URL;

export default function useRegisterForm({ consent }) {
  const { params } = useRoute<{
    key: string;
    path?: string;
    name: string;
    params?: { [key: string]: any };
  }>();
  const initialValues = {
    email: params?.email || '',
    consent: false,
  };

  const successAcceptConsent = useSuccessAcceptConsent();
  const acceptConsent = useAcceptConsent({
    consentId: consent.id,
    onSuccess: successAcceptConsent,
  });

  const register = useRegisterUser({
    onSuccess: acceptConsent,
  });

  const openConsent = useMemo(() => OpenLink({
    url: consent.content || myjobglassesWebsite,
  }), [consent.content]);

  return {
    initialValues,
    acceptConsent,
    register,
    openConsent,
  };
}
