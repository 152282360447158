import { MaterialIcons } from '@expo/vector-icons';
import { Link } from '@react-navigation/native';
import React from 'react';
import {
  FlatList,
  View,
} from 'react-native';
import { useIntl } from 'react-intl';
import tw, {
  Text,
  ScreenAreaView,
  ScreenContainer,
} from 'design-system';
import { RootStackParamList } from 'types/navigation';
import CurrentUser from './current-user/CurrentUser';
import LogoutButton from './logout-button/LogoutButton';
import links from './links';

export default function MainDrawerContent() {
  const { formatMessage } = useIntl();

  return (
    <ScreenAreaView edges={['top', 'bottom']}>
      <ScreenContainer>
        <View>
          <CurrentUser />
          <FlatList
            data={links}
            keyExtractor={(item) => item.route}
            renderItem={({ item }) => (
              <Link<RootStackParamList>
                to={{
                  screen: item.route,
                }}
              >
                <View
                  style={tw.style('flex flex-row justify-between h-14 w-full items-center')}
                >
                  <Text>
                    {formatMessage(item.title)}
                  </Text>
                  <MaterialIcons
                    name="keyboard-arrow-right"
                    size={24}
                  />
                </View>
              </Link>
            )}
          />
          <LogoutButton />
        </View>
      </ScreenContainer>
    </ScreenAreaView>
  );
}
