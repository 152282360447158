import { InMemoryCache } from '@apollo/client';
import {
  persistCache,
  LocalStorageWrapper,
} from 'apollo3-cache-persist';

export default async function persist(cache: InMemoryCache) {
  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });
}
