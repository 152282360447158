import React from 'react';
import { Platform } from 'react-native';
import { useIntl } from 'react-intl';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import navigationTranslations from 'shared/translations/navigation.translations';
import { RootStackParamList } from 'types/navigation';
import tw from 'design-system';
import Screens from '../../screens';
import PublicScreen from '../../screens/public/PublicScreen';
import AskPasswordScreen from '../../screens/user/ask-password/AskPasswordScreen';
import LoginScreen from '../../screens/user/login/LoginScreen';
import RegisterScreen from '../../screens/user/register/RegisterScreen';
import UpdatePasswordScreen from '../../screens/user/update-password/UpdatePasswordScreen';
import CheckRegistrationTokenScreen from '../../screens/user/check-registration-token/CheckRegistrationTokenScreen';
import RegisterSuccessScreen from '../../screens/user/register-success/RegisterSuccessScreen';
import CreatePasswordScreen from '../../screens/user/create-password/CreatePasswordScreen';
import HeaderBackButton from '../HeaderBackButton';

export default function Unauthenticated() {
  const intl = useIntl();
  const UnauthenticatedStackNavigator = createNativeStackNavigator<RootStackParamList>();

  const screenOptions = {
    headerShown: !(Platform.OS === 'web'),
    headerBackTitleVisible: true,
    headerBackTitle: intl.formatMessage(navigationTranslations.backButton),
    headerTintColor: tw.color('black'),
    headerShadowVisible: false,
    title: '',
    headerLeft: HeaderBackButton,
  };

  return (
    <UnauthenticatedStackNavigator.Navigator
      initialRouteName={Screens.public}
      screenOptions={screenOptions}
    >
      <UnauthenticatedStackNavigator.Screen
        name={Screens.public}
        component={PublicScreen}
        options={{
          headerShown: false,
        }}
      />
      <UnauthenticatedStackNavigator.Screen
        name={Screens.login}
        component={LoginScreen}
      />
      <UnauthenticatedStackNavigator.Screen
        name={Screens.register}
        component={RegisterScreen}
      />
      <UnauthenticatedStackNavigator.Screen
        name={Screens.askPassword}
        component={AskPasswordScreen}
      />
      <UnauthenticatedStackNavigator.Screen
        name={Screens.updatePassword}
        component={UpdatePasswordScreen}
      />
      <UnauthenticatedStackNavigator.Screen
        name={Screens.checkRegistrationToken}
        component={CheckRegistrationTokenScreen}
        options={{
          headerShown: false,
        }}
      />
      <UnauthenticatedStackNavigator.Screen
        name={Screens.createPassword}
        component={CreatePasswordScreen}
        options={{
          headerShown: false,
        }}
      />
      <UnauthenticatedStackNavigator.Group
        screenOptions={{
          presentation: 'modal',
        }}
      >
        <UnauthenticatedStackNavigator.Screen
          name={Screens.registerSuccess}
          component={RegisterSuccessScreen}
        />
      </UnauthenticatedStackNavigator.Group>
    </UnauthenticatedStackNavigator.Navigator>
  );
}
