import { defineMessages } from 'react-intl';

export default defineMessages({
  unknown: {
    id: 'UserItem.unknown',
    defaultMessage: 'Utilisateur Inconnu',
  },
  pending: {
    id: 'UserItem.pending',
    defaultMessage: 'En attente',
  },
  accepted: {
    id: 'UserItem.accepted',
    defaultMessage: 'Accepté',
  },
  declined: {
    id: 'UserItem.declined',
    defaultMessage: 'Rejeté',
  },
  canceled: {
    id: 'UserItem.canceled',
    defaultMessage: 'Annulé',
  },
});
