import React from 'react';
import {
  Link as RNNLink,
  StackActions,
} from '@react-navigation/native';
import { RootStackParamList } from 'types/navigation';
import Text from '../../basic/text/Text';

type LinkProps<T> = {
  text: string
  route: T
  replace?: boolean | undefined
};

// eslint-disable-next-line max-len
interface ILinkWrapperProps<RouteName extends keyof RootStackParamList = keyof RootStackParamList> extends LinkProps<RouteName> {
  params?: RootStackParamList[RouteName]
}

export default function Link({
  text,
  route,
  params = undefined,
  replace = undefined,
}: ILinkWrapperProps) {
  return (
    <RNNLink<RootStackParamList>
      to={{
        screen: route,
        params,
      }}
      action={replace ? StackActions.replace(route, params) : undefined}
    >
      <Text
        variant="link"
      >
        {text}
      </Text>
    </RNNLink>
  );
}
