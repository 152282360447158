import {
  createContext,
  useContext,
} from 'react';

interface Context {
  local: string | undefined,
  setLocal: (value: string) => void,
}

const LocalizationContext = createContext<Context>({
  local: undefined,
  setLocal: () => {},
});

export function useLocalizationContextContext() {
  const context = useContext(LocalizationContext);
  if (context === undefined) {
    throw new Error('useLocalizationContextContext must be used within a LocalizationProvider');
  }

  return context;
}

export default LocalizationContext;
