import { defineMessages } from 'react-intl';

export default defineMessages({
  members: {
    id: 'CommunityMembersList.members',
    defaultMessage: 'Membres',
  },
  noMembers: {
    id: 'CommunityMembersList.noMembers',
    defaultMessage: 'Pas de membres',
  },
});
