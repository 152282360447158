import React from 'react';
import { View } from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import tw from '../../tw';
import Text from '../text/Text';

type BadgeProps = {
  count: number;
  style?: Style
}

export default function Badge({
  count,
  style,
}: BadgeProps) {
  return (
    <View
      style={tw.style(
        'w-5 h-5 items-center justify-center rounded-full bg-primary-200',
        style,
      )}
    >
      <Text
        variant="small"
        style={tw.style('font-bold text-primary-700')}
      >
        {count}
      </Text>
    </View>
  );
}
