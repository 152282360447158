import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { useIntl } from 'react-intl';
import tw, {
  Text,
  Button,
  ScreenAreaView,
  ScreenContainer,
  Loader,
  Link,
} from 'design-system';
import { ScreenNavigationProp } from 'types/navigation';
import Myjobglasses from '../../assets/svg/Myjobglasses';
import Screens from '../../screens';
import translations from './translations';
import usePublicScreen from './hooks/usePublicScreen';

export default function PublicScreen() {
  const intl = useIntl();
  const { navigate } = useNavigation<ScreenNavigationProp<Screens.register>>();
  const { loading } = usePublicScreen();

  function gotToRegister() {
    navigate(Screens.register);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <ScreenAreaView>
      <ScreenContainer>
        <View
          style={tw.style('flex-1')}
        >
          <Myjobglasses
            width="100%"
            height="60px"
          />
        </View>

        <View
          style={tw.style('flex-4 justify-center items-center')}
        >
          <Text
            variant="header1"
            style={tw.style('mb-8')}
          >
            {intl.formatMessage(translations.title)}
          </Text>
          <Text
            variant="subtitle"
            style={tw.style('text-center mb-12')}
          >
            {intl.formatMessage(translations.subtitle)}
          </Text>
          <Button
            onPress={() => gotToRegister()}
          >
            {intl.formatMessage(translations.register).toUpperCase()}
          </Button>
        </View>

        <View
          style={tw.style('flex-1 justify-center')}
        >
          <Text
            style={tw.style('text-center')}
          >
            {intl.formatMessage(translations.alreadySignin, {
              linkThis: (text: string) => (
                <Link
                  route={Screens.login}
                  text={text}
                />
              ),
            })}
          </Text>
        </View>
      </ScreenContainer>
    </ScreenAreaView>
  );
}
