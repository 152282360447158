import { defineMessages } from 'react-intl';

export const errorTranslations = defineMessages({
  titleRequired: {
    id: 'CreateCommunityForm.required.titleRequired',
    defaultMessage: 'Titre Requis',
  },
  descriptionRequired: {
    id: 'CreateCommunityForm.required.descriptionRequired',
    defaultMessage: 'Description requise',
  },
});

export const toastrTranslations = defineMessages({
  failedToCreateCommunity: {
    id: 'CreateCommunityForm.toastr.failedToCreateCommunity',
    defaultMessage: 'Échec de la création de la communauté',
  },
});

export default defineMessages({
  title: {
    id: 'CreateCommunity.title',
    defaultMessage: 'Créer une communauté',
  },
  formLabelTitle: {
    id: 'CreateCommunity.formLabelTitle',
    defaultMessage: 'Titre de communauté',
  },
  formLabelDescription: {
    id: 'CreateCommunity.formLabelDescription',
    defaultMessage: 'Description de communauté',
  },
  formButtonSubmit: {
    id: 'CreateCommunity.formButtonSubmit',
    defaultMessage: 'Créer',
  },
  imageUploadLabel: {
    id: 'CreateCommunity.imageUploadLabel',
    defaultMessage: 'Logo',
  },
});
