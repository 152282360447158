import React from 'react';
import { View } from 'react-native';
import { useIntl } from 'react-intl';
import tw, {
  ScreenContainer,
  Text,
} from 'design-system';
import UpdateProgramForm from './update-program-form/UpdateProgramForm';
import translations from './translations';

export default function UpdateProgram() {
  const intl = useIntl();
  return (
    <ScreenContainer>
      <View
        style={tw.style('flex-1 flex-col')}
      >
        <Text
          variant="header2"
          style={tw.style('mb-6')}
        >
          {intl.formatMessage(translations.title)}
        </Text>
        <UpdateProgramForm />
      </View>
    </ScreenContainer>
  );
}
