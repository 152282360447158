import { useIntl } from 'react-intl';
import Toast from 'react-native-toast-message';
import { usePublishProgramMutation } from 'types/graphql';
import { toastrTranslations } from './translations';

export default function usePublishProgram() {
  const intl = useIntl();

  const [publishProgramMutation] = usePublishProgramMutation({
    refetchQueries: ['program'],
    onCompleted: () => (
      Toast.show({
        type: 'success',
        text1: intl.formatMessage(toastrTranslations.success),
      })
    ),
    onError: () => (
      Toast.show({
        type: 'error',
        text1: intl.formatMessage(toastrTranslations.error),
      })
    ),
  });

  return {
    publishProgramMutation,
  };
}
