import { useRoute } from '@react-navigation/native';
import useSuccessUpdatePassword from './useSuccessUpdatePassword';
import useUpdateUserPassword from './useUpdatePassword';

export default function useUpdatePasswordForm() {
  const { params } = useRoute<{
    key: string;
    path?: string;
    name: string;
    params?: { [key: string]: any };
  }>();
  const initialValues = {
    passwordToken: params?.passwordToken || '',
    password: '',
  };
  const onSuccess = useSuccessUpdatePassword();
  const updatePassword = useUpdateUserPassword({ onSuccess });

  return {
    initialValues,
    updatePassword,
  };
}
