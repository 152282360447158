import React, { ReactNode } from 'react';
import { View } from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import tw from '../../tw';
import Text from '../text/Text';

export type ColorType = 'success' | 'warning' | 'danger' | 'primary' | 'secondary' | 'tertiary';

export type TagProps = {
  children: ReactNode,
  color?: ColorType,
  style?: Style,
}

export default function Tag({
  children,
  color = 'primary',
  style,
}: TagProps) {
  return (
    <View
      style={tw.style(
        `text-center uppercase px-2 py-1 rounded-full bg-${color}-700`,
        style,
      )}
    >
      <Text
        variant="small"
        style={tw.style(
          'text-light',
        )}
      >
        { children }
      </Text>
    </View>
  );
}
