import { gql } from '@apollo/client';

export const AUTO_LOGIN = gql`
  query autoLogin {
    me {
      id
      createdAt
      updatedAt
      email
      authoredCommunities{
        id
        name
        description
      }
      userProfile {
        id
        createdAt
        updatedAt
        avatarUrl
        firstName
        lastName
        phoneNumber
      }
    }
  }
`;
