/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Appearance,
  Platform,
  Switch as RNSwitch,
} from 'react-native';
import tw from '../../tw';

type SwitchProps = {
  value: boolean;
  disabled?: boolean;
  onValueChange: (value: boolean, shouldValidate?: boolean) => void;
}
export default function Switch({
  value,
  disabled = false,
  onValueChange,
}: SwitchProps) {
  const colorScheme = Appearance.getColorScheme();
  const [isToggled, toggleSwitch] = useState(value);

  return (
    <RNSwitch
      trackColor={{
        true: tw.color('primary-500'),
        false: colorScheme === 'light' ? tw.color('grey-200') : tw.color('grey-500'),
      }}
      thumbColor={tw.color('light')}
      ios_backgroundColor={colorScheme === 'light' ? tw.color('grey-200') : tw.color('grey-500')}
      onValueChange={(toggle) => {
        toggleSwitch(toggle);
        onValueChange(toggle, true);
      }}
      value={isToggled}
      disabled={disabled}
      {...Platform.select({
        web: {
          activeThumbColor: tw.color('light'),
          activeTrackColor: tw.color('primary-500'),
        },
      })}
    />
  );
}
