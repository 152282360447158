import React from 'react';
import { View } from 'react-native';
import tw, {
  Text,
  Avatar,
  Loader,
} from 'design-system';
import { useMeQuery } from 'types/graphql';

export default function CurrentUser() {
  const { loading, data } = useMeQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <View
      style={tw.style('flex-row items-center mb-6')}
    >
      <Avatar
        variant="medium"
        profile={data.me.userProfile}
      />
      <Text
        style={tw.style('ml-5')}
      >
        {data.me.userProfile.firstName}
      </Text>
      <Text
        style={tw.style('ml-1')}
      >
        {data?.me?.userProfile.lastName.toUpperCase()}
      </Text>
    </View>
  );
}
