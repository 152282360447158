import React from 'react';
import { HeaderBackButton as RNEHeaderBackButton } from '@react-navigation/elements';
import { useNavigation } from '@react-navigation/native';
import { useIntl } from 'react-intl';
import navigationTranslations from 'shared/translations/navigation.translations';
import tw from 'design-system';

export default function HeaderBackButton({
  label,
}: {
  label?: string;
}) {
  const intl = useIntl();
  const navigation = useNavigation();

  return (
    <RNEHeaderBackButton
      label={label ?? intl.formatMessage(navigationTranslations.backButton)}
      labelVisible
      tintColor={tw.color('black')}
      onPress={() => navigation.goBack()}
    />
  );
}
