import { MaterialIcons } from '@expo/vector-icons';
import React, { useState } from 'react';
import { ScrollView } from 'react-native';
import { useIntl } from 'react-intl';
import {
  Formik,
  FormikHelpers,
} from 'formik';
import tw, {
  Text,
  ScreenAreaView,
  ScreenContainer,
  Button,
  InputForm,
  Link,
  KeyboardDisplayManager,
} from 'design-system';
import Screens from '../../../screens';
import { FormikFields } from './@types';
import useLoginForm from './hooks/useLoginScreen';
import translations from './translations';
import validationSchema from './LoginScreen.validation';

export default function LoginScreen() {
  const intl = useIntl();
  const [isPasswordSecured, setPasswordSecured] = useState(true);
  const {
    initialValues,
    login,
  } = useLoginForm();

  return (
    <ScreenAreaView edges={['bottom']}>
      <Formik
        initialValues={initialValues}
        validateOnChange
        validateOnBlur
        validateOnMount
        validationSchema={validationSchema(intl)}
        onSubmit={(
          values: FormikFields,
          formikBag: FormikHelpers<FormikFields>,
        ) => login(values, formikBag)}
      >
        {({
          handleSubmit,
        }) => (

          <ScreenContainer>
            <KeyboardDisplayManager>
              <ScrollView>
                <Text
                  variant="header1"
                  style={tw.style('mb-4')}
                >
                  {intl.formatMessage(translations.title)}
                </Text>
                <Text
                  variant="subtitle"
                  style={tw.style('mb-4')}
                >
                  {intl.formatMessage(translations.subtitle)}
                </Text>
                <Text
                  variant="body"
                  style={tw.style('mb-6')}
                >
                  {intl.formatMessage(translations.isNotRegistered, {
                    linkThis: (text: string) => (
                      <Link
                        route={Screens.register}
                        text={text}
                      />
                    ),
                  })}
                </Text>
                <InputForm
                  fieldName="email"
                  label={intl.formatMessage(translations.emailLabel)}
                  keyboardType="email-address"
                  style={tw.style('mb-4')}
                />
                <InputForm
                  fieldName="password"
                  label={intl.formatMessage(translations.passwordLabel)}
                  secureTextEntry={isPasswordSecured}
                  inputRightElement={(
                    <MaterialIcons
                      style={tw.style('self-center text-grey-500')}
                      size={24}
                      name={isPasswordSecured ? 'visibility' : 'visibility-off'}
                      onPress={() => setPasswordSecured(!isPasswordSecured)}
                    />
                  )}
                  style={tw.style('mb-2')}
                />
                <Text
                  variant="link"
                  style={tw.style('mb-6')}
                >
                  {intl.formatMessage(translations.askPasswordLink, {
                    linkThis: (text: string) => (
                      <Link
                        route={Screens.askPassword}
                        text={text}
                      />
                    ),
                  })}
                </Text>
              </ScrollView>
              <Button
                variant="full"
                onPress={handleSubmit}
                style={tw.style('self-center')}
              >
                {intl.formatMessage(translations.submitButton).toUpperCase()}
              </Button>
            </KeyboardDisplayManager>
          </ScreenContainer>
        )}
      </Formik>
    </ScreenAreaView>
  );
}
