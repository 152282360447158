import React from 'react';
import { useIntl } from 'react-intl';
import tw, {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
} from 'design-system';
import useProgramDelete from './useProgramDelete';
import translations from '../translations';

export function ProgramDeleteModal({
  isDeleteModalVisible,
  setIsDeleteModalVisible,
}: {
  isDeleteModalVisible: boolean;
  setIsDeleteModalVisible: (value: boolean) => void;
}) {
  const intl = useIntl();
  const deleteProgram = useProgramDelete();

  return (
    <Modal
      isVisible={isDeleteModalVisible}
    >
      <ModalBody>
        <Text
          variant="pretitle"
          style={tw.style('text-center')}
        >
          {intl.formatMessage(translations.deleteProgramTitle)}
        </Text>
      </ModalBody>
      <ModalFooter style={tw.style('flex-row')}>
        <Button
          style={tw.style('mx-2')}
          onPress={() => { deleteProgram(); }}
        >
          {intl.formatMessage(translations.deleteProgramModalSubmitButton)}
        </Button>
        <Button
          style={tw.style('mx-2')}
          onPress={() => {
            setIsDeleteModalVisible(false);
          }}
        >
          {intl.formatMessage(translations.deleteProgramModalCancelButton)}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
