import React from 'react';
import {
  Pressable,
  View,
} from 'react-native';
import { useIntl } from 'react-intl';
import tw, {
  Text,
  ScreenContainer,
} from 'design-system';
import translations from './translations';
import useRegisterSuccessScreen from './hooks/useRegisterSuccessScreen';

export default function RegisterSuccessScreen() {
  const intl = useIntl();
  const {
    initialValues,
    askUserRegistration,
  } = useRegisterSuccessScreen();

  return (
    <ScreenContainer>
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          variant="header1"
          style={tw.style('text-center mb-4')}
        >
          🎉
        </Text>
        <Text
          style={tw.style('text-center mb-4 font-medium')}
        >
          {intl.formatMessage(translations.text)}
        </Text>
        <Text
          style={tw.style('text-center mb-12 font-medium')}
        >
          {initialValues?.email}
        </Text>
        <Text
          style={tw.style('mb-2')}
        >
          {intl.formatMessage(translations.question)}
        </Text>
        <Pressable
          onPress={() => askUserRegistration({
            variables: {
              email: initialValues?.email,
            },
          })}
        >
          <Text
            style={tw.style('underline text-primary-500')}
          >
            {intl.formatMessage(translations.link)}
          </Text>
        </Pressable>
      </View>
    </ScreenContainer>
  );
}
