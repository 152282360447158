import { useRoute } from '@react-navigation/native';
import React from 'react';
import { FlatList } from 'react-native';
import { useIntl } from 'react-intl';
import {
  Card,
  ScreenAreaView,
  ScreenContainer,
  SectionListManager,
  Text,
} from 'design-system';
import { RouteProps } from 'types/navigation';
import { useCommunityMembersListQuery } from 'types/graphql';
import UserItem from 'shared/components/user-item/UserItem';
import Screens from '../../../screens';
import translations from './translations';

export default function CommunityMembersList() {
  const intl = useIntl();
  const { params: { id } } = useRoute<RouteProps<Screens.communityMembersList>>();
  const { data } = useCommunityMembersListQuery({
    variables: {
      id,
    },
  });

  return (
    <ScreenAreaView edges={['bottom']}>
      <ScreenContainer>
        <SectionListManager
          badge
          data={data.community.members}
          title={intl.formatMessage(translations.members)}
        >
          <FlatList
            data={data.community.members}
            renderItem={({ item }) => (
              <Card key={item.id}>
                <UserItem
                  userProfile={item.userProfile}
                />
              </Card>
            )}
            keyExtractor={(item) => item.id}
            ListEmptyComponent={() => (
              <Text>
                {intl.formatMessage(translations.noMembers)}
              </Text>
            )}
          />
        </SectionListManager>
      </ScreenContainer>
    </ScreenAreaView>
  );
}
