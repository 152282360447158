import { defineMessages } from 'react-intl';
import { AskPasswordUserErrors } from 'types/graphql-errors';

export const errorTranslations = defineMessages({
  emailRequired: {
    id: 'AskPasswordForm.required.email',
    defaultMessage: 'Veuillez saisir un email valide',
  },
  [AskPasswordUserErrors.NOT_FOUND]: {
    id: 'AskPasswordForm.notFound',
    defaultMessage: 'Utilisateur introuvable',
  },
  [AskPasswordUserErrors.UNAUTHORIZED]: {
    id: 'AskPasswordForm.unauthorized',
    defaultMessage: 'Utilisateur non autorisé',
  },
  [AskPasswordUserErrors.USER_EMAIL_BLANK]: {
    id: 'AskPasswordForm.userEmailBlank',
    defaultMessage: 'Veuillez saisir un email valide',
  },
  [AskPasswordUserErrors.USER_EMAIL_INVALID]: {
    id: 'AskPasswordForm.userEmailInvalid',
    defaultMessage: 'Veuillez saisir un email valide',
  },
});
export default defineMessages({
  title: {
    id: 'AskPassword.title',
    defaultMessage: 'Mot de passe oublié',
  },
  subtitle: {
    id: 'AskPassword.subtitle',
    defaultMessage: 'Indiquez votre e-mail pour recevoir un lien re réinitialisation de votre mot de passe.',
  },
  label: {
    id: 'AskPasswordForm.label',
    defaultMessage: 'Email',
  },
  submitButton: {
    id: 'AskPasswordForm.submit',
    defaultMessage: 'Envoyez',
  },
});
