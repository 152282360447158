import React from 'react';
import {
  Modal as RNModal,
  View,
} from 'react-native';
import tw from '../../tw';

type ModalProps = {
  isVisible: boolean;
  children: React.ReactNode;
}
export default function Modal({
  children,
  isVisible,
}: ModalProps) {
  return (
    <View style={tw.style('flex-1 justify-center items-center')}>
      <RNModal
        animationType="fade"
        transparent
        visible={isVisible}
      >
        <View style={tw.style('flex-1 justify-center items-center')}>
          <View
            style={tw.style(
              'rounded-md shadow-black shadow-offset-[0px]/[2px] shadow-opacity-25 shadow-radius-1 bg-light p-2',
              {
                elevation: 5,
              },
            )}
          >
            {children}
          </View>
        </View>
      </RNModal>
    </View>
  );
}
