import React from 'react';
import { View } from 'react-native';
import tw from '../../tw';

export default function ProgressBar({
  progress = 0,
}: {
  progress?: number;
}) {
  return (
    <View
      style={tw.style('h-1 w-full bg-grey-200 rounded-md')}
    >
      <View
        style={tw.style(
          'h-1 bg-primary-500 rounded-md',
          {
            width: `${progress}%`,
          },
        )}
        accessibilityRole="progressbar"
        accessibilityValue={{
          min: 0,
          max: 100,
          now: progress,
        }}
      />
    </View>
  );
}
