import {
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { useIntl } from 'react-intl';
import tw, {
  Link,
  Loader,
  ScreenAreaView,
  ScreenContainer,
  SectionListManager,
  Tag,
  Text,
  UsersPartialList,
} from 'design-system';
import {
  RouteProps,
  ScreenNavigationProp,
} from 'types/navigation';
import { useProgramQuery } from 'types/graphql';
import Screens from '../../../screens';
import PublishProgramButton from './publish-program-button/PublishProgramButton';
import translations from './translations';

export default function ShowProgram() {
  const intl = useIntl();
  const { navigate } = useNavigation<ScreenNavigationProp<
  Screens.addProgramParticipant | Screens.addProgramOwner
  >>();
  const { params: { id } } = useRoute<RouteProps<Screens.showProgram>>();
  const { data, loading } = useProgramQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <ScreenAreaView edges={['bottom']}>
      <ScreenContainer>
        <View
          style={tw.style('flex-row items-center mb-4')}
        >
          <Tag
            color={data.program.publicationStatus === 'published' ? 'success' : 'warning'}
          >
            {intl.formatMessage(translations[data.program.publicationStatus])}
          </Tag>
          {
            data.program.publicationStatus === 'draft' && (
              <PublishProgramButton
                id={id}
                style={tw.style('ml-auto')}
              />
            )
          }
        </View>
        <Text
          variant="pretitle"
          style={tw.style('mb-4')}
        >
          {data.program.title}
        </Text>
        <Text
          style={tw.style('mb-4')}
        >
          {intl.formatMessage(translations.title)}
        </Text>
        <Text
          variant="body"
          style={tw.style('mb-4')}
        >
          {data.program.community.name}
        </Text>
        <Text
          variant="body"
          style={tw.style('mb-4')}
        >
          {data.program.description}
        </Text>

        <SectionListManager
          badge
          title={intl.formatMessage(translations.participants)}
          data={data.program.participants}
          addItem={() => navigate(Screens.addProgramParticipant, { id })}
          style={tw.style('mb-4')}
        >
          <UsersPartialList
            users={data.program.participants}
            LinkToListScreen={(
              <Link
                route={Screens.programParticipantsList}
                params={{
                  id: data.program.id,
                }}
                text={intl.formatMessage(translations.participantsLinkList)}
              />
            )}
            ListEmptyComponent={(
              <Text
                variant="body"
              >
                {intl.formatMessage(translations.noParticipants)}
              </Text>
            )}
          />
        </SectionListManager>

        <SectionListManager
          badge
          title={intl.formatMessage(translations.owners)}
          data={data.program.owners}
          addItem={() => navigate(Screens.addProgramOwner, { id })}
        >
          <UsersPartialList
            users={data.program.owners}
            LinkToListScreen={(
              <Link
                route={Screens.programOwnersList}
                params={{
                  id: data.program.id,
                }}
                text={intl.formatMessage(translations.ownersLinkList)}
              />
            )}
            ListEmptyComponent={(
              <Text
                variant="body"
              >
                {intl.formatMessage(translations.noOwners)}
              </Text>
            )}
          />
        </SectionListManager>
      </ScreenContainer>
    </ScreenAreaView>
  );
}
