import { defineMessages } from 'react-intl';

export default defineMessages({
  programs: {
    id: 'CommunityProgramsList.programs',
    defaultMessage: 'Programmes',
  },
  noPrograms: {
    id: 'CommunityProgramsList.noPrograms',
    defaultMessage: 'Pas de programmes',
  },
});
