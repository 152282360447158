import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'UpdateProgram.title',
    defaultMessage: 'Modifier un Programme',
  },
  formLabelTitle: {
    id: 'UpdateProgram.formLabelTitle',
    defaultMessage: 'Titre de programme',
  },
  formLabelDescription: {
    id: 'UpdateProgram.formLabelDescription',
    defaultMessage: 'Description de programme',
  },
  formButtonSubmit: {
    id: 'UpdateProgram.formButtonSubmit',
    defaultMessage: 'Modifier',
  },
  switchPublicationStatusPublic: {
    id: 'UpdateProgram.switchPublicationStatusPublic',
    defaultMessage: 'Rendre public',
  },
  switchPublicationStatusPrivate: {
    id: 'UpdateProgram.switchPublicationStatusPrivate',
    defaultMessage: 'Rendre privé',
  },
});

export const errorTranslations = defineMessages({
  titleRequired: {
    id: 'UpdateProgramForm.required.titleRequired',
    defaultMessage: 'Titre Requis',
  },
  descriptionRequired: {
    id: 'UpdateProgramForm.required.descriptionRequired',
    defaultMessage: 'Description requise',
  },
});

export const toastrTranslations = defineMessages({
  failedToUpdateProgram: {
    id: 'UpdateProgramForm.toastr.failedToUpdateProgram',
    defaultMessage: 'Échec de la mise à jour du programme',
  },
  failedToFetchPrograms: {
    id: 'UpdateProgramForm.toastr.failedToFetchPrograms',
    defaultMessage: 'Échec de la récupération des programmes',
  },
});
