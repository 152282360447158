import React from 'react';
import { Appearance } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import tw from '../../design-system/tw';

export default function Myjobglasses({
  width = '216px',
  height = '48px',
}: {
  width?: string,
  height?: string,
}) {
  const colorScheme = Appearance.getColorScheme();
  const textColor = colorScheme === 'dark' ? tw.color('text-light') : tw.color('text-dark');

  return (
    <Svg
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox="0 0 216 48"
      fill="none"
    >
      <Path
        d="M0.736328 47.3333L215.264 47.3333V0.677006L0.736328 0.677006V47.3333Z"
        fill="transparent"
      />
      <Path
        d="M24.1137 0.677032C11.2034 0.677032 0.736328 11.1226 0.736328 24.0059C0.736328 36.8883 11.2034 47.3333 24.1137 47.3333C37.0228 47.3333 47.4892 36.8883 47.4892 24.0059C47.4892 11.1226 37.0228 0.677032 24.1137 0.677032ZM24.1137 42.453C13.9038 42.453 5.62724 34.1941 5.62724 24.0059C5.62724 13.8181 13.9038 5.55655 24.1137 5.55655C32.0362 5.55655 38.7772 10.5427 41.4071 17.5359C40.7191 17.3285 39.9942 17.2088 39.241 17.2002C39.1756 17.1956 39.1401 17.1976 39.1401 17.1956C37.4001 17.197 35.7946 17.7683 34.4879 18.7241C32.5725 14.9899 28.7026 12.4115 24.2146 12.3694V12.3714C24.1567 12.3687 24.1203 12.3694 24.1078 12.3694C18.6549 12.3694 14.0648 16.1325 12.8056 21.2095L12.0411 21.2043C12.0253 21.2031 12.0108 21.2024 11.9976 21.2004H11.8999V21.2024C10.7879 21.2234 9.88095 22.0916 9.80841 23.1935C9.7972 23.2672 9.80112 23.3113 9.80449 23.341V23.3778C9.80449 23.3825 9.80449 23.3857 9.80571 23.389C9.80449 23.3903 9.80449 23.3917 9.80449 23.393H9.80571C9.8388 24.5338 10.7648 25.4526 11.9217 25.4677L12.5543 25.4723C13.2785 31.1557 18.1121 35.5849 24.0109 35.6395H24.0452C24.0452 35.6395 24.0676 35.6395 24.1137 35.6395C30.1662 35.6356 35.1548 31.0121 35.7108 25.0946C35.7115 25.0774 35.7188 25.0629 35.7188 25.0471C35.7326 23.1771 37.2635 21.6618 39.1296 21.6618C41.0081 21.6737 42.5008 23.156 42.5502 24.9761C42.0409 34.7108 33.9925 42.453 24.1137 42.453ZM31.2915 24.0045V24.0783C31.2572 27.9954 28.039 31.1728 24.1105 31.1701C24.088 31.1701 24.0636 31.1701 24.0379 31.1728C20.1146 31.1359 16.9294 27.9237 16.9308 24.0045C16.9308 24.0045 16.9308 24.0011 16.9334 23.9591C16.9624 20.0155 20.1833 16.8408 24.1065 16.8395H24.1105C24.1105 16.8376 24.1137 16.8395 24.1592 16.8395H24.175C28.1116 16.8718 31.2949 20.0854 31.2915 24.0045Z"
        fill="#00AFEC"
      />
      <Path
        d="M61.8519 18.1867H63.113V20.5252C63.9627 19.1631 65.1458 17.8778 67.4892 17.8778C69.7806 17.8778 71.1963 19.1887 71.916 20.7044C72.7657 19.24 74.183 17.8778 76.6534 17.8778C79.7684 17.8778 81.6992 20.0617 81.6992 23.3512V31.2874H80.437V23.5053C80.437 20.6794 78.9712 19.0346 76.5509 19.0346C74.3363 19.0346 72.4067 20.7294 72.4067 23.6583V31.2874H71.145V23.4261C71.145 20.7044 69.6525 19.0346 67.3096 19.0346C64.9664 19.0346 63.113 21.0893 63.113 23.7362V31.2874H61.8519V18.1867Z"
        fill={textColor}
      />
      <Path
        d="M94.8212 18.1867H96.2118L90.5233 31.7515C89.3646 34.4995 88.0271 35.4749 86.1476 35.4749C85.1441 35.4749 84.4226 35.2957 83.5472 34.9106L83.9843 33.8051C84.6797 34.1395 85.2467 34.2937 86.2246 34.2937C87.5883 34.2937 88.4893 33.4968 89.4672 31.2107L83.1609 18.1867H84.6284L90.0867 29.901L94.8212 18.1867Z"
        fill={textColor}
      />
      <Path
        d="M103.949 24.4806V24.4288C103.949 20.473 107.142 17.2625 111.44 17.2625C115.714 17.2625 118.88 20.4212 118.88 24.3783V24.4288C118.88 28.3859 115.688 31.5956 111.39 31.5956C107.116 31.5956 103.949 28.4365 103.949 24.4806ZM115.019 24.4806V24.4288C115.019 22.3991 113.551 20.627 111.39 20.627C109.148 20.627 107.811 22.3486 107.811 24.3783V24.4288C107.811 26.4597 109.278 28.2306 111.44 28.2306C113.68 28.2306 115.019 26.5103 115.019 24.4806Z"
        fill={textColor}
      />
      <Path
        d="M124.076 29.4904V31.2874H120.163V12.5355H124.076V19.4964C125.029 18.2129 126.342 17.2625 128.376 17.2625C131.593 17.2625 134.657 19.7796 134.657 24.3783V24.4288C134.657 29.0274 131.645 31.5451 128.376 31.5451C126.29 31.5451 125.002 30.593 124.076 29.4904ZM130.744 24.4288V24.3783C130.744 22.0914 129.2 20.5759 127.371 20.5759C125.543 20.5759 124.025 22.0914 124.025 24.3783V24.4288C124.025 26.7151 125.543 28.2306 127.371 28.2306C129.2 28.2306 130.744 26.7411 130.744 24.4288Z"
        fill={textColor}
      />
      <Path
        d="M136.715 33.4462L137.461 32.4181C139.032 33.6253 140.834 34.268 142.765 34.268C145.777 34.268 147.912 32.5466 147.912 29.1815V27.2803C146.781 28.8995 145.029 30.3121 142.429 30.3121C139.264 30.3121 136.123 27.8968 136.123 24.1467V24.0944C136.123 20.3193 139.264 17.8778 142.429 17.8778C145.056 17.8778 146.832 19.2656 147.912 20.8073V18.1867H149.174V29.2582C149.174 31.1594 148.557 32.6495 147.501 33.7016C146.369 34.8327 144.67 35.4492 142.739 35.4492C140.552 35.4492 138.517 34.7809 136.715 33.4462ZM147.99 24.12V24.07C147.99 21.0388 145.286 19.0846 142.558 19.0846C139.803 19.0846 137.488 20.9864 137.488 24.0438V24.0944C137.488 27.0494 139.856 29.1041 142.558 29.1041C145.286 29.1041 147.99 27.1 147.99 24.12Z"
        fill={textColor}
      />
      <Path
        d="M152.08 12.5355H153.341V31.2874H152.08V12.5355Z"
        fill={textColor}
      />
      <Path
        d="M155.684 27.4856V27.4351C155.684 24.7126 158.025 23.1709 161.424 23.1709C163.251 23.1709 164.513 23.4017 165.773 23.7362V23.1709C165.773 20.5253 164.152 19.1631 161.449 19.1631C159.853 19.1631 158.541 19.6005 157.33 20.217L156.866 19.1108C158.308 18.4437 159.724 17.9809 161.526 17.9809C163.302 17.9809 164.719 18.4699 165.671 19.4197C166.545 20.2932 167.009 21.5 167.009 23.1185V31.2874H165.773V29.1041C164.873 30.3633 163.251 31.5956 160.754 31.5956C158.308 31.5956 155.684 30.2092 155.684 27.4856ZM165.8 26.3812V24.8918C164.692 24.6085 163.225 24.3003 161.347 24.3003C158.567 24.3003 157.021 25.5333 157.021 27.3576V27.4089C157.021 29.3107 158.824 30.4138 160.832 30.4138C163.509 30.4138 165.8 28.7704 165.8 26.3812Z"
        fill={textColor}
      />
      <Path
        d="M169.373 29.6456L170.12 28.6419C171.562 29.7468 173.159 30.3633 174.832 30.3633C176.608 30.3633 177.999 29.3874 177.999 27.8718V27.82C177.999 26.2783 176.351 25.7136 174.522 25.2C172.386 24.5835 170.017 23.9409 170.017 21.6035V21.5517C170.017 19.4453 171.794 17.9297 174.343 17.9297C175.912 17.9297 177.689 18.4949 179.002 19.3685L178.332 20.4212C177.122 19.6255 175.68 19.1108 174.292 19.1108C172.489 19.1108 171.305 20.0879 171.305 21.3982V21.45C171.305 22.9138 173.081 23.4529 174.961 23.992C177.071 24.5835 179.285 25.3529 179.285 27.6665V27.7177C179.285 30.0551 177.277 31.5451 174.755 31.5451C172.849 31.5451 170.764 30.7727 169.373 29.6456Z"
        fill={textColor}
      />
      <Path
        d="M180.621 29.6456L181.367 28.6419C182.808 29.7468 184.405 30.3633 186.079 30.3633C187.855 30.3633 189.245 29.3874 189.245 27.8718V27.82C189.245 26.2783 187.598 25.7136 185.768 25.2C183.632 24.5835 181.265 23.9409 181.265 21.6035V21.5517C181.265 19.4453 183.04 17.9297 185.589 17.9297C187.16 17.9297 188.935 18.4949 190.248 19.3685L189.579 20.4212C188.369 19.6255 186.927 19.1108 185.539 19.1108C183.736 19.1108 182.551 20.0879 182.551 21.3982V21.45C182.551 22.9138 184.328 23.4529 186.207 23.992C188.317 24.5835 190.531 25.3529 190.531 27.6665V27.7177C190.531 30.0551 188.524 31.5451 186.002 31.5451C184.095 31.5451 182.011 30.7727 180.621 29.6456Z"
        fill={textColor}
      />
      <Path
        d="M192.099 24.7632V24.7126C192.099 20.9097 194.777 17.8778 198.329 17.8778C202.011 17.8778 204.352 20.8585 204.352 24.7376C204.352 24.9692 204.352 25.0459 204.327 25.2512H193.438C193.67 28.5132 196.012 30.4138 198.586 30.4138C200.619 30.4138 202.011 29.5165 203.091 28.3591L203.966 29.1297C202.627 30.5686 201.057 31.5956 198.535 31.5956C195.085 31.5956 192.099 28.8477 192.099 24.7632ZM202.987 24.12C202.808 21.475 201.289 19.0346 198.278 19.0346C195.678 19.0346 193.67 21.2185 193.438 24.12H202.987Z"
        fill={textColor}
      />
      <Path
        d="M205.353 29.6456L206.101 28.6419C207.542 29.7468 209.138 30.3633 210.811 30.3633C212.587 30.3633 213.977 29.3874 213.977 27.8718V27.82C213.977 26.2783 212.33 25.7136 210.503 25.2C208.366 24.5835 205.997 23.9409 205.997 21.6035V21.5517C205.997 19.4453 207.774 17.9297 210.322 17.9297C211.893 17.9297 213.669 18.4949 214.981 19.3685L214.311 20.4212C213.102 19.6255 211.662 19.1108 210.271 19.1108C208.468 19.1108 207.284 20.0879 207.284 21.3982V21.45C207.284 22.9138 209.061 23.4529 210.939 23.992C213.05 24.5835 215.264 25.3529 215.264 27.6665V27.7177C215.264 30.0551 213.258 31.5451 210.734 31.5451C208.829 31.5451 206.743 30.7727 205.353 29.6456Z"
        fill={textColor}
      />
      <Path
        d="M100.535 18.2367C99.8133 18.2367 99.1425 18.0221 98.5714 17.6644V30.7495C98.5714 31.9051 98.0044 32.3412 97.1804 32.3412C96.8983 32.3412 96.6669 32.3156 96.3568 32.2633V35.3213C96.8983 35.4242 97.336 35.4749 98.0575 35.4749C100.94 35.4749 102.484 34.0883 102.484 31.0577V17.6749C101.916 18.0245 101.252 18.2367 100.535 18.2367Z"
        fill={textColor}
      />
      <Path
        d="M102.484 14.5198C102.484 15.5951 101.611 16.4646 100.535 16.4646C99.4602 16.4646 98.5872 15.5951 98.5872 14.5198C98.5872 13.4451 99.4602 12.5756 100.535 12.5756C101.611 12.5756 102.484 13.4451 102.484 14.5198Z"
        fill={textColor}
      />
    </Svg>
  );
}
