import React, { useState } from 'react';
import {
  View,
} from 'react-native';
import { useField } from 'formik';
import Text from '../../basic/text/Text';
import tw from '../../tw';
import InputImage from '../../basic/input-image/InputImage';

type InputImageFormProps = {
  fieldName: string;
  label?: string;
  editable?: boolean;
  imageUrl?: string;
}

export default function InputImageForm({
  fieldName,
  label,
  editable = true,
  imageUrl,
}: InputImageFormProps) {
  const [image, setImage] = useState(imageUrl ?? null);
  const [field, meta, helper] = useField(fieldName);

  function pickImage(uri: string) {
    setImage(uri);
    field.onChange(field.name);
    helper.setValue(image, true);
  }

  return (
    <View>
      <Text
        variant="pretitle"
        style={tw.style(
          'mb-1',
          {
            'text-dark dark:text-grey-500': editable,
            'text-grey-200 dark:text-grey-800': !editable,
          },
        )}
      >
        {label}
      </Text>
      <InputImage
        imageUrl={image}
        pickImage={(uri) => pickImage(uri)}
      />
      {meta.error && meta.touched && (
        <Text
          variant="small"
          style={tw.style('mt-2 text-danger-500')}
        >
          {meta.error}
        </Text>
      )}
    </View>
  );
}
