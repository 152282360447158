import { defineMessages } from 'react-intl';

export const toastrTranslations = defineMessages({
  failedToCreateProgramParticipant: {
    id: 'AddProgramParticipantForm.toastr.failedToCreateProgramParticipant',
    defaultMessage: 'Echec de l\'ajout du participant',
  },
});

export const errorTranslations = defineMessages({
  emailRequired: {
    id: 'AddProgramParticipantForm.error.emailRequired',
    defaultMessage: 'Email requis',
  },
});

export default defineMessages({
  formLabelEmail: {
    id: 'AddProgramParticipantForm.formLabelEmail',
    defaultMessage: 'Email',
  },
  formButtonSubmit: {
    id: 'AddProgramParticipantForm.formButtonSubmit',
    defaultMessage: 'Ajouter',
  },
});
