import React from 'react';
import * as ImagePicker from 'expo-image-picker';
import {
  Image,
  Platform,
  Pressable,
} from 'react-native';
import tw from '../../tw';

type InputImageProps = {
  imageUrl: string;
  pickImage: (uri: string) => void;
  editable?: boolean;
}
export default function InputImage({
  imageUrl,
  pickImage,
  editable = true,
}: InputImageProps) {
  async function onPress() {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: editable,
      aspect: [4, 3],
      quality: 1,
      uri: Platform.OS === 'android' ? 'file://' : '',
    });

    if (!result.cancelled) {
      const { uri } = result as ImagePicker.ImageInfo;
      pickImage(uri);
    }
  }

  return (
    <Pressable
      onPress={() => onPress()}
      style={tw.style('relative')}
    >
      <Image
        source={{ uri: imageUrl || 'https://via.placeholder.com/150' }}
        style={tw.style('w-32 h-32 content-center mb-4 rounded-md border border-primary-200')}
      />
    </Pressable>
  );
}
