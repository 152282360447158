import { useIntl } from 'react-intl';
import Toast from 'react-native-toast-message';
import { useAcceptConsentMutation } from 'types/graphql';
import commonErrorsTranslations from 'shared/translations/errors.translations';
import { AcceptConsentParams } from '../@types';
import { UseSuccessAcceptConsentParams } from './useSuccessAcceptConsent';

export default function useAcceptConsent({
  consentId,
  onSuccess,
}: {
  consentId: string
  onSuccess: (values: UseSuccessAcceptConsentParams) => void;
}) {
  const intl = useIntl();
  const [acceptConsent] = useAcceptConsentMutation();

  return async function submitConsent(value: AcceptConsentParams) {
    return acceptConsent({
      variables: {
        consentId,
        userId: value.userId,
      },
    })
      .then(() => {
        onSuccess({
          email: value.email,
        });
      })
      .catch(() => {
        Toast.show({
          type: 'error',
          text1: intl.formatMessage(commonErrorsTranslations.technical),
        });
      });
  };
}
