import { MaterialIcons } from '@expo/vector-icons';
import React, {
  useRef,
  useState,
} from 'react';
import {
  Appearance,
  View,
} from 'react-native';
import { useField } from 'formik';
import PhoneInput from 'react-native-phone-number-input';
import { Style } from 'twrnc/dist/esm/types';
import Text from '../../basic/text/Text';
import tw from '../../tw';

type InputPhoneFormProps = {
  label: string;
  name: string;
  style?: Style
}
export default function InputPhoneForm({
  label,
  name,
  style,
}: InputPhoneFormProps) {
  const phoneInput = useRef(null);
  const [value] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const [field, meta, helper] = useField(name);
  const colorScheme = Appearance.getColorScheme();

  return (
    <View
      style={tw.style(style)}
    >
      <Text
        variant="pretitle"
        style={tw.style('mb-1 text-dark dark:text-grey-500')}
      >
        {label}
      </Text>
      <PhoneInput
        ref={phoneInput}
        defaultValue={value}
        defaultCode="FR"
        layout="first"
        placeholder={label}
        onChangeText={(text) => {
          field.onChange(text);
        }}
        onChangeFormattedText={(text) => {
          field.onChange(text);
          helper.setValue(text, true);
        }}
        containerStyle={tw.style(
          'h-10 border border-grey-500 rounded-md bg-light w-full text-dark mb-1',
          'dark:bg-dark dark:text-light',
          {
            'border-2 border-primary-500': isFocus,
            'border-danger-500': meta.error && meta.touched,
          },
        )}
        textContainerStyle={tw.style(
          'pt-1 pb-1 bg-light rounded-md text-dark',
          'dark:bg-dark dark:text-light',
        )}
        textInputStyle={tw.style('text-dark dark:text-light')}
        codeTextStyle={tw.style('text-dark dark:text-light')}
        renderDropdownImage={(
          <MaterialIcons
            style={tw.style('self-center text-grey-500')}
            size={24}
            name="keyboard-arrow-down"
          />
        )}
        withDarkTheme={colorScheme === 'dark'}
        textInputProps={{
          onBlur: () => setIsFocus(false),
          onFocus: () => setIsFocus(true),
        }}
      />
      {meta.error && meta.touched && (
        <Text
          variant="small"
          style={tw.style('text-danger-500')}
        >
          {meta.error}
        </Text>
      )}
    </View>
  );
}
