import Screens from '../../../screens';
import translations from './translations';

export default [
  {
    title: translations.dashboard,
    route: Screens.dashboard,
  },
  {
    title: translations.security,
    route: Screens.security,
  },
  {
    title: translations.notifications,
    route: Screens.notifications,
  },
  {
    title: translations.myCommunities,
    route: Screens.myCommunities,
  },

];
