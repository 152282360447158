import { createDrawerNavigator } from '@react-navigation/drawer';
import React from 'react';
import Screens from '../../../screens';
import DashboardScreen from '../../../screens/dashboard/DashboardScreen';
import SecurityScreen from '../../../screens/settings/security/SecurityScreen';
import NotificationsScreen from '../../../screens/settings/notifications/NotificationsScreen';
import MainDrawerContent from './MainDrawerContent';

export default function MainDrawerScreen() {
  const MainDrawer = createDrawerNavigator();

  return (
    <MainDrawer.Navigator
      id="MainDrawer"
      initialRouteName={Screens.dashboard}
      drawerContent={MainDrawerContent}
      screenOptions={{
        drawerPosition: 'left',
        headerTitle: '',
      }}
    >
      <MainDrawer.Screen
        name={Screens.dashboard}
        component={DashboardScreen}
      />
      <MainDrawer.Screen
        name={Screens.security}
        component={SecurityScreen}
      />
      <MainDrawer.Screen
        name={Screens.notifications}
        component={NotificationsScreen}
      />
    </MainDrawer.Navigator>
  );
}
