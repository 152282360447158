import { defineMessages } from 'react-intl';

export default defineMessages({
  backButton: {
    id: 'Navigation.backButton',
    defaultMessage: 'Retour',
  },
  openModificationModal: {
    id: 'Navigation.openModificationModal',
    defaultMessage: 'Modifier',
  },
});
