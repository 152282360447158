import { defineMessages } from 'react-intl';
import { CreateUserProfileErrors } from 'types/graphql-errors';

export const errorTranslations = defineMessages({
  [CreateUserProfileErrors.USER_PROFILE_FIRST_NAME_BLANK]: {
    id: 'CreateProfileForm.error.userProfileFirstNameBlank',
    defaultMessage: 'Vous devez saisir votre prénom',
  },
  [CreateUserProfileErrors.USER_PROFILE_LAST_NAME_BLANK]: {
    id: 'CreateProfileForm.error.userProfileLastNameBlank',
    defaultMessage: 'Vous devez saisir votre nom',
  },
  [CreateUserProfileErrors.USER_PROFILE_PHONE_NUMBER_TAKEN]: {
    id: 'CreateProfileForm.error.userProfilePhoneNumberTaken',
    defaultMessage: 'Ce numéro de téléphone est déjà utilisé',
  },
  [CreateUserProfileErrors.USER_PROFILE_PHONE_NUMBER_INVALID]: {
    id: 'CreateProfileForm.error.userProfilePhoneNumber',
    defaultMessage: 'Le numéro de téléphone doit être au format international',
  },
  [CreateUserProfileErrors.USER_PROFILE_USER_ALREADY_HAVE_A_USER_PROFILE]: {
    id: 'CreateProfileForm.error.userProfileUserAlreadyHaveAUserProfile',
    defaultMessage: 'Ce compte est déjà lié à un profil',
  },
  [CreateUserProfileErrors.USER_PROFILE_USER_BLANK]: {
    id: 'CreateProfileForm.error.userProfileUserBlank',
    defaultMessage: 'Votre profil n\' a pu être créé',
  },
});

export default defineMessages({
  title: {
    id: 'CreateProfileScreen.title',
    defaultMessage: 'Création du compte',
  },
  subtitle: {
    id: 'CreateProfileScreen.subtitle',
    defaultMessage: 'Création du profil',
  },
  description: {
    id: 'CreateProfileScreen.description',
    defaultMessage: 'Indiquez quelques informations pour personnaliser votre expérience.',
  },
  firstNameLabel: {
    id: 'CreateProfileForm.firstNameLabel',
    defaultMessage: 'Prénom',
  },
  lastNameLabel: {
    id: 'CreateProfileForm.lastNameLabel',
    defaultMessage: 'Nom',
  },
  phoneLabel: {
    id: 'CreateProfileForm.phoneLabel',
    defaultMessage: 'Téléphone',
  },
  submitButton: {
    id: 'CreateProfileForm.submitButton',
    defaultMessage: 'Créer mon compte',
  },
});
