enum Screen {
  // Authentification
  public = 'Public',
  register = 'Register',
  registerSuccess = 'RegisterSuccess',
  login = 'Login',
  askPassword = 'AskPassword',
  updatePassword = 'UpdatePassword',
  createPassword = 'CreatePassword',
  checkRegistrationToken = 'CheckRegistrationToken',
  createProfile = 'CreateProfile',

  // Home
  dashboard = 'Dashboard',
  mainDrawerScreen = 'MainDrawerScreen',
  security = 'Security',
  notifications = 'Notifications',

  // Community
  myCommunities = 'MyCommunities',
  showCommunity = 'ShowCommunity',
  createCommunity = 'CreateCommunity',
  updateCommunity = 'UpdateCommunity',
  addCommunityMember = 'AddCommunityMember',
  communityProgramsList = 'CommunityProgramsList',

  // Program
  showProgram = 'ShowProgram',
  createProgram = 'CreateProgram',
  updateProgram = 'UpdateProgram',
  communityMembersList = 'CommunityMembersList',
  programParticipantsList = 'ProgramParticipantsList',
  programOwnersList = 'ProgramOwnersList',
  addProgramParticipant = 'AddProgramParticipant',
  addProgramOwner = 'AddProgramOwner',
}

export default Screen;
