import { MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import { Pressable } from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import tw from '../../tw';

type ButtonIconProps = {
  icon: keyof typeof MaterialIcons.glyphMap;
  action: (value?: any) => void;
  style?: Style;
}
export default function ButtonIcon({
  icon,
  action,
  style,
}: ButtonIconProps) {
  return (
    <Pressable
      onPress={() => {
        action();
      }}
      style={tw.style(
        'rounded-full w-8 h-8 bg-primary-500 justify-center items-center',
        style,
      )}
      accessibilityRole="button"
    >
      <MaterialIcons
        name={icon}
        size={20}
        style={tw.style('text-light')}
      />
    </Pressable>
  );
}
