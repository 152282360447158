import { defineMessages } from 'react-intl';
import { ProgramPublicationStatus } from 'types/graphql';

export default defineMessages({
  [ProgramPublicationStatus.Archived]: {
    id: 'Program.archivedStatus',
    defaultMessage: 'Archivé',
  },
  [ProgramPublicationStatus.Published]: {
    id: 'Program.publishedStatus',
    defaultMessage: 'Publié',
  },
  [ProgramPublicationStatus.Draft]: {
    id: 'Program.draftStatus',
    defaultMessage: 'Brouillon',
  },
});
