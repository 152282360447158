import { useNavigation } from '@react-navigation/native';
import { ScreenNavigationProp } from 'types/navigation';
import Screens from '../../../../screens';

export default function useSuccessAskPassword() {
  const { navigate } = useNavigation<ScreenNavigationProp<Screens.login>>();

  return ({
    email,
  }: {
    email: string,
  }) => {
    navigate(Screens.login, {
      email,
    });
  };
}
