import { useAutoLoginQuery } from 'types/graphql';
import { useAuthorizationContext } from 'providers/authorization/AuthorizationContext';
import { AUTO_LOGIN } from '../gql/autoLogin.gql';

export default function usePublicScreen() {
  const { setAuthenticated, setUserProfileCompleted } = useAuthorizationContext();

  /**
   * Automatically redirect to the authenticated stack if the user is logged in.
   */
  const { loading } = useAutoLoginQuery({
    query: AUTO_LOGIN,
    fetchPolicy: 'network-only',
    onCompleted: ({ me }) => {
      setUserProfileCompleted(me.userProfile !== null);
      setAuthenticated(true);
    },
  });

  return {
    loading,
  };
}
