import React from 'react';
import { useIntl } from 'react-intl';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useAuthorizationContext } from 'providers/authorization/AuthorizationContext';
import { RootStackParamList } from 'types/navigation';
import navigationTranslations from 'shared/translations/navigation.translations';
import tw from 'design-system';
import Screens from '../../screens';
import CreateProfileScreen from '../../screens/user/create-profile/CreateProfileScreen';
import ProgramGroupStack from '../../screens/program/ProgramGroupStack';
import CommunityGroupStack from '../../screens/community/CommunityGroupStack';
import HeaderBackButton from '../HeaderBackButton';
import MainDrawerScreen from './drawer/MainDrawerScreen';

export default function Authenticated() {
  const intl = useIntl();
  const AuthenticatedStackNavigator = createNativeStackNavigator<RootStackParamList>();
  const { isUserProfileCompleted } = useAuthorizationContext();

  const screenOptions = {
    headerBackTitleVisible: true,
    headerBackTitle: intl.formatMessage(navigationTranslations.backButton),
    headerTintColor: tw.color('black'),
    headerShadowVisible: false,
    title: '',
    headerLeft: HeaderBackButton,
  };

  return (
    <AuthenticatedStackNavigator.Navigator
      screenOptions={screenOptions}
    >
      {
        isUserProfileCompleted
          ? (
            <>
              <AuthenticatedStackNavigator.Screen
                name={Screens.mainDrawerScreen}
                component={MainDrawerScreen}
                options={{ headerShown: false }}
              />
              {CommunityGroupStack()}
              {ProgramGroupStack()}
            </>
          )
          : (
            <AuthenticatedStackNavigator.Screen
              name={Screens.createProfile}
              component={CreateProfileScreen}
              options={{
                headerShown: false,
              }}
            />
          )
      }
    </AuthenticatedStackNavigator.Navigator>
  );
}
