import React from 'react';
import { useIntl } from 'react-intl';
import tw, {
  Text,
  ScreenAreaView,
  ScreenContainer,
} from 'design-system';
import KeyboardDisplayManager from '../../../design-system/basic/keyboard-display-manager/KeyboardDisplayManager';
import UpdatePasswordForm from './update-password-form/UpdatePasswordForm';
import translations from './translations';

export default function UpdatePasswordScreen() {
  const intl = useIntl();

  return (
    <ScreenAreaView edges={['bottom']}>
      <ScreenContainer>
        <KeyboardDisplayManager>
          <Text
            variant="header1"
            style={tw.style('mb-4')}
          >
            {intl.formatMessage(translations.title)}
          </Text>
          <Text
            variant="subtitle"
            style={tw.style('mb-4')}
          >
            {intl.formatMessage(translations.subtitle)}
          </Text>
          <Text
            style={tw.style('mb-6')}
          >
            {intl.formatMessage(translations.description)}
          </Text>
          <UpdatePasswordForm />
        </KeyboardDisplayManager>
      </ScreenContainer>
    </ScreenAreaView>
  );
}
