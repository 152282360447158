import React from 'react';
import { View } from 'react-native';
import { useIntl } from 'react-intl';
import tw, {
  ScreenContainer,
  Text,
} from 'design-system';
import CreateCommunityForm from './create-community-form/CreateCommunityForm';
import translations from './translations';

export default function CreateCommunity() {
  const intl = useIntl();

  return (
    <ScreenContainer>
      <View
        style={tw.style('flex-1 flex-col')}
      >
        <Text
          variant="header2"
          style={tw.style('mb-8')}
        >
          {intl.formatMessage(translations.title)}
        </Text>
        <CreateCommunityForm />
      </View>
    </ScreenContainer>
  );
}
