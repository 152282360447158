import { defineMessages } from 'react-intl';

export default defineMessages({
  programs: {
    id: 'ShowCommunity.programs',
    defaultMessage: 'Programmes',
  },
  members: {
    id: 'ShowCommunity.members',
    defaultMessage: 'Membres',
  },
  noMembers: {
    id: 'ShowCommunity.noMembers',
    defaultMessage: 'Pas de membres',
  },
  noPrograms: {
    id: 'ShowCommunity.noPrograms',
    defaultMessage: 'Pas de programmes',
  },
  programsListLink: {
    id: 'ShowCommunity.programsListLink',
    defaultMessage: 'Voir la liste des programmes',
  },
  membersListLink: {
    id: 'ShowCommunity.membersListLink',
    defaultMessage: 'Voir les membres',
  },
});
