import { MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import { View } from 'react-native';
import tw from '../../tw';
import ScreenAreaView from '../../basic/screen-area-view/ScreenAreaView';
import ScreenContainer from '../../basic/screen-container/ScreenContainer';

export default function ErrorScreen({
  children,
}: {
  children?: React.ReactNode,
}) {
  return (
    <ScreenAreaView edges={['bottom']}>
      <ScreenContainer>
        <View
          style={tw.style('flex-1 flex-col justify-center items-center')}
        >
          {children ?? (
            <MaterialIcons
              style={tw.style('self-center text-dark dark:text-light')}
              name="error"
              size={100}
            />
          )}
        </View>
      </ScreenContainer>
    </ScreenAreaView>
  );
}
