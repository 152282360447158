import React from 'react';
import { View } from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import tw from '../../tw';

export default function ScreenContainer({
  children,
  style,
}: {
  children: React.ReactNode,
  style?: Style
}) {
  return (
    <View
      style={tw.style('bg-light dark:bg-dark flex-1 p-4 md:p-6 lg:p-8 w-full max-w-screen-xl m-auto', style)}
    >
      {children}
    </View>
  );
}
