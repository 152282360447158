import React, { useMemo } from 'react';
import { View } from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import Text from '../text/Text';
import tw from '../../tw';

type VariantType = 'extrasmall' | 'small' | 'medium' | 'large' | 'extralarge';

type AvatarProps = {
  profile: {
    firstName?: string
    lastName?: string
  }
  variant?: VariantType
  style?: Style
}

export default function Avatar({
  profile,
  variant = 'medium',
  style,
}: AvatarProps) {
  const colors = useMemo(() => [
    'bg-primary-700 dark:bg-primary-300',
    'bg-secondary-700 dark:bg-secondary-300',
    'bg-tertiary-700 dark:bg-tertiary-300',
    'bg-success-700 dark:bg-success-300',
    'bg-warning-700 dark:bg-warning-300',
  ], []);
  const avatarColor = useMemo(() => colors[Math.floor(Math.random() * colors.length)], [colors]);

  return (
    <View
      style={tw.style(
        'justify-center items-center rounded-full',
        avatarColor,
        {
          'h-6 w-6': variant === 'extrasmall',
          'h-8 w-8': variant === 'small',
          'h-12 w-12': variant === 'medium',
          'h-16 w-16': variant === 'large',
          'h-24 w-24': variant === 'extralarge',
        },
        style,
      )}
    >
      <Text
        style={tw.style({
          'text-white': true,
          'text-xs': variant === ('extrasmall' || 'small'),
          'text-base': variant === 'medium',
          'text-xl': variant === 'large',
          'text-3xl': variant === 'extralarge',
        })}
      >
        {profile?.firstName ? profile.firstName.charAt(0).toUpperCase() : 'U'}
        {profile?.lastName ? profile.lastName.charAt(0).toUpperCase() : 'U'}
      </Text>
    </View>
  );
}
