import {
  StackActions,
  useNavigation,
} from '@react-navigation/native';
import { useIntl } from 'react-intl';
import Toast from 'react-native-toast-message';
import { useCreateCommunityMemberInvitationMutation } from 'types/graphql';
import { toastrTranslations } from '../translations';

export default function useAddCommunityMember() {
  const intl = useIntl();
  const { dispatch } = useNavigation();

  const [CreateCommunityMemberInvitationMutation] = useCreateCommunityMemberInvitationMutation({
    refetchQueries: ['community'],
    onCompleted: () => dispatch(StackActions.pop()),
    onError: () => {
      Toast.show({
        type: 'error',
        text1: intl.formatMessage(toastrTranslations.failedToCreateCommunityMember),
      });
    },
  });

  return {
    CreateCommunityMemberInvitationMutation,
  };
}
