import { defineMessages } from 'react-intl';

export const errorTranslations = defineMessages({
  titleRequired: {
    id: 'CreateProgramForm.required.titleRequired',
    defaultMessage: 'Titre Requis',
  },
  descriptionRequired: {
    id: 'CreateProgramForm.required.descriptionRequired',
    defaultMessage: 'Description requise',
  },
});

export const toastrTranslations = defineMessages({
  failure: {
    id: 'CreateProgramForm.toastr.failure',
    defaultMessage: 'Échec de la création du programme',
  },
});

export default defineMessages({
  title: {
    id: 'CreateProgram.title',
    defaultMessage: 'Créer un programme',
  },
  formLabelTitle: {
    id: 'CreateProgram.formLabelTitle',
    defaultMessage: 'Titre de programme',
  },
  formLabelDescription: {
    id: 'CreateProgram.formLabelDescription',
    defaultMessage: 'Description de programme',
  },
  formButtonSubmit: {
    id: 'CreateProgram.formButtonSubmit',
    defaultMessage: 'Créer',
  },
  imageUploadLabel: {
    id: 'CreateProgram.imageUploadLabel',
    defaultMessage: 'Logo',
  },
});
