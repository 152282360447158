import React from 'react';
import { View } from 'react-native';
import { useIntl } from 'react-intl';
import { useMeQuery } from 'types/graphql';
import tw, {
  Text,
  ScreenAreaView,
  ScreenContainer,
  Loader,
  ProgressBar,
  KeyboardDisplayManager,
  ErrorScreen,
} from 'design-system';
import CreateProfileForm from './create-profile-form/CreateProfileForm';
import translations from './translations';

export default function CreateProfileScreen() {
  const intl = useIntl();
  const { loading, data, error } = useMeQuery();

  if (loading) return <Loader />;

  if (error) return <ErrorScreen />;

  return (
    <ScreenAreaView edges={['top', 'bottom']}>
      <ScreenContainer>
        <KeyboardDisplayManager>
          <Text
            variant="header1"
            style={tw.style('mb-4')}
          >
            {intl.formatMessage(translations.title)}
          </Text>
          <View
            style={tw.style('mb-4')}
          >
            <ProgressBar
              progress={100}
            />
          </View>
          <Text
            variant="header3"
            style={tw.style('mb-4')}
          >
            {intl.formatMessage(translations.subtitle)}
          </Text>
          <Text
            style={tw.style('mb-6')}
          >
            {intl.formatMessage(translations.description)}
          </Text>
          <CreateProfileForm userId={data.me.id} />
        </KeyboardDisplayManager>
      </ScreenContainer>
    </ScreenAreaView>
  );
}
