import { useRoute } from '@react-navigation/native';
import React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import tw, {
  Button,
  InputForm,
} from 'design-system';
import { RouteProps } from 'types/navigation';
import Screens from '../../../../screens';
import useAddProgramOwner from './useAddProgramOwner';
import validationSchema from './AddProgramOwnerForm.validation';
import translations from './translations';

type AddProgramOwnerFormikFields = {
  programId: string;
  email: string;
}

export default function AddProgramOwnerForm() {
  const intl = useIntl();
  const { CreateProgramOwnerInvitationMutation } = useAddProgramOwner();
  const { params: { id } } = useRoute<RouteProps<Screens.addProgramOwner>>();
  const initialValues = {
    programId: id,
    email: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange
      validateOnBlur
      validateOnMount
      validationSchema={validationSchema(intl)}
      onSubmit={(
        values: AddProgramOwnerFormikFields,
      ) => CreateProgramOwnerInvitationMutation({
        variables: values,
      })}
    >
      {({
        handleSubmit,
      }) => (
        <>
          <InputForm
            fieldName="email"
            label={intl.formatMessage(translations.formLabelEmail)}
            style={tw.style('w-full mb-4')}
          />
          <Button
            variant="full"
            onPress={() => handleSubmit()}
            style={tw.style('self-center')}
          >
            {intl.formatMessage(translations.formButtonSubmit).toUpperCase()}
          </Button>
        </>
      )}
    </Formik>
  );
}
