import { defineMessages } from 'react-intl';

export default defineMessages({
  dashboard: {
    id: 'LeftNavigationList.dashboard',
    defaultMessage: 'Dashboard',
  },
  security: {
    id: 'SettingsLinkList.security',
    defaultMessage: 'Sécurité',
  },
  notifications: {
    id: 'SettingsLinkList.notifications',
    defaultMessage: 'Notifications',
  },
  myCommunities: {
    id: 'LeftNavigationList.myCommunities',
    defaultMessage: 'Mes communautés',
  },
});
