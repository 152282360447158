import { useRoute } from '@react-navigation/native';
import React from 'react';
import { FlatList } from 'react-native';
import { useIntl } from 'react-intl';
import {
  Card,
  ScreenAreaView,
  ScreenContainer,
  SectionListManager,
  Text,
} from 'design-system';
import { RouteProps } from 'types/navigation';
import { useProgramQuery } from 'types/graphql';
import UserItem from 'shared/components/user-item/UserItem';
import Screens from '../../../screens';
import translations from './translations';

export default function ProgramOwnersList() {
  const intl = useIntl();
  const { params: { id } } = useRoute<RouteProps<Screens.programParticipantsList>>();
  const { data: { program: { propertyInvitations } } } = useProgramQuery({
    variables: {
      id,
    },
  });

  return (
    <ScreenAreaView edges={['bottom']}>
      <ScreenContainer>
        <SectionListManager
          badge
          data={propertyInvitations}
          title={intl.formatMessage(translations.title)}
        >
          <FlatList
            data={propertyInvitations}
            renderItem={({ item }) => (
              <Card key={item.id}>
                <UserItem
                  userProfile={item.user.userProfile}
                  invitationStatus={item.status}
                />
              </Card>
            )}
            keyExtractor={(item) => item.id}
            ListEmptyComponent={() => (
              <Text>
                {intl.formatMessage(translations.noOwners)}
              </Text>
            )}
          />
        </SectionListManager>
      </ScreenContainer>
    </ScreenAreaView>
  );
}
