import {
  StackActions,
  useNavigation,
} from '@react-navigation/native';
import { useIntl } from 'react-intl';
import Toast from 'react-native-toast-message';
import { useCreateCommunityMutation } from 'types/graphql';
import { toastrTranslations } from '../translations';

export default function useCreateCommunity() {
  const intl = useIntl();
  const { dispatch } = useNavigation();

  const [createCommunityMutation] = useCreateCommunityMutation({
    refetchQueries: ['myCommunities'],
    onCompleted: () => dispatch(StackActions.pop()),
    onError: () => {
      Toast.show({
        type: 'error',
        text1: intl.formatMessage(toastrTranslations.failedToCreateCommunity),
      });
    },
  });

  return {
    createCommunityMutation,
  };
}
