import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useCreateUserProfileMutation } from 'types/graphql';
import { CreateUserProfileErrors } from 'types/graphql-errors';
import { useAuthorizationContext } from 'providers/authorization/AuthorizationContext';
import useHandleGraphqlErrors from '../../../../hooks/use-handle-graphql-errors/useHandleGraphqlErrors';
import { errorTranslations } from '../translations';

export type CreateProfileFormikFields = {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

export default function useCreateProfileForm({
  userId,
}: {
  userId: string;
}) {
  const intl = useIntl();
  const { setUserProfileCompleted } = useAuthorizationContext();
  const [createUserProfile] = useCreateUserProfileMutation();
  const handleGraphqlErrors = useHandleGraphqlErrors();

  const errorsMapping = {
    translations: {
      global: {
        [CreateUserProfileErrors.USER_PROFILE_USER_ALREADY_HAVE_A_USER_PROFILE]:
          intl.formatMessage(errorTranslations.USER_PROFILE_USER_ALREADY_HAVE_A_USER_PROFILE),
        [CreateUserProfileErrors.USER_PROFILE_USER_BLANK]:
          intl.formatMessage(errorTranslations.USER_PROFILE_USER_BLANK),
      },
      firstName: {
        [CreateUserProfileErrors.USER_PROFILE_FIRST_NAME_BLANK]:
          intl.formatMessage(errorTranslations.USER_PROFILE_FIRST_NAME_BLANK),
      },
      lastName: {
        [CreateUserProfileErrors.USER_PROFILE_LAST_NAME_BLANK]:
          intl.formatMessage(errorTranslations.USER_PROFILE_LAST_NAME_BLANK),
      },
      phoneNumber: {
        [CreateUserProfileErrors.USER_PROFILE_PHONE_NUMBER_TAKEN]:
          intl.formatMessage(errorTranslations.USER_PROFILE_PHONE_NUMBER_TAKEN),
        [CreateUserProfileErrors.USER_PROFILE_PHONE_NUMBER_INVALID]:
          intl.formatMessage(errorTranslations.USER_PROFILE_PHONE_NUMBER_INVALID),
      },
    },
  };

  return {
    initialValues: {
      userId,
      firstName: '',
      lastName: '',
      phoneNumber: '',
    },
    onSubmit: (
      values: CreateProfileFormikFields,
      formikBag: FormikHelpers<CreateProfileFormikFields>,
    ) => createUserProfile({
      variables: values,
    })
      .then(({ data }) => setUserProfileCompleted(data.createUserProfile !== null))
      .catch((error) => handleGraphqlErrors<
      CreateProfileFormikFields,
      CreateUserProfileErrors
    >(error, formikBag, errorsMapping)),
  };
}
