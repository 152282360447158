import { defineMessages } from 'react-intl';
import { ProgramPublicationStatus } from '../../../types/graphql';

export default defineMessages({
  title: {
    id: 'ShowProgram.title',
    defaultMessage: 'Programme de la communauté',
  },
  participants: {
    id: 'ShowProgram.participants',
    defaultMessage: 'Participants',
  },
  noParticipants: {
    id: 'ShowProgram.noParticipants',
    defaultMessage: 'Pas de participants',
  },
  owners: {
    id: 'ShowProgram.owners',
    defaultMessage: 'Responsables',
  },
  noOwners: {
    id: 'ShowProgram.noOwners',
    defaultMessage: 'Pas de responsable',
  },
  participantsLinkList: {
    id: 'ShowProgram.participantsLinkList',
    defaultMessage: 'Liste des participants',
  },
  ownersLinkList: {
    id: 'ShowProgram.ownersLinkList',
    defaultMessage: 'Liste des responsables',
  },
  [ProgramPublicationStatus.Archived]: {
    id: 'Program.archivedStatus',
    defaultMessage: 'Archivé',
  },
  [ProgramPublicationStatus.Published]: {
    id: 'Program.publishedStatus',
    defaultMessage: 'Publié',
  },
  [ProgramPublicationStatus.Draft]: {
    id: 'Program.draftStatus',
    defaultMessage: 'Brouillon',
  },
});
