import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ProgramOwnersList.title',
    defaultMessage: 'Liste des responsables',
  },
  noOwners: {
    id: 'ProgramOwnersList.noOwners',
    defaultMessage: 'Pas de responsable',
  },
});
