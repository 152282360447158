import React from 'react';
import { View } from 'react-native';
import {
  Formik,
  FormikHelpers,
} from 'formik';
import { useIntl } from 'react-intl';
import tw, {
  Button,
  InputForm,
} from 'design-system';
import { FormikFields } from '../@types';
import useAskPasswordForm from '../hooks/useAskPasswordForm';
import translations from '../translations';
import validationSchema from './AskPasswordForm.validation';

export default function AskPasswordForm() {
  const intl = useIntl();
  const {
    initialValues,
    askPassword,
  } = useAskPasswordForm();

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange
      validateOnBlur
      validateOnMount
      validationSchema={validationSchema(intl)}
      onSubmit={(
        values: FormikFields,
        formikBag: FormikHelpers<FormikFields>,
      ) => askPassword(values, formikBag)}
    >
      {({
        handleSubmit,
      }) => (
        <>
          <InputForm
            fieldName="email"
            label={intl.formatMessage(translations.label)}
            placeholder={intl.formatMessage(translations.label)}
            keyboardType="email-address"
            style={tw.style('mb-4')}
          />
          <View
            style={tw.style('flex-1 justify-end')}
          >
            <Button
              variant="full"
              onPress={() => handleSubmit()}
              style={tw.style('self-center')}
            >
              {intl.formatMessage(translations.submitButton).toUpperCase()}
            </Button>
          </View>
        </>
      )}
    </Formik>
  );
}
