import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { errorTranslations } from '../translations';

export default (intl: IntlShape) => Yup.object().shape({
  email: Yup
    .string()
    .ensure()
    .email(intl.formatMessage(errorTranslations.emailRequired))
    .required(intl.formatMessage(errorTranslations.emailRequired)),
});
