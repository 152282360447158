/* eslint-disable max-len */
import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
  NavigationProp,
  RouteProp,
} from '@react-navigation/native';
import { useIntl } from 'react-intl';
import { Button } from 'design-system';
import { RootStackParamList } from 'types/navigation';
import navigationTranslations from 'shared/translations/navigation.translations';
import Screens from '../../screens';
import MyCommunities from './my-communities/MyCommunities';
import ShowCommunity from './show-community/ShowCommunity';
import CreateCommunity from './create-community/CreateCommunity';
import UpdateCommunity from './update-community/UpdateCommunity';
import AddCommunityMember from './add-community-member/AddCommunityMember';
import CommunityProgramsList from './community-programs-list/CommunityProgramsList';
import CommunityMembersList from './community-members-list/CommunityMembersList';

export default function CommunityGroupStack() {
  const intl = useIntl();
  const CommunityGroupStackNavigator = createNativeStackNavigator();

  return (
    <>
      <CommunityGroupStackNavigator.Group>
        <CommunityGroupStackNavigator.Screen
          name={Screens.myCommunities}
          component={MyCommunities}
        />
        <CommunityGroupStackNavigator.Screen
          name={Screens.showCommunity}
          component={ShowCommunity}
          options={({
            navigation,
            route,
          }: {
            navigation: NavigationProp<RootStackParamList>,
            route: RouteProp<RootStackParamList, Screens.updateCommunity>,
          }) => ({
            headerRight: () => (
              <Button
                variant="outlined"
                onPress={() => navigation.navigate(Screens.updateCommunity, {
                  id: route.params.id,
                })}
              >
                {intl.formatMessage(navigationTranslations.openModificationModal)}
              </Button>
            ),
          })}
        />
        <CommunityGroupStackNavigator.Screen
          name={Screens.communityProgramsList}
          component={CommunityProgramsList}
        />
        <CommunityGroupStackNavigator.Screen
          name={Screens.communityMembersList}
          component={CommunityMembersList}
        />
      </CommunityGroupStackNavigator.Group>
      <CommunityGroupStackNavigator.Group
        screenOptions={() => ({
          presentation: 'modal',
        })}
      >
        <CommunityGroupStackNavigator.Screen
          name={Screens.createCommunity}
          component={CreateCommunity}
        />

        <CommunityGroupStackNavigator.Screen
          name={Screens.updateCommunity}
          component={UpdateCommunity}
        />
        <CommunityGroupStackNavigator.Screen
          name={Screens.addCommunityMember}
          component={AddCommunityMember}
        />
      </CommunityGroupStackNavigator.Group>
    </>
  );
}
