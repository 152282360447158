import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'UpdateCommunity.title',
    defaultMessage: 'Modifier une communauté',
  },
  formLabelTitle: {
    id: 'UpdateCommunity.formLabelTitle',
    defaultMessage: 'Titre de communauté',
  },
  formLabelDescription: {
    id: 'UpdateCommunity.formLabelDescription',
    defaultMessage: 'Description de communauté',
  },
  formButtonSubmit: {
    id: 'UpdateCommunity.formButtonSubmit',
    defaultMessage: 'Mettre à jour',
  },
});

export const errorTranslations = defineMessages({
  titleRequired: {
    id: 'UpdateCommunityForm.required.titleRequired',
    defaultMessage: 'Titre Requis',
  },
  descriptionRequired: {
    id: 'UpdateCommunityForm.required.descriptionRequired',
    defaultMessage: 'Description requise',
  },
});

export const toastrTranslations = defineMessages({
  failedToUpdateCommunity: {
    id: 'CreateCommunityForm.toastr.failedToUpdateCommunity',
    defaultMessage: 'Échec de la mise à jour de la communauté',
  },
  failedToFetchCommunity: {
    id: 'CreateCommunityForm.toastr.failedToFetchCommunity',
    defaultMessage: 'Échec de la récupération de la communauté',
  },
});
