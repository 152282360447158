import { Link } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { useIntl } from 'react-intl';
import tw, {
  Text,
  Card,
  Tag,
} from 'design-system';
import { RootStackParamList } from 'types/navigation';
import { Program } from 'types/graphql';
import programTranslations from 'shared/translations/program.translations';
import Screens from '../../../screens';

type ProgramItemProps = Pick<Program, 'id' | 'title' | 'publicationStatus'>

export default function ProgramItem({
  title,
  id,
  publicationStatus,
}: ProgramItemProps) {
  const intl = useIntl();

  return (
    <Card>
      <Link<RootStackParamList>
        to={{
          screen: Screens.showProgram,
          params: {
            id,
          },
        }}
      >
        <View
          style={tw.style('flex-row w-full items-center')}
        >
          <Text
            variant="body"
          >
            {title}
          </Text>
          <Tag
            color={publicationStatus === 'published' ? 'success' : 'warning'}
            style={tw.style('ml-auto')}
          >
            {intl.formatMessage(programTranslations[publicationStatus])}
          </Tag>
        </View>
      </Link>
    </Card>
  );
}
