import { Link } from '@react-navigation/native';
import React from 'react';
import {
  Image,
  View,
} from 'react-native';
import tw, {
  Text,
  Card,
} from 'design-system';
import { RootStackParamList } from 'types/navigation';
import Screens from '../../../../screens';

export type CommunityItemProps = {
  id: string;
  title: string;
  image?: string;
}

export default function CommunityItem({
  title,
  image = 'https://via.placeholder.com/150',
  id,
}: CommunityItemProps) {
  return (
    <Card>
      <Link<RootStackParamList>
        to={{
          screen: Screens.showCommunity,
          params: {
            id,
          },
        }}
      >

        <View
          style={tw.style('flex flex-row items-center')}
        >
          <Image
            style={
              tw.style('h-24 w-24 rounded-md')
            }
            source={{ uri: image }}
          />
          <Text
            style={tw.style('ml-4 shrink')}
          >
            {title}
          </Text>
        </View>
      </Link>
    </Card>
  );
}
