import { defineMessages } from 'react-intl';

export default defineMessages({
  default: {
    id: 'Errors.default',
    defaultMessage: 'Une erreur est survenue, merci de réessayer',
  },
  network: {
    id: 'Errors.network',
    defaultMessage: 'Oups, il semblerait qu\'on ait perdu le contact !',
  },
  technical: {
    id: 'Errors.technical',
    defaultMessage: 'Oups ! Un problème technique est survenu !',
  },
});
