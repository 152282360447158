import { defineMessages } from 'react-intl';

export const toastrTranslations = defineMessages({
  error: {
    id: 'PublishProgram.toastr.error',
    defaultMessage: 'Publication impossible',
  },
  success: {
    id: 'PublishProgram.toastr.success',
    defaultMessage: 'Publication réussie',
  },
});

export default defineMessages({
  publishButtonText: {
    id: 'PublishProgram.title',
    defaultMessage: 'Publier',
  },
});
