import React, { useEffect } from 'react';
import { Loader } from 'design-system';
import useCheckRegistrationTokenScreen from './hooks/useCheckRegistrationTokenScreen';

export default function CheckRegistrationTokenScreen() {
  const {
    initialValues,
    checkRegistrationToken,
  } = useCheckRegistrationTokenScreen();

  useEffect(() => {
    checkRegistrationToken(initialValues);
  }, [initialValues, checkRegistrationToken]);

  return (
    <Loader />
  );
}
