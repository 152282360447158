/* eslint-disable arrow-body-style */
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import fetch from 'cross-fetch';
import Storage from 'enums/storage';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from 'utils/storage/storage';
import persist from './persist';

const headersKey = ['access-token', 'token-type', 'client', 'expiry', 'uid'];

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const { response: { headers } } = operation.getContext();

    if (headersKey.every(
      (key: string) => Object.keys(headers.map).includes(key),
    )) {
      const authentificationHeader = JSON.stringify({
        'access-token': headers.map['access-token'],
        'token-type': headers.map['token-type'],
        client: headers.map.client,
        expiry: headers.map.expiry,
        uid: headers.map.uid,
      });

      setLocalStorageItem(Storage.SESSION, authentificationHeader);
    }

    return response;
  });
});

const setAuthorizationLink = setContext(async (request) => {
  const headerStringify = await getLocalStorageItem(Storage.SESSION);

  if (headerStringify) {
    const headers = JSON.parse(headerStringify);
    return {
      headers,
    };
  }

  return request;
});

const httpLink = new BatchHttpLink({
  uri: `${process.env.REACT_APP_ODYSSEY_BACKEND_URL}/graphql`,
  fetch,
});

const cache = new InMemoryCache();

persist(cache);

const client = new ApolloClient({
  connectToDevTools: process.env.NODE_ENV === 'development',
  cache,
  link: ApolloLink.from([
    afterwareLink,
    setAuthorizationLink,
    httpLink,
  ]),
});

export default client;
