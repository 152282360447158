import Storage from '../../enums/storage';

export function getLocalStorageItem(
  key: Storage,
  defaultValue: string | undefined,
): Promise<string | undefined> {
  return new Promise((resolve) => {
    try {
      const item = window.localStorage.getItem(key) || defaultValue;
      resolve(item);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      resolve(defaultValue);
    }
  });
}

export function setLocalStorageItem(key: Storage, value: string): Promise<void> {
  return new Promise((resolve) => {
    try {
      window.localStorage.setItem(key, value);
      resolve();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      resolve();
    }
  });
}

export function removeLocalStorageItem(key: Storage): Promise<void> {
  return new Promise((resolve) => {
    try {
      window.localStorage.removeItem(key);
      resolve();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      resolve();
    }
  });
}
