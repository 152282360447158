import { useState } from 'react';
import { useIntl } from 'react-intl';
import translations from './translations';

export default function useProgramActions() {
  const intl = useIntl();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const actionsSheetOptions = {
    [intl.formatMessage(translations.deleteProgramTitle)]: () => setIsDeleteModalVisible(true),
  };
  return (
    {
      actionsSheetOptions,
      isDeleteModalVisible,
      setIsDeleteModalVisible,
    }
  );
}
