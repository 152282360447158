import React from 'react';
import { ActivityIndicator as RNActivityIndicator } from 'react-native';
import tw from '../../tw';

type ActivityIndicatorProps = {
  size?: 'small' | 'large';
}
export default function ActivityIndicator({
  size = 'large',
}: ActivityIndicatorProps) {
  return (
    <RNActivityIndicator
      size={size}
      color={tw.color('primary-500')}
    />
  );
}
