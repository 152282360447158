import { useField } from 'formik';
import React from 'react';
import { View } from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import Switch from '../../basic/switch/Switch';
import Text from '../../basic/text/Text';
import tw from '../../tw';

type SwitchFormProps = {
  children: React.ReactNode;
  fieldName: string;
  textHelper?: string;
  disabled?: boolean;
  style?: Style;
}
export default function SwitchForm({
  children,
  fieldName,
  textHelper,
  disabled = false,
  style,
}: SwitchFormProps) {
  const [field, meta, helper] = useField(fieldName);
  const isValid = !(meta.error && meta.touched);

  return (
    <View
      style={tw.style(style)}
    >
      <View style={tw.style('flex-row items-center')}>
        <Switch
          value={field.value}
          onValueChange={helper.setValue}
          disabled={disabled}
        />
        {children}
      </View>
      {textHelper && isValid && (
        <Text
          variant="small"
          style={tw.style('mt-2', {
            'text-grey-500': !disabled,
            'text-grey-200 dark:text-grey-800': disabled,
          })}
        >
          {textHelper}
        </Text>
      )}
      {meta.error && meta.touched && (
        <Text
          variant="small"
          style={tw.style('text-danger-500 mt-2')}
        >
          {meta.error}
        </Text>
      )}
    </View>
  );
}
