import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ProgramParticipantsList.title',
    defaultMessage: 'Liste des participants',
  },
  noParticipants: {
    id: 'ProgramParticipantsList.noParticipants',
    defaultMessage: 'Pas de participant',
  },
});
