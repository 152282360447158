import { defineMessages } from 'react-intl';
import { UpdateUserPasswordErrors } from 'types/graphql-errors';

export const errorTranslations = defineMessages({
  passwordRequired: {
    id: 'UpdatePasswordForm.required.password',
    defaultMessage: 'Veuillez saisir un mot de passe valide',
  },
  updatedPasswordRequired: {
    id: 'UpdatePasswordForm.required.password.new',
    defaultMessage: 'Veuillez saisir un nouveau mot de passe',
  },
  minimumPasswordStrength: {
    id: 'UpdatePasswordForm.required.minimumPasswordStrength',
    defaultMessage: 'Votre mot de passe doit faire au minimum 8 caractères',
  },
  [UpdateUserPasswordErrors.NOT_FOUND]: {
    id: 'UpdatePasswordForm.error.notFound',
    defaultMessage: 'Utilisateur introuvable',
  },
  [UpdateUserPasswordErrors.USER_PASSWORD_BLANK]: {
    id: 'UpdatePasswordForm.error.passwordBlank',
    defaultMessage: 'Vous devez saisir un mot de passe',
  },
  [UpdateUserPasswordErrors.USER_PASSWORD_NOT_STRONG_ENOUGH]: {
    id: 'UpdatePasswordForm.error.userPasswordNotStrongEnough',
    defaultMessage: 'Votre mot de passe doit contenir au moins 8 caractères',
  },
  [UpdateUserPasswordErrors.USER_PASSWORD_TOKEN_EXPIRED]: {
    id: 'UpdatePasswordForm.error.userPasswordTokenExpired',
    defaultMessage: 'Votre lien de réinitialisation de mot de passe a expiré',
  },
  [UpdateUserPasswordErrors.USER_PASSWORD_TOKEN_INVALID]: {
    id: 'UpdatePasswordForm.error.userPasswordTokenInvalid',
    defaultMessage: 'Votre lien de réinitialisation de mot de passe n\'est pas valide',
  },
  [UpdateUserPasswordErrors.USER_PASSWORD_TOKEN_NOT_GENERATED]: {
    id: 'UpdatePasswordForm.error.userPasswordTokenNotGenerated',
    defaultMessage: 'Votre lien de réinitialisation de mot de passe n\'a pas été généré',
  },
});

export default defineMessages({
  title: {
    id: 'UpdatePasswordScreen.title',
    defaultMessage: 'Nouveau mot de passe',
  },
  subtitle: {
    id: 'UpdatePasswordScreen.subtitle',
    defaultMessage: 'Modifiez votre mot de passe',
  },
  description: {
    id: 'UpdatePasswordScreen.description',
    defaultMessage: 'Utilisez votre nouveau mot de passe lors de vos prochaines connexions.',
  },
  submitButton: {
    id: 'UpdatePasswordForm.submit',
    defaultMessage: 'Envoyez',
  },
  password: {
    id: 'UpdatePasswordForm.label.password',
    defaultMessage: 'Mot de passe',
  },
});
