import React from 'react';
import { View } from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import tw from '../../tw';

type ModalFooterProps = {
  children: React.ReactNode;
  style?: Style;
}

export default function ModalFooter({
  children,
  style,
}: ModalFooterProps) {
  return (
    <View
      style={tw.style(
        'mt-2',
        style,
      )}
    >
      {children}
    </View>
  );
}
