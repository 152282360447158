import { gql } from '@apollo/client';

export const PUBLISHED_CONSENT = gql`
  query publishedConsent {
    publishedConsent {
      id
      content
    }
  }
`;
