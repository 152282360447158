import { useNavigation } from '@react-navigation/native';
import { ScreenNavigationProp } from 'types/navigation';
import Screens from '../../../../screens';

export default function useSuccessUpdatePassword() {
  const { navigate } = useNavigation<ScreenNavigationProp<Screens.login>>();

  return () => {
    navigate(Screens.login);
  };
}
