import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import testPasswordStrength from '../../../../utils/test-password-strength/test-password-strength';
import { errorTranslations } from '../translations';

export default (intl: IntlShape) => Yup.object().shape({
  password: Yup
    .string()
    .ensure()
    .test({
      name: 'password specs',
      message: intl.formatMessage(errorTranslations.minimumPasswordStrength),
      exclusive: true,
      test: (value) => testPasswordStrength(value),
    })
    .required(intl.formatMessage(errorTranslations.updatedPasswordRequired)),
});
