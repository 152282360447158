import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'MyCommunities.title',
    defaultMessage: 'Mes communautés',
  },
  noCommunities: {
    id: 'MyCommunities.noCommunities',
    defaultMessage: 'Vous n\'avez aucunes communautés',
  },
  noAuthoredCommunities: {
    id: 'MyCommunities.noAuthoredCommunities',
    defaultMessage: 'Vous n\'avez aucunes communautés créées',
  },
});
