import React, { ReactNode } from 'react';
import {
  Text as RNText,
} from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import tw from '../../tw';

type TextVariant = 'header1' | 'header2' | 'header3' | 'subtitle' | 'body' | 'small' | 'pretitle' | 'buttontext' | 'link';

export type TextProps = {
  children: ReactNode,
  variant?: TextVariant,
  style?: Style
}

export default function Text({
  children,
  variant = 'body',
  style,
}: TextProps) {
  return (
    <RNText
      style={tw.style(
        'text-dark dark:text-light',
        variant === 'header1' && 'text-4xl font-bold',
        variant === 'header2' && 'text-3xl font-bold',
        variant === 'header3' && 'text-2xl font-bold',
        variant === 'subtitle' && 'text-xl font-medium',
        variant === 'body' && 'text-sm',
        variant === 'small' && 'text-xs',
        variant === 'pretitle' && 'text-sm font-semibold uppercase',
        variant === 'buttontext' && 'text-base font-semibold uppercase',
        variant === 'link' && 'text-sm text-primary-500 dark:text-primary-500 underline font-semibold ',
        style,
      )}
    >
      { children }
    </RNText>
  );
}
