import React from 'react';
import { useIntl } from 'react-intl';
import tw, {
  Text,
  ScreenAreaView,
  ScreenContainer,
  Loader,
  ErrorScreen,
  KeyboardDisplayManager,
} from 'design-system';
import RegisterForm from './register-form/RegisterForm';
import useRegisterScreen from './hooks/useRegisterScreen';
import translations from './translations';

export default function RegisterScreen() {
  const intl = useIntl();
  const {
    loading,
    consent,
    error,
  } = useRegisterScreen();

  if (loading) return <Loader />;

  if (error) return <ErrorScreen />;

  return (
    <ScreenAreaView edges={['bottom']}>
      <ScreenContainer>
        <KeyboardDisplayManager>
          <Text
            variant="header1"
            style={tw.style('mb-4')}
          >
            {intl.formatMessage(translations.title)}
          </Text>
          <Text
            variant="subtitle"
            style={tw.style('mb-6')}
          >
            {intl.formatMessage(translations.subtitle)}
          </Text>
          <RegisterForm
            consent={consent}
          />
        </KeyboardDisplayManager>
      </ScreenContainer>
    </ScreenAreaView>
  );
}
