import { defineMessages } from 'react-intl';

export const toastrTranslations = defineMessages({
  failedToCreateProgramOwner: {
    id: 'AddProgramOwnerForm.toastr.failedToCreateProgramOwner',
    defaultMessage: 'Echec de l\'ajout du responsable',
  },
});

export const errorTranslations = defineMessages({
  emailRequired: {
    id: 'AddProgramOwnerForm.error.emailRequired',
    defaultMessage: 'Email requis',
  },
});

export default defineMessages({
  formLabelEmail: {
    id: 'AddProgramOwnerForm.formLabelEmail',
    defaultMessage: 'Email',
  },
  formButtonSubmit: {
    id: 'AddProgramOwnerForm.formButtonSubmit',
    defaultMessage: 'Ajouter',
  },
});
