import React, { ReactNode } from 'react';
import { View } from 'react-native';
import tw from '../../tw';

type CardProps = {
  children: ReactNode;
}

export default function Card({
  children,
}: CardProps) {
  return (
    <View
      style={tw.style('rounded-md w-full border border-2 border-dotted mb-4 p-1')}
    >
      {children}
    </View>
  );
}
