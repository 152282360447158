import React from 'react';
import { View } from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import ActivityIndicator from '../../basic/activity-indicator/ActivityIndicator';
import tw from '../../tw';

type LoaderProps = {
  style?: Style,
}
export default function Loader({
  style,
}: LoaderProps) {
  return (
    <View
      style={tw.style(
        'flex-1 flex-col content-center justify-center bg-light dark:bg-dark',
        style,
      )}
    >
      <ActivityIndicator />
    </View>
  );
}
