import { useIntl } from 'react-intl';
import { FormikHelpers } from 'formik';
import { useUpdateUserPasswordMutation } from 'types/graphql';
import { UpdateUserPasswordErrors } from 'types/graphql-errors';
import useHandleGraphqlErrors from '../../../../hooks/use-handle-graphql-errors/useHandleGraphqlErrors';
import { FormikFields } from '../@types';
import { errorTranslations } from '../translations';

export default function useUpdateUserPassword({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const intl = useIntl();
  const [updateUserPassword] = useUpdateUserPasswordMutation();
  const handleGraphqlErrors = useHandleGraphqlErrors();

  const errorsMapping = {
    translations: {
      global: {
        [UpdateUserPasswordErrors.NOT_FOUND]:
          intl.formatMessage(errorTranslations.NOT_FOUND),
      },
      password: {
        [UpdateUserPasswordErrors.USER_PASSWORD_BLANK]:
          intl.formatMessage(errorTranslations.USER_PASSWORD_BLANK),
        [UpdateUserPasswordErrors.USER_PASSWORD_NOT_STRONG_ENOUGH]:
          intl.formatMessage(errorTranslations.USER_PASSWORD_NOT_STRONG_ENOUGH),
        [UpdateUserPasswordErrors.USER_PASSWORD_TOKEN_EXPIRED]:
          intl.formatMessage(errorTranslations.USER_PASSWORD_TOKEN_EXPIRED),
        [UpdateUserPasswordErrors.USER_PASSWORD_TOKEN_INVALID]:
          intl.formatMessage(errorTranslations.USER_PASSWORD_TOKEN_INVALID),
        [UpdateUserPasswordErrors.USER_PASSWORD_TOKEN_NOT_GENERATED]:
          intl.formatMessage(errorTranslations.USER_PASSWORD_TOKEN_NOT_GENERATED),
      },
    },
  };

  return async function onSubmit(
    values: FormikFields,
    formikBag: FormikHelpers<FormikFields>,
  ): Promise<void> {
    return updateUserPassword({
      variables: {
        password: values.password,
        passwordToken: values.passwordToken,
      },
    })
      .then(() => {
        onSuccess();
      })
      .catch((errors) => {
        handleGraphqlErrors<
          FormikFields,
          UpdateUserPasswordErrors
        >(
          errors,
          formikBag,
          errorsMapping,
        );
      });
  };
}
