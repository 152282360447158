import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { RegisterUserErrors } from 'types/graphql-errors';
import { useRegisterUserMutation } from 'types/graphql';
import useHandleGraphqlErrors from '../../../../hooks/use-handle-graphql-errors/useHandleGraphqlErrors';
import {
  AcceptConsentParams,
  RegisterFormikFields,
} from '../@types';
import { errorTranslations } from '../translations';

export default function useRegisterUser({
  onSuccess,
}: {
  onSuccess: ({
    userId,
    email,
  }: AcceptConsentParams) => void;
}) {
  const intl = useIntl();
  const [registerUser] = useRegisterUserMutation();
  const handleGraphqlErrors = useHandleGraphqlErrors();

  const errorsMapping = {
    translations: {
      email: {
        [RegisterUserErrors.USER_EMAIL_BLANK]:
          intl.formatMessage(errorTranslations.USER_EMAIL_BLANK),
        [RegisterUserErrors.USER_EMAIL_INVALID]:
          intl.formatMessage(errorTranslations.USER_EMAIL_INVALID),
        [RegisterUserErrors.USER_EMAIL_TAKEN]:
          intl.formatMessage(errorTranslations.USER_EMAIL_TAKEN),
      },
    },
  };

  return async function onSubmit(
    values: RegisterFormikFields,
    formikBag: FormikHelpers<RegisterFormikFields>,
  ): Promise<void> {
    return registerUser({
      variables: values,
    })
      .then(({ data }) => {
        onSuccess({
          userId: data?.registerUser?.id,
          email: data?.registerUser?.email,
        });
      })
      .catch((errors) => {
        handleGraphqlErrors<
          RegisterFormikFields,
          RegisterUserErrors
        >(
          errors,
          formikBag,
          errorsMapping,
        );
      });
  };
}
