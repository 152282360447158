import { connectActionSheet } from '@expo/react-native-action-sheet';
import {
  // DarkTheme,
  // DefaultTheme,
  NavigationContainer,
} from '@react-navigation/native';
import React from 'react';
import Toast from 'react-native-toast-message';
// import { useColorScheme } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Loader } from 'design-system';
import { RootStackParamList } from 'types/navigation';
import { useAuthorizationContext } from 'providers/authorization/AuthorizationContext';
import Authenticated from './authenticated/Authenticated';
import Unauthorized from './unauthenticated/Unauthenticated';
import Screens from '../screens';

export function RootNavigation() {
  const { authenticated } = useAuthorizationContext();

  const config = {
    screens: {
      [Screens.login]: 'login/:email?&:password?',
      [Screens.register]: 'register/:email?',
      [Screens.updatePassword]: 'update-password/:passwordToken',
      [Screens.checkRegistrationToken]: 'check-registration-token/:passwordToken?&:email?',
      [Screens.registerSuccess]: 'register-success',
      [Screens.askPassword]: 'ask-password',
      [Screens.createPassword]: 'create-password',
      [Screens.createProfile]: 'create-profile',
      [Screens.public]: 'public',
      [Screens.mainDrawerScreen]: {
        path: '',
        screens: {
          [Screens.dashboard]: 'dashboard',
          [Screens.security]: 'security',
          [Screens.notifications]: 'notifications',
        },
      },
      [Screens.myCommunities]: 'my-communities',
      [Screens.showCommunity]: 'show-community/:id?',
      [Screens.createCommunity]: 'create-community',
      [Screens.updateCommunity]: 'update-community/:id?',
      [Screens.addCommunityMember]: 'add-community-member/:id',
      [Screens.communityMembersList]: 'community-members-list/:id',
      [Screens.showProgram]: 'show-program/:id?',
      [Screens.createProgram]: 'create-program/:id?',
      [Screens.updateProgram]: 'update-program/:id?',
      [Screens.communityProgramsList]: 'community-programs-list/:id?',
      [Screens.addProgramOwner]: 'add-program-owner/:id',
      [Screens.programOwnersList]: 'program-owners-list/:id',
      [Screens.addProgramParticipant]: 'add-program-participant/:id',
      [Screens.programParticipantsList]: 'program-participants-list/:id',
    },
  };

  const linking = {
    prefixes: [
      'https://frontend.develop.lb.k8s.dev.mjg-staging.com',
    ],
    config,
  };

  // const colorScheme = useColorScheme();

  return (
    <SafeAreaProvider>
      <NavigationContainer<RootStackParamList>
        linking={linking}
        fallback={<Loader />}
        documentTitle={{
          formatter: () => 'Odyssey',
        }}
      // theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
      >
        {authenticated
          ? (
            <Authenticated />
          )
          : (
            <Unauthorized />
          )}
        <Toast />
      </NavigationContainer>
    </SafeAreaProvider>
  );
}

const ConnectedApp = connectActionSheet(RootNavigation);

export default ConnectedApp;
