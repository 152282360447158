import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { errorTranslations } from '../translations';

export default (intl: IntlShape) => Yup.object().shape({
  title: Yup
    .string()
    .required(intl.formatMessage(errorTranslations.titleRequired)),
  description: Yup
    .string()
    .required(intl.formatMessage(errorTranslations.descriptionRequired)),
});
