import {
  StackActions,
  useNavigation,
} from '@react-navigation/native';
import { useIntl } from 'react-intl';
import Toast from 'react-native-toast-message';
import { useCreateProgramParticipantInvitationMutation } from 'types/graphql';
import { toastrTranslations } from './translations';

export default function useAddProgramParticipant() {
  const intl = useIntl();
  const { dispatch } = useNavigation();

  // eslint-disable-next-line max-len
  const [CreateProgramParticipantInvitationMutation] = useCreateProgramParticipantInvitationMutation({
    refetchQueries: ['program'],
    onCompleted: () => dispatch(StackActions.pop()),
    onError: () => {
      Toast.show({
        type: 'error',
        text1: intl.formatMessage(toastrTranslations.failedToCreateProgramParticipant),
      });
    },
  });

  return {
    CreateProgramParticipantInvitationMutation,
  };
}
