import { useIntl } from 'react-intl';
import { FormikHelpers } from 'formik';
import { useAskPasswordUserMutation } from 'types/graphql';
import { AskPasswordUserErrors } from 'types/graphql-errors';
import useHandleGraphqlErrors from '../../../../hooks/use-handle-graphql-errors/useHandleGraphqlErrors';
import { errorTranslations } from '../translations';

export type FormikFields = {
  email: string;
};

export default function useAskPassword({
  onSuccess,
}: {
  onSuccess: (data: FormikFields) => void;
}) {
  const intl = useIntl();
  const [askPasswordUser] = useAskPasswordUserMutation();
  const handleGraphqlErrors = useHandleGraphqlErrors();

  const errorsMapping = {
    translations: {
      global: {
        [AskPasswordUserErrors.NOT_FOUND]:
          intl.formatMessage(errorTranslations.NOT_FOUND),
        [AskPasswordUserErrors.UNAUTHORIZED]:
          intl.formatMessage(errorTranslations.UNAUTHORIZED),
      },
      email: {
        [AskPasswordUserErrors.USER_EMAIL_BLANK]:
          intl.formatMessage(errorTranslations.USER_EMAIL_BLANK),
        [AskPasswordUserErrors.USER_EMAIL_INVALID]:
          intl.formatMessage(errorTranslations.USER_EMAIL_INVALID),
      },
    },
  };

  return async function onSubmit(
    values: FormikFields,
    formikBag: FormikHelpers<FormikFields>,
  ): Promise<void> {
    return askPasswordUser({
      variables: values,
    })
      .then(() => {
        onSuccess({
          email: values.email,
        });
      })
      .catch((errors) => {
        handleGraphqlErrors<
        FormikFields,
        AskPasswordUserErrors
        >(
          errors,
          formikBag,
          errorsMapping,
        );
      });
  };
}
