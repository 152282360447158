import { useRoute } from '@react-navigation/native';
import { useIntl } from 'react-intl';
import Toast from 'react-native-toast-message';
import { useAskUserRegistrationMutation } from 'types/graphql';
import { toastrTranslations } from '../translations';

export default function useRegisterSuccessScreen() {
  const intl = useIntl();
  const { params } = useRoute<{
    key: string;
    path?: string;
    name: string;
    params?: { [key: string]: any };
  }>();
  const initialValues = {
    email: params?.email || '',
  };

  const [askUserRegistration] = useAskUserRegistrationMutation({
    onCompleted: () => {
      Toast.show({
        type: 'success',
        text1: intl.formatMessage(toastrTranslations.success),
      });
    },
    onError: () => {
      Toast.show({
        type: 'danger',
        text1: intl.formatMessage(toastrTranslations.error),
      });
    },
  });

  return {
    initialValues,
    askUserRegistration,
  };
}
