import React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import tw, {
  Button,
  InputForm,
  InputImageForm,
} from 'design-system';
import validationSchema from './CreateCommunityForm.validation';
import useCreateCommunity from './useCreateCommunity';
import translations from '../translations';

type CreateCommunityFormikFields = {
  name: string;
  description: string;
  logo?: string;
}

export default function CreateCommunityForm() {
  const intl = useIntl();
  const initialValues = {
    name: '',
    description: '',
    logo: '',
  };
  const { createCommunityMutation } = useCreateCommunity();

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange
      validateOnBlur
      validateOnMount
      validationSchema={validationSchema(intl)}
      onSubmit={(
        values: CreateCommunityFormikFields,
      ) => createCommunityMutation({ variables: values })}
    >
      {({
        handleSubmit,
      }) => (
        <>
          <InputImageForm
            fieldName="logo"
            label={intl.formatMessage(translations.imageUploadLabel)}
          />
          <InputForm
            fieldName="name"
            label={intl.formatMessage(translations.formLabelTitle)}
            style={tw.style('w-full mb-4')}
          />
          <InputForm
            fieldName="description"
            label={intl.formatMessage(translations.formLabelDescription)}
            style={tw.style('w-full mb-4')}
          />
          <Button
            variant="full"
            onPress={() => handleSubmit()}
            style={tw.style('self-center')}
          >
            {intl.formatMessage(translations.formButtonSubmit).toUpperCase()}
          </Button>
        </>
      )}
    </Formik>
  );
}
