import { useRoute } from '@react-navigation/native';
import useLoginUser from './useLoginUser';
import useSuccessLogin from './useSuccessLogin';

export default function useLoginScreen() {
  const { params } = useRoute<{
    key: string;
    path?: string;
    name: string;
    params?: { [key: string]: any };
  }>();
  const initialValues = {
    email: params?.email || '',
    password: params?.password || '',
  };

  const onSuccess = useSuccessLogin();
  const login = useLoginUser({
    onSuccess,
  });

  return {
    initialValues,
    login,
  };
}
