import useAskPassword from './useAskPassword';
import useSuccessAskPassword from './useSuccessAskPassword';

export default function useAskPasswordForm() {
  const initialValues = {
    email: '',
  };
  const askPassword = useAskPassword({
    onSuccess: useSuccessAskPassword(),
  });

  return {
    initialValues,
    askPassword,
  };
}
