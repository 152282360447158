import { defineMessages } from 'react-intl';

export const toastrTranslations = defineMessages({
  error: {
    id: 'RegisterSuccess.toastr.error',
    defaultMessage: 'Error',
  },
  success: {
    id: 'RegisterSuccess.toastr.success',
    defaultMessage: 'Lien de confirmation envoyé',
  },
});

export default defineMessages({
  text: {
    id: 'RegisterSuccess.text',
    defaultMessage: 'Cliquez sur le lien de confirmation qui vous a été envoyé à l’adresse e-mail renseignée :',
  },
  question: {
    id: 'RegisterSuccess.subtitle',
    defaultMessage: 'Vous n’avez pas reçu d’e-mail ?',
  },
  link: {
    id: 'RegisterSuccess.link',
    defaultMessage: 'Renvoyer un nouveau lien',
  },
});
