import React from 'react';
import { View } from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import tw from '../../tw';

type ModalBodyProps = {
  children: React.ReactNode;
  style?: Style;
}

export default function ModalBody({
  children,
  style,
}: ModalBodyProps) {
  return (
    <View
      style={tw.style(
        {
          elevation: 5,
        },
        style,
      )}
    >
      {children}
    </View>
  );
}
