import { useRoute } from '@react-navigation/native';
import { useCheckPasswordTokenMutation } from 'types/graphql';
import { CheckRegistrationValues } from '../@types';
import useCheckRegistrationTokenFailure from './useCheckRegistrationTokenFailure';
import useCheckRegistrationTokenSuccess from './useCheckRegistrationTokenSuccess';

export default function useCheckRegistrationTokenScreen() {
  const { params } = useRoute<{
    key: string;
    path?: string;
    name: string;
    params?: { [key: string]: any };
  }>();
  const initialValues: CheckRegistrationValues = {
    email: params?.email || '',
    passwordToken: params?.passwordToken || '',
  };
  const onSuccess = useCheckRegistrationTokenSuccess();
  const onFailure = useCheckRegistrationTokenFailure();
  const [checkPasswordToken] = useCheckPasswordTokenMutation();

  return {
    initialValues,
    checkRegistrationToken: (values: CheckRegistrationValues) => checkPasswordToken({
      variables: {
        email: values.email,
        passwordToken: values.passwordToken,
      },
    })
      .then(() => {
        onSuccess({
          passwordToken: values.passwordToken,
        });
      })
      .catch(() => {
        onFailure({
          email: values.email,
        });
      }),
  };
}
