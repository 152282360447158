import { useNavigation } from '@react-navigation/native';
import { ScreenNavigationProp } from 'types/navigation';
import Screens from '../../../../screens';

export default function useSuccessCreatePassword() {
  const { navigate } = useNavigation<ScreenNavigationProp<Screens.createProfile>>();

  return () => {
    navigate(Screens.createProfile);
  };
}
