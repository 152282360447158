import {
  CommonActions,
  useNavigation,
} from '@react-navigation/native';
import { useIntl } from 'react-intl';
import Toast from 'react-native-toast-message';
import { ScreenNavigationProp } from 'types/navigation';
import Screens from '../../../../screens';
import { errorTranslations } from '../translations';

export default function useCheckRegistrationTokenFailure() {
  const intl = useIntl();
  const { dispatch } = useNavigation<ScreenNavigationProp<Screens.register>>();

  return ({
    email,
  }) => {
    Toast.show({
      type: 'error',
      text1: intl.formatMessage(errorTranslations.error),
    });
    dispatch((state) => {
      // Add the home route to the start of the stack
      const routes = [{
        name: Screens.public,
      }, {
        name: Screens.register,
        params: {
          email,
        },
      }];

      return CommonActions.reset({
        ...state,
        routes,
        index: routes.length - 1,
      });
    });
  };
}
