import React from 'react';
import {
  Button,
} from 'design-system';
import useLogoutScreen from './hooks/useLogoutButton';

export default function LogoutButton() {
  const { logoutUser } = useLogoutScreen();

  return (
    <Button onPress={() => logoutUser()}>
      Logout
    </Button>
  );
}
