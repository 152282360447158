import { defineMessages } from 'react-intl';
import { RegisterUserErrors } from 'types/graphql-errors';

export const errorTranslations = defineMessages({
  emailRequired: {
    id: 'RegisterForm.required.email',
    defaultMessage: 'Cette adresse email est invalide',
  },
  consentRequired: {
    id: 'RegisterForm.required.consent',
    defaultMessage: 'Vous devez accepter les conditions d\'utilisation',
  },
  [RegisterUserErrors.USER_EMAIL_BLANK]: {
    id: 'RegisterForm.error.userEmailBlank',
    defaultMessage: 'Veuillez saisir une adresse email',
  },
  [RegisterUserErrors.USER_EMAIL_INVALID]: {
    id: 'RegisterForm.error.userEmailInvalid',
    defaultMessage: 'Cette adresse email est invalide',
  },
  [RegisterUserErrors.USER_EMAIL_TAKEN]: {
    id: 'RegisterForm.error.userEmailTaken',
    defaultMessage: 'Cette adresse email est déjà utilisé, connectez-vous ou demandez un nouveau mot de passe',
  },
});

export default defineMessages({
  title: {
    id: 'RegisterScreen.title',
    defaultMessage: 'Rejoindre le réseau',
  },
  subtitle: {
    id: 'RegisterScreen.subtitle',
    defaultMessage: 'Indiquez votre e-mail et recevez un lien de confirmation pour créer votre compte.',
  },
  emailLabel: {
    id: 'RegisterForm.label.email',
    defaultMessage: 'Email',
  },
  acceptConsent: {
    id: 'RegisterForm.label.acceptConsent',
    defaultMessage: 'Conditions Générales d\'Utilisation.',
  },
  acceptConsentHelper: {
    id: 'RegisterForm.label.acceptConsentHelper',
    defaultMessage: 'En cliquant sur “Envoyer le lien”, vous acceptez nos Conditions Générales d\'Utilisation.',
  },
  submitButton: {
    id: 'RegisterForm.button.submit',
    defaultMessage: 'Envoyer le lien',
  },
});
