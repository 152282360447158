import { useNavigation } from '@react-navigation/native';
import { ScreenNavigationProp } from 'types/navigation';
import Screens from '../../../../screens';

export type UseSuccessAcceptConsentParams = {
  email: string;
}
export default function useSuccessAcceptConsent() {
  const { navigate } = useNavigation<ScreenNavigationProp<Screens.registerSuccess>>();

  return ({
    email,
  }: UseSuccessAcceptConsentParams) => {
    navigate(Screens.registerSuccess, {
      email,
    });
  };
}
