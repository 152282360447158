import { defineMessages } from 'react-intl';

export const errorTranslations = defineMessages({
  emailRequired: {
    id: 'AddCommunityMemberForm.required.emailRequired',
    defaultMessage: 'Email Requis',
  },
});

export const toastrTranslations = defineMessages({
  failedToCreateCommunityMember: {
    id: 'AddCommunityMemberForm.toastr.failufailedToCreateCommunityMemberre',
    defaultMessage: 'Échec de l\'ajout de membre',
  },
  failedToFetchCommunity: {
    id: 'AddCommunityMemberForm.toastr.failedToFetchCommunity',
    defaultMessage: 'Échec de la récupération de la communauté',
  },

});

export default defineMessages({
  title: {
    id: 'AddCommunityMemberForm.title',
    defaultMessage: 'Ajouter un membre',
  },
  formLabelEmail: {
    id: 'AddCommunityMemberForm.formLabelEmail',
    defaultMessage: 'Email',
  },
  formButtonSubmit: {
    id: 'AddCommunityMemberForm.formButtonSubmit',
    defaultMessage: 'Ajouter',
  },
});
