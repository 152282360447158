import { useAuthorizationContext } from 'providers/authorization/AuthorizationContext';
import { User } from 'types/graphql';

export default function useSuccessLogin() {
  const { setAuthenticated, setUserProfileCompleted } = useAuthorizationContext();

  return (user: Partial<User>) => {
    setUserProfileCompleted(user.userProfile !== null);
    setAuthenticated(true);
  };
}
